<template>
  <div class="gp-zendesk">
    <a href="javascript:void(0)" @click="handleClick">
      <feather-icon name="message-square" />
      <l10n value="Chat with support" />
    </a>
  </div>
</template>
<script>
module.exports = {
  data() {
    return {
      users: [],
    };
  },
  props: {
    username: { type: String },
    locale: { type: String },
    zendeskKey: { type: String, default: 'bd635324-860c-4b6e-8c8a-85fb78446a25' },
  },
  methods: {
    handleClick() {
      const identity = { name: this.username };
      const user = this.users.find((user) => user.id == this.username);
      if (user && user.name) {
        identity.name = user.name;
      }
      if (user && user.email) {
        identity.email = user.email;
      }

      zE.identify(identity);
      zE.setLocale(this.locale);
      zE.activate({ hideOnClose: true });
    },
  },
  mounted() {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${this.zendeskKey}`;
    document.head.appendChild(script);
    const setupTimer = setInterval(() => {
      const launcher = document.getElementById('launcher');
      if (launcher) {
        const zendesk = launcher.parentNode;
        zendesk.id = 'zendesk';
        const updateVisibility = () => {
          if (document.getElementById('webWidget')) {
            $(zendesk).show();
          } else {
            $(zendesk).hide();
          }
        };
        $(zendesk).draggable();
        $(zendesk).resizable({
          handles: 'n, e, s, w, se',
          start: (e, ui) => {
            const el = $(ui.originalElement);
            const { top, left } = el.offset();
            el.css({
              top,
              left,
              right: 'none',
              bottom: 'none',
              position: 'fixed',
              maxHeight: '100vh',
            });
          },
        });

        const observer = new MutationObserver(updateVisibility);
        observer.observe(zendesk, { childList: true });
        updateVisibility();

        clearInterval(setupTimer);
      }
    }, 100);
    fetch('/graphql?__getUsersForZendesk__', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: '{dataset{users{id name email}}}' }),
    }).then((res) => res.json()).then((res) => this.users = res.data.dataset.users);
  },
};
</script>
<style>
.gp-zendesk svg {
    width: 20px;
}
.gp-zendesk {
    /*padding-right: 10px;*/
    /*margin-right: 10px;*/
    position: relative;
    font-size: 14px;
    white-space: nowrap;
}
#zendesk {
    position: fixed;
    z-index: 99999;
    top: 60px;
    right: 20px;
    height: 500px;
    width: 400px;
    height: 800px;
    max-width: 100vw;
    max-height: 80vh;
    min-width: 200px;
    min-height: 300px;
    display: none;
}
#zendesk.ui-draggable-dragging #webWidget,
#zendesk.ui-resizable-resizing #webWidget {
    pointer-events: none;
}
#zendesk:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 70px;
    height: 44px;
}
#webWidget {
    position: absolute!important;
    top: 0!important;
    left: 0!important;
    margin: -16px!important;
    z-index: 0!important;
    width: calc(100% + 32px)!important;
    height: calc(100% + 32px)!important;
    min-width: none!important;
    max-width: none!important;
    min-height: none!important;
    max-height: none!important;
}
</style>
