<template>
  <div class="pim-tree-table">
    <plain-table
      ref="table"
      stream="default"
      :dims="dims"
      :vals="vals"
      :provider="provider"
      :totals="false"
      :tableActions="tableActions"
      :throttled="false"
      :initialSort="[hasImage ? 2 : 1]"
      @row-selected="rowSelected"
      @rows-selected="rowsSelected"
    />
    <my-popup
      v-if="showColumnSelector"
      :anchor="$refs.table.$refs.showTableActions"
    >
      <div class="popover">
        <div class="popover-body">
          <pim-columns
            ref="columns"
            v-if="$refs.attributes"
            :type="type"
            :fields="fields"
            :columns="columns"
            @submit="showColumnSelector = false; columns = $event;"
            @cancel="showColumnSelector = false"
          />
        </div>
      </div>
    </my-popup>
    <pim-attributes-ex
      ref="attributes"
      :show="false"
      :entries="entries"
      :fields="attributesFields"
      @change="attributes = $event"
    />
  </div>
</template>
<script>
module.exports = {
  props: {
    entry: { type: Object },
    entries: { type: Array },
    fields: { type: Array },
  },
  data() {
    const { type } = this.entries[0];
    return {
      columns: JSON.parse(localStorage[`pim-columns-${type}`] || '[]'),
      attributes: {},
      showColumnSelector: false,
    };
  },
  watch: {
    columns() {
      localStorage[`pim-columns-${this.type}`] = JSON.stringify(this.columns);
    },
    rows() {
      this.$refs.table.requestData({ forced: true });
    },
  },
  methods: {
    rowSelected(row) {
      row = this.$refs.table.rows[row];
      const focus = this.entries[row.i];

      if (focus) {
        this.$emit('focus', focus);
      }
    },
    rowsSelected(rows) {

    },
    provider(req, table) {
      const { skip, take, sort } = table;
      const rows = _(this.rows)
        .orderBy(
          sort.map((n) => Math.abs(n) - 1),
          sort.map((n) => (n > 0 ? 'asc' : 'desc')),
        )
        .drop(skip)
        .take(take)
        .value();
      const data = {
        dataset: {
          source: {
            report: {
              size: this.rows.length,
              rows,
              stats: {},
              totals: {},
              columns: _.concat(this.dims, this.vals),
            },
          },
        },
      };
      const meta = {
        stream: 'default',
        columns: _.concat(this.dims, this.vals),
        dims: this.dims,
        vals: this.vals,
        cols: [],
        args: { take, skip },
      };
      return { meta, data };
    },
  },
  computed: {
    attributesFields() {
      const ids = new Set(_.map(this.columns, 'id'));
      return this.fields.filter((field) => ids.has(field.id));
    },
    type() {
      return this.entries[0].type;
    },
    dims() {
      return [/* {
                type: "check",
                name: " ",
            } */];
    },
    vals() {
      const vals = [];
      if (this.hasImage) {
        vals.push({
          name: 'Image',
          calc: 'image',
          type: 'html',
          format: (x) => (x ? `<img src="${x}">` : ''),
        });
      }
      vals.push({
        name: _.capitalize(this.type),
        calc: 'name',
      });
      if (this.hasDesc) {
        vals.push({
          name: 'Description',
          calc: 'desc',
        });
      }
      for (const column of this.columns) {
        const field = this.fields.find((field) => field.id == column.id);
        vals.push({
          name: column.name,
          calc: column.id,
          editable:
                        field
                        && !field.is_readonly
                        && field.editable !== false
                        && (!field.is_derived || field.overridable),
          format: (x) => (x || '-'),
        });
      }
      return vals;
    },
    hasImage() {
      return this.entries.some((entry) => entry.image);
    },
    hasDesc() {
      return this.entries.some((entry) => entry.desc);
    },
    rows() {
      return Object.freeze(this.entries.map((entry, i) => {
        const row = [];
        row.i = i;
        row.id = entry.id;
        row.__cache = {};
        // row.push(entry.selected)
        if (this.hasImage) {
          row.push(entry.image);
        }
        row.push(entry.name);
        if (this.hasDesc) {
          row.push(entry.desc);
        }
        for (const column of this.columns) {
          const attribute = this.attributes[column.id];
          const value = attribute ? attribute.vals[row.i] : '-';
          row.push(value);
        }
        return Object.freeze(row);
      }));
    },
    tableActions() {
      return [{
        icon: 'download',
        text: 'Export to Excel',
      }, {
        icon: 'upload',
        text: 'Import from Excel',
      }, {
        icon: 'list',
        text: 'Manage columns',
        call: () => this.showColumnSelector = true,
      }];
    },
  },
};
</script>
