<template>
  <my-popup
    v-if="permalink"
    :draggable="true"
    placement="bottom"
    @clickoutside="permalink = null"
    :anchor="anchor">
    <div class="popover pim-permalink">
      <div class="popover-body">
        <div class="form-group">
          <a class="float-right" href="javascript:void(0)" @click="permalink = null"><feather-icon name="x" /></a>
          <label>
            <l10n value="Please use the link below to share current state" />
          </label>
          <div class="input-group">
            <input class="form-control" readonly :value="permalink" @click="$event.target.select()" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-outline-secondary" @click="copyPermalink">
                <feather-icon name="clipboard" />
                <l10n :value="permalinkCopied ? 'Copied' : 'Copy'" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </my-popup>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  props: {
    anchor: { type: [String, Object, HTMLElement] },
  },
  data() {
    return {
      permalink: null,
      permalinkCopied: false,
    };
  },
  watch: {
    permalink() {
      this.permalinkCopied = false;
    },
  },
  methods: {
    async loadPermalink(permalink) {
      const res = await fetch(`/storage/permalinks/${permalink}`);
      return await res.json();
    },
    async copyPermalink() {
      await navigator.clipboard.writeText(this.permalink);
      this.permalinkCopied = true;
    },
    async createAndShowPermalink(config) {
      this.permalink = await this.createPermalink(config);
    },
    async createPermalink(config) {
      const id = utils.randomId();
      await fetch(`/storage/permalinks/${id}`, {
        method: 'PUT',
        body: JSON.stringify(config),
        headers: { 'Content-Type': 'application/json' },
      });
      return `${window.location.origin}/pages/tree/#permalink=${id}`;
    },
  },
};
</script>
<style>
.popover.pim-permalink {
    max-width: 100vw;
    width: 520px;
    box-shadow: 0 2px 10px -5px grey;
    font-size: 1em;
}
.popover.pim-permalink input {
    font-size: 0.9em;
}
.popover.pim-permalink svg {
    width: 18px;
}
.my-dark-theme .popover.pim-permalink {
    box-shadow: 0 2px 10px -5px black;
}
.my-dark-theme .popover.pim-permalink button {
    color: var(--light);
}
</style>
