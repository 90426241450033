<template>
  <div class="gp-bounds">
    <div class="progress" :style="{ visibility: loading ? 'visible' : 'hidden' }">
      <div
        v-if="loading"
        class="progress-bar progress-bar-striped progress-bar-animated bg-info"
        :style="{ width: `${progress * 100}%` }"
      />
    </div>
    <div v-if="report" :class="{ loading }">
      <ul class="gp-bounds-runs">
        <li v-for="run in report.runs" :key="run.id">
          <l10n
            value="{strategy}, optimized by {user} at {time}"
            :strategy="run.strategy.name"
            :user="run.user"
            :time="formatTime(run.time)"
          />
        </li>
      </ul>
      <div class="gp-bounds-legend">
        <div
          v-if="report.result.currentPrice"
          class="gp-bounds-legend-current-price">
          <l10n value="Current price" />
          {{formatPrice(report.result.currentPrice)}}
        </div>
        <div
          v-if="report.result.modifiedCurrentPrice"
          class="gp-bounds-legend-modified-current-price">
          <l10n value="Modified current price" />
          {{formatPrice(report.result.modifiedCurrentPrice)}}
        </div>
        <div
          v-if="report.result.optimalPrice"
          class="gp-bounds-legend-optimal-price">
          <l10n value="Optimal price" />
          {{formatPrice(report.result.optimalPrice)}}
        </div>
        <div
          v-if="report.result.finalPrice"
          class="gp-bounds-legend-final-price">
          <l10n value="Final price" />
          {{formatPrice(report.result.finalPrice)}}
        </div>
        <div
          v-if="report.result.markdown"
          class="gp-bounds-legend-markdown-price">
          <l10n value="Markdown price" />
        </div>
      </div>
      <div class="gp-bounds-markdown" v-if="report.result.markdown">
        <series-chart
          :stream="stream"
          :groups="groups"
          :dims="['type', 'date']"
          :vals="['price']"
          :throttled="false"
          :elasticX="true"
          :elasticY="true"
          :marginTop="0"
          :marginLeft="60"
          :marginRight="50"
          :marginBottom="60"
          :yAxisPadding="'10%'"
          :height="200"
          :yTicks="5"
          :xTicks="10"
          :rightYTicks="5"
          :xTickAngle="-90"
          x="d3.scaleTime()"
          yTickFormat="d3.format('$,.2f')"
          :brushOn="false"
          title="(d) => d.value[0]"
          :renderVerticalGridLines="true"
          :renderHorizontalGridLines="true"
          :clipPadding="10"
          valueAccessor="(d) => d.value[0]"
          :transitionDuration="0"
          :renderLegend="false"
          curve="d3.curveStepAfter"
          :renderDataPoints="{
            radius: 2,
            fillOpacity: 1,
            strokeOpacity: 0,
          }"
          :provider="markdownChart1Provider"
          :colors="markdownChart1Colors"
          :seriesSort="markdownChart1SeriesSort"
          id="markdownChart1"
          ref="markdownChart1"
        />
        <composite-chart
          :stream="stream"
          :groups="groups"
          :dims="['type', 'date']"
          :vals="['value']"
          :throttled="false"
          :elasticX="true"
          :elasticY="true"
          :marginTop="60"
          :marginLeft="60"
          :marginRight="50"
          :marginBottom="60"
          :yAxisPadding="'10%'"
          :height="260"
          :yTicks="5"
          :xTicks="10"
          :rightYTicks="5"
          :xTickAngle="-90"
          x="d3.scaleTime()"
          yTickFormat="d3.format('$,.0f')"
          rightYTickFormat="d3.format(',.0f')"
          :brushOn="false"
          title="(d) => d.value[0]"
          :renderVerticalGridLines="true"
          :renderHorizontalGridLines="true"
          :clipPadding="10"
          valueAccessor="(d) => d.value[0]"
          :transitionDuration="0"
          :renderLegend="true"
          curve_="d3.curveStepAfter"
          :renderDataPoints="{
            radius: 1,
            fillOpacity: 1,
            strokeOpacity: 0,
          }"
          id="markdownChart2"
        >
          <line-chart
            :stream="stream"
            :groups="groups"
            :dims="['date']"
            :vals="['Inventory']"
            :throttled="false"
            :transitionDuration="0"
            :renderDataPoints="{
              radius: 1,
              fillOpacity: 1,
              strokeOpacity: 0,
            }"
            :provider="markdownChart2ProviderInventory"
            colors="() => 'var(--orange)'"
            :brushOn="false"
            :useRightYAxis="true"
            :renderTitle="true"
            :xyTipsOn="true"
          />
          <line-chart
            :stream="stream"
            :groups="groups"
            :dims="['date']"
            :vals="['Revenue']"
            :throttled="false"
            :transitionDuration="0"
            :renderDataPoints="{
              radius: 1,
              fillOpacity: 1,
              strokeOpacity: 0,
            }"
            :provider="markdownChart2ProviderRevenue"
            colors="() => 'var(--teal)'"
            :brushOn="false"
            :renderTitle="true"
            :xyTipsOn="true"
          />
          <line-chart
            :stream="stream"
            :groups="groups"
            :dims="['date']"
            :vals="['Profit']"
            :throttled="false"
            :transitionDuration="0"
            :renderDataPoints="{
              radius: 1,
              fillOpacity: 1,
              strokeOpacity: 0,
            }"
            :provider="markdownChart2ProviderProfit"
            colors="() => 'var(--red)'"
            :brushOn="false"
            :renderTitle="true"
            :xyTipsOn="true"
          />
        </composite-chart>
      </div>
      <gp-check
        :checked="selectedStage == 'currentPrice'"
        @click="selectedStage = selectedStage == 'currentPrice' ? 'finalPrice' : 'currentPrice'"
      >
        <l10n value="Show price bounds for current price" />
      </gp-check>
      <my-dialog title="Price rule relations" v-if="opendedRelationRule" @close="opendedRelationRule = null" :large="true">
        <gp-bounds-related
          :stream="stream"
          :groups="groups"
          :rule="opendedRelationRule"
          :formats="formats"
          :formulas="formulas"
          :attributes="attributes"
          :metrics="metrics"
          :timeframes="timeframes"
          :related-metrics="relatedMetrics"
          :parentExtraFilters="parentExtraFilters"
        />
      </my-dialog>
      <table>
        <tbody>
          <template
            v-for="row in report.rows"
            v-if="row.stage === selectedStage && row.status">
            <tr :key="`${row.rule_id}`" :class="{ strict: row.rule.strict }">
              <td :title="formatRule(row.rule, row.leftBound, row.rightBound, row.centroid)">
                <template v-if="row.error !== undefined">
                  <feather-icon name="check" v-if="row.error < 0.009999" />
                  <feather-icon name="alert-circle" v-else />
                </template>
                <a
                  href="javascript:void(0)"
                  @click="$set(expandedRules, row.rule.id, !expandedRules[row.rule.id])"
                >
                  {{row.rule.name}}
                </a>
              </td>
              <td>
                <div class="gp-bounds-rule">
                  <div
                    v-if="row.leftBound || row.rightBound"
                    class="gp-bounds-bounds"
                    :style="{
                      left: `calc(${scale(row.leftBound) * 100}%`,
                      width: `calc(${(scale(row.rightBound) - scale(row.leftBound)) * 100}%`,
                    }" />
                  <div
                    v-if="row.finalPrice"
                    class="gp-bounds-final-price"
                    :style="{ left: `${scale(row.finalPrice) * 100}%` }" />
                  <div
                    v-if="row.optimalPrice"
                    class="gp-bounds-optimal-price"
                    :style="{ left: `${scale(row.optimalPrice) * 100}%` }" />
                  <div
                    v-if="row.modifiedCurrentPrice"
                    class="gp-bounds-modified-current-price"
                    :style="{ left: `${scale(row.modifiedCurrentPrice) * 100}%` }" />
                  <div
                    v-if="row.currentPrice"
                    class="gp-bounds-current-price"
                    :style="{ left: `${scale(row.currentPrice) * 100}%` }" />
                </div>
              </td>
            </tr>
            <tr v-if="expandedRules[row.rule.id]">
              <td colspan="2">
                <span
                  class="gp-bounds-weight"
                  v-if="row.rule.weight !== undefined
                    && row.rule.type !== 'rounding'
                    && row.rule.type !== 'allowable_percent'"
                >
                  <l10n value="rule weight" />
                  {{formatPercent(row.rule.weight * 100)}}
                </span>
                <template v-if="row.rule.filter.length > 0">
                  <p>{{formatRuleFilter(row.rule)}}</p>
                </template>
                <p>
                  {{formatRule(row.rule, row.leftBound, row.rightBound, row.centroid)}}
                </p>
                <p v-if="row.error >= 0.01">
                  <l10n value="price rule violation {error}" :error="formatPrice(row.error)" />
                </p>
                <p v-if="row.rule.type === 'same_price' || row.rule.type === 'relations'">
                  <a
                    aria-label="Show price relations"
                    href="javascript:void(0)"
                    @click="opendedRelationRule = row.rule"
                  >
                    <l10n value="Show price relations" />
                  </a>
                </p>
              </td>
            </tr>
          </template>
          <template v-if="modelSeries">
            <tr v-for="metric in ['margin', 'value', 'units']">
              <td>
                <l10n :value="`${metric} profile`" />
              </td>
              <td>
                <svg class="gp-bounds-series" viewBox="0 0 104 30" width="100%">
                  <path :d="modelSeries[metric].axis" />
                  <path :d="modelSeries[metric].fill" />
                  <path :d="modelSeries[metric].line" />
                  <svg-title v-if="modelSeries[metric].best">
                    <circle
                      :cx="modelSeries[metric].best.x"
                      :cy="modelSeries[metric].best.y"
                      :title="modelSeries[metric].best.title"
                      r="3"
                    />
                  </svg-title>
                </svg>
                <div class="gp-bounds-rule">
                  <div
                    v-if="report.result.finalPrice"
                    class="gp-bounds-final-price"
                    :style="{ left: `${scale(report.result.finalPrice) * 100}%` }" />
                  <div
                    v-if="report.result.optimalPrice"
                    class="gp-bounds-optimal-price"
                    :style="{ left: `${scale(report.result.optimalPrice) * 100}%` }" />
                  <div
                    v-if="report.result.modifiedCurrentPrice"
                    class="gp-bounds-modified-current-price"
                    :style="{ left: `${scale(report.result.modifiedCurrentPrice) * 100}%` }" />
                  <div
                    v-if="report.result.currentPrice"
                    class="gp-bounds-current-price"
                    :style="{ left: `${scale(report.result.currentPrice) * 100}%` }" />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <p v-if="report.elast">
        <l10n value="Elasticity:" />
        {{new Number(report.elast).toLocaleString()}}
      </p>
    </div>
  </div>
</template>

<script>
const utils = require('../my-utils');
const ls = require('../api/localStorage');

module.exports = {
  mixins: [utils.extraFilters, utils.configHelpers],
  props: {
    uniqueKey: {
      type: String,
      default: 'Initial',
      required: true,
    },
    cores: { type: Number, default: 16 },
    stream: { type: String, default: 'default' },
    groups: { type: Array },
    filter1: { type: String },
    filter2: { type: String },
    rulesLibrary: { type: Array, default: () => [] },
    relatedMetrics: { type: Array, default: () => ['avg_price_zone_price', 'avg_recommended_price', 'avg_new_price'] },
  },

  data() {
    return {
      report: null,
      reportId: null,
      loading: false,
      progresses: {},
      expandedRules: {},
      opendedRelationRule: null,
      selectedStage: 'finalPrice',
    };
  },

  mounted() {
    this.requestDataThrottled();
    utils.bridge.bind('ws-message', this.handleWsMessage);
    utils.bridge.bind('optimizationIdChanged', this.requestData);
  },

  beforeDestroy() {
    utils.bridge.unbind('ws-message', this.handleWsMessage);
    utils.bridge.unbind('optimizationIdChanged', this.requestData);
  },

  watch: {
    extraFilter0() {
      this.requestDataThrottled();
    },
    extraFilter1() {
      this.requestDataThrottled();
    },
    extraFilter2() {
      this.requestDataThrottled();
    },
    extraFilter3() {
      this.requestDataThrottled();
    },
    report() {
      this.$refs.markdownChart1?.requestData();
      this.$refs.markdownChart2?.requestData();
    },
  },

  computed: {
    parentExtraFilters() {
      const itemRegexp = /&& item == "[0-9]+"/gm;

      return {
        extraFilter0: this.extraFilters.extraFilter0.replace(itemRegexp, ''),
        extraFilter1: this.extraFilters.extraFilter1.replace(itemRegexp, ''),
        extraFilter2: this.extraFilters.extraFilter2.replace(itemRegexp, ''),
      };
    },

    progress() {
      return Math.min(1, _(this.progresses).values().sum() / 1);
    },

    options() {
      return _(this.rulesLibrary)
        .map('options')
        .filter()
        .map(_.toPairs)
        .flatten()
        .value();
    },

    friendlyNames() {
      return _(this.options)
        .map('[1]')
        .filter(_.isPlainObject)
        .map(_.toPairs)
        .flatten()
        .map(([k, v]) => [v, k])
        .fromPairs()
        .value();
    },

    modelSeries() {
      if (this.report && this.report.model && this.report.model.length) {
        const computeSeries = (name) => {
          let min = Number.MAX_VALUE;
          let max = Number.MIN_VALUE;
          let opt;

          for (const entry of this.report.model) {
            const val = entry[name];
            min = Math.min(min, val);
            max = Math.max(max, val);
          }

          const path = [];
          const height = 30;
          const padding = 2;
          const scale = (val) => {
            const area = height - padding * 2;
            const y = area - 0.9 * (val - min) / (max - min) * area + padding;
            return Math.round(y * 100) / 100;
          };

          for (let i = 0; i < this.report.model.length; ++i) {
            let x = i + padding;
            const val = this.report.model[i][name];

            if (val === max && i !== 0 && i !== this.report.model.length - 1) {
              opt = i;
            }

            const y = scale(val);
            const op = path.length ? 'L' : 'M';
            path.push(`${op} ${x} ${y}`);
            x += 1;
          }

          return {
            line: path.join(' '),
            fill: path.concat([
              `L ${100 + padding} ${scale(0)}`,
              `L ${padding} ${scale(0)}`]).join(' '),
            axis: `M 0 ${scale(0)} L ${100 + padding * 2} ${scale(0)}`,
            best: opt
              ? {
                x: opt + padding,
                y: scale(max),
                title: this.formatPrice(this.report.model[opt].price),
              }
              : null,
          };
        };
        return {
          margin: computeSeries('margin'),
          value: computeSeries('value'),
          units: computeSeries('units'),
        };
      }
    },
  },

  methods: {
    getUniqueTableDataFromLocalStorage(key) {
      const response = ls.loadFromLocalStorage(this.uniqueKey, key);

      if (response && response.type === 'error') {
        setTimeout(() => {
          this.createNotification(response, 'error');
          return undefined;
        }, 0);
      }

      return response;
    },

    markdownChart1SeriesSort(a, b) {
      const seriesOrder = ([name]) => {
        switch (name) {
          case 'Current price': return 0;
          case 'Optimial price': return 1;
          case 'Final price': return 2;
          case 'Markdown price': return 3;
        }
      };
      return d3.ascending(seriesOrder(a), seriesOrder(b));
    },

    markdownChart1Colors(name) {
      switch (name) {
        case 'Current price': return 'var(--cyan)';
        case 'Optimial price': return 'var(--orange)';
        case 'Final price': return 'var(--red)';
        case 'Markdown price': return 'var(--purple)';
      }
    },

    markdownChart1Provider(req) {
      const parseDate = (dateStr) => (dateStr ? new Date(`${dateStr.substr(0, 4)}-${dateStr.substr(4, 2)}-${dateStr.substr(6, 2)}`) : undefined);
      const markdown = this.report?.result?.markdown;
      const startDate = parseDate(markdown.start_date) || gptable.parseDate(gptable.referenceDate);
      let endDate = parseDate(markdown?.end_date);

      if (!endDate) {
        endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 30);
      }

      const dates = markdown?.dates || [];
      const startDateMarkdown = parseDate(_.min(dates)?.toString());
      const endDateMarkdown = parseDate(_.max(dates)?.toString());
      const prices = markdown?.finalPrices || [];
      const rows = _.zip(dates.map((d) => moment(`${d}`)._d), prices)
        .filter(([d, p]) => d != 0)
        .concat([
          !moment(startDateMarkdown).isSame(startDate) ? [startDate, this.report?.result?.currentPrice || 0] : [],
          !moment(endDateMarkdown).isSame(endDate) ? [endDate, _.last(prices.filter((p) => p != 0)) || 0] : [],
        ])
        .map(([d, p]) => ['Markdown price', d, p])
        .concat([
          ['Current price', startDate, this.report?.result?.currentPrice],
          ['Current price', endDate, this.report?.result?.currentPrice],
        ])
        .concat([
          ['Optimial price', startDate, this.report?.result?.optimalPrice],
          ['Optimial price', endDate, this.report?.result?.optimalPrice],
        ])
        .concat([
          ['Final price', startDate, this.report?.result?.finalPrice],
          ['Final price', endDate, this.report?.result?.finalPrice],
        ]);
      const data = {
        dataset: {
          streams: {
            combined: {
              report: {
                size: rows.length,
                rows,
                stats: {},
                totals: {},
              },
            },
          },
        },
      };
      const dims = [
        { name: 'type' },
        { name: 'date' },
      ];
      const vals = [{
        name: utils.l10n('price'),
        format: (x) => new Number(x).toLocaleString(this.locale, {
          style: 'currency',
          currency: this.currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      }];
      const meta = {
        stream: 'combined',
        columns: _.concat(dims, vals),
        dims,
        vals,
        cols: [],
      };

      return { data, meta };
    },

    markdownChart2SeriesSort(a, b) {
      const seriesOrder = ([name]) => {
        switch (name) {
          case 'Revenue': return 0;
          case 'Profit': return 1;
          case 'Inventory': return 2;
        }
      };
      return d3.ascending(seriesOrder(a), seriesOrder(b));
    },

    markdownChart2Colors(name) {
      switch (name) {
        case 'Revenue': return 'var(--cyan)';
        case 'Profit': return 'var(--orange)';
        case 'Inventory': return 'var(--purple)';
      }
    },

    async markdownChart2ProviderInventory(req) {
      return await this.markdownChart2Provider('Inventory', req);
    },

    async markdownChart2ProviderRevenue(req) {
      return await this.markdownChart2Provider('Revenue', req);
    },

    async markdownChart2ProviderProfit(req) {
      return await this.markdownChart2Provider('Profit', req);
    },

    async markdownChart2Provider(type, req) {
      const parseDate = (dateStr) => (dateStr ? new Date(`${dateStr.substr(0, 4)}-${dateStr.substr(4, 2)}-${dateStr.substr(6, 2)}`) : undefined);
      const markdown = this.report?.result?.markdown;
      let startDate = utils.parseDate(gptable.referenceDate);
      let endDate = parseDate(markdown?.end_date);

      if (!endDate) {
        endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 30);
      }

      let dates = [];
      const daysCount = moment(endDate).diff(startDate, 'days') + 1;

      for (let i = 0; i <= daysCount; ++i) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);
        dates[i] = utils.formatDate(date);
      }

      startDate = utils.formatDate(startDate);
      const filter0 = this.extraFilter0;
      const filter1 = this.extraFilter1;
      let filter2 = this.extraFilter2;

      if (filter2) {
        filter2 = `${filter2} && optimization.optimization_run_id != ''`;
      } else {
        filter2 = 'optimization.optimization_run_id != \'\'';
      }

      const optimizationId = this.getUniqueTableDataFromLocalStorage('optimizationId');
      const vars = JSON.stringify({
        target_price_zone: gptable.priceZone,
        target_optimization_run_id: utils.quote(optimizationId || ''),
      });

      let vals = '';
      switch (type) {
        case 'Inventory':
          vals = dates.map((date) => `sum(inventory_units-forecast_md(\`${dates[0]}\`, \`${date}\`, zone_price, optimization.markdown, inventory_units, 'units'))`).join(', ');
          break;
        case 'Revenue':
          vals = dates.map((date) => `sum(forecast_md(\`${dates[0]}\`, \`${date}\`, zone_price, optimization.markdown, inventory_units, 'value'))`).join(', ');
          break;
        case 'Profit':
          vals = dates.map((date) => `sum(forecast_md(\`${dates[0]}\`, \`${date}\`, zone_price, optimization.markdown, inventory_units, 'value')-forecast_md(\`${dates[0]}\`, \`${date}\`, zone_price, optimization.markdown, inventory_units, 'units')*cost*(1+vat))`).join(', ');
          break;
      }

      const query = `
                    query {
                        dataset {
                            streams {
                                combined {
                                    report(
                                        name: "gp-bounds-md",
                                        cores: ${this.cores},
                                        ${filter0 ? `filter0: ${utils.quote(filter0)},` : ''}
                                        ${filter1 ? `filter1: ${utils.quote(filter1)},` : ''}
                                        ${filter2 ? `filter2: ${utils.quote(filter2)},` : ''}
                                        vars: ${utils.quote(vars)},
                                        vals: ${utils.quote(vals)})
                                    {
                                        rows
                                    }
                                }
                            }
                        }
                    }`;

      let { rows } = (await (await fetch('/graphql?__getRowsForGpBounds__', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
        method: 'POST',
      })).json()).data.dataset.streams.combined.report;
      dates = dates.map(utils.parseDate);
      rows = _.zipWith(dates, rows[0], (d, x) => [d, x]);

      const data = {
        dataset: {
          streams: {
            combined: {
              report: {
                size: rows.length,
                rows,
                stats: {},
                totals: {},
              },
            },
          },
        },
      };
      const dims = [{ name: 'date' }];
      let format = (x) => x;
      switch (type) {
        case 'Inventory':
          format = (x) => new Number(x).toLocaleString(this.locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          break;
        default:
          format = (x) => new Number(x).toLocaleString(this.locale, {
            style: 'currency',
            currency: this.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      vals = [{ name: utils.l10n(type), format }];
      const meta = {
        stream: 'combined',
        columns: _.concat(dims, vals),
        dims,
        vals,
        cols: [],
      };
      return { data, meta };
    },

    requestDataThrottled(silent) {
      clearTimeout(this.requestDataTimeout);
      this.requestDataTimeout = setTimeout(this.requestData, 300);
    },
    async requestData() {
      const filter0 = this.extraFilter0;
      const filter1 = this.extraFilter1;
      let filter2 = this.extraFilter2;

      if (filter2) {
        filter2 = `${filter2} && optimization.optimization_run_id != ''`;
      } else {
        filter2 = 'optimization.optimization_run_id != \'\'';
      }

      this.reportId = utils.randomId();
      this.progresses = {};
      this.loading = true;

      const optimizationId = this.getUniqueTableDataFromLocalStorage('optimizationId');
      const vars = JSON.stringify({
        target_price_zone: gptable.priceZone,
        target_optimization_run_id: utils.quote(optimizationId || ''),
      });

      const query1 = `
                    query {
                        dataset {
                            streams {
                                combined {
                                    report(
                                        id: "${this.reportId}-1",
                                        name: "gp-bounds-1",
                                        cores: ${this.cores},
                                        ${filter0 ? `filter0: ${utils.quote(filter0)},` : ''}
                                        ${filter1 ? `filter1: ${utils.quote(filter1)},` : ''}
                                        ${filter2 ? `filter2: ${utils.quote(filter2)},` : ''}
                                        vars: ${utils.quote(vars)},
                                        dims: "item, target_price_zone as price_zone, optimization.optimization_run_id as optimization_run_id",
                                        vals: "optimization.current_price as current_price, optimization.modified_current_price as modified_current_price, optimization.optimal_price as optimal_price, optimization.final_price as final_price, optimization.create_user as optimization_user, optimization.create_time as optimization_time, optimization.strategy_id as strategy_id, optimization.strategy_name as strategy_name, optimization.markdown as markdown"
                                        links: [{
                                            linkName: "details",
                                            sourceName: "optimization_details_scoped",
                                            columnPairs:[
                                                { srcColumn: "item", dstColumn: "item" },
                                                { srcColumn: "price_zone", dstColumn: "price_zone" }
                                            ]
                                        }]) 
                                    {
                                        result: report(
                                            id: "${this.reportId}-2",
                                            name: "gp-bounds-2",
                                            cores: ${this.cores},
                                            vars: ${utils.quote(vars)},
                                            vals: "avg(current_price), avg(modified_current_price), avg(optimal_price), avg(final_price), min(markdown), max(markdown)",
                                            expand: "details")
                                        {
                                            rows
                                        }
                                        report(
                                            id:"${this.reportId}-3",
                                            name: "gp-bounds-3",
                                            cores: ${this.cores},
                                            vars: ${utils.quote(vars)},
                                            dims: "optimization_run_id, details.optimization_rule_id as optimization_rule_id, details.stage as stage",
                                            vals: "avg(current_price) as current_price, avg(modified_current_price) as modified_current_price, avg(optimal_price) as optimal_price, avg(final_price) as final_price, avg(details.left_bound if details.right_bound != 99999991) as left_bound, avg(details.right_bound if details.right_bound != 99999991) as right_bound, avg(details.centroid if details.right_bound != 99999991) as centroid, avg(details.error if details.right_bound != 99999991) as error, max(details.status) as status, optimization_time, min(markdown) as min_markdown, max(markdown) as max_markdown",
                                            expand: "details",
                                            links: [{
                                                linkName: "rules",
                                                sourceName: "optimization_rules_scoped",
                                                columnPairs: [
                                                    { srcColumn: "optimization_rule_id", dstColumn: "optimization_rule_id" }
                                                ]
                                            }])
                                        {
                                            report(
                                                id: "${this.reportId}-4",
                                                name: "gp-bounds-4",
                                                cores: ${this.cores},
                                                vars: ${utils.quote(vars)},
                                                dims: "optimization_rule_id, stage",
                                                vals: "last(current_price, optimization_time), last(modified_current_price, optimization_time), last(optimal_price, optimization_time), last(final_price, optimization_time), last(left_bound, optimization_time), last(right_bound, optimization_time), last(centroid, optimization_time), last(error, optimization_time), last(status, optimization_time), last(rules.optimization_rule_data, optimization_time) as rule_data")
                                            {
                                                columns {synonym}
                                                rows
                                            }
                                        }
                                        runs: report(
                                            id: "${this.reportId}-5",
                                            name: "gp-bounds-5",
                                            cores: ${this.cores},
                                            vars: ${utils.quote(vars)},
                                            dims: "optimization_run_id",
                                            vals: "strategy_id, strategy_name, optimization_time, optimization_user")
                                        {
                                            rows
                                        }
                                    }
                                }
                            }
                        }
                    }
                    `;

      const sendQuery = (query) => utils.scheduleRequest(this, {
        url: '/graphql?__requestGpBoundsData__',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      });

      try {
        const [{ data }, seasons, params] = await Promise.all([
          sendQuery(query1),
          (async () => {
            const classes = _.map(_.get(
              await sendQuery(`
                                    query {     
                                        dataset {
                                            streams {
                                                combined {
                                                    report(
                                                        cores: ${this.cores},
                                                        ${filter0 ? `filter0: ${utils.quote(filter0)},` : ''}
                                                        ${filter1 ? `filter1: ${utils.quote(filter1)},` : ''}
                                                        ${filter2 ? `filter2: ${utils.quote(filter2)},` : ''}
                                                        vars: ${utils.quote(vars)},
                                                        dims: "class")
                                                    {
                                                        rows
                                                    }
                                                }
                                            }
                                        }
                                    }`),
              'data.dataset.streams.combined.report.rows',
            ), '0');

            const startDate = gptable.parseDate(gptable.referenceDate);
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + 30);

            const seasons = _.get(
              await sendQuery(`
                                    query {     
                                        dataset {
                                            report(name:"model_season_last") {
                                                report(
                                                    cores: ${this.cores},
                                                    filter0: ${utils.quote(`class in ${utils.quote(classes)}`)},
                                                    filter2: "date >= ${utils.quote(startDate, { type: 'date' })} && date <= ${utils.quote(endDate, { type: 'date' })}",
                                                    dims: "class",
                                                    vals: "sum(season)")
                                                {
                                                    rows
                                                }
                                            }
                                        }
                                    }`),
              'data.dataset.report.report.rows',
            );
            return _.fromPairs(seasons);
          })(),
          (async () => {
            const startDate = gptable.parseDate(gptable.referenceDate);
            const endDate = new Date(startDate);
            const cost = this.resolveDateConditions(this.resolveSubstitutes('cost_inc_vat'), startDate, endDate);
            const query = `
                                query {     
                                    dataset {
                                        streams {
                                            combined {
                                                report(
                                                    cores: ${this.cores},
                                                    ${filter0 ? `filter0: ${utils.quote(filter0)},` : ''}
                                                    ${filter1 ? `filter1: ${utils.quote(filter1)},` : ''}
                                                    ${filter2 ? `filter2: ${utils.quote(filter2)},` : ''}
                                                    vars: ${utils.quote(vars)},
                                                    dims: "class, item, store",
                                                    vals: "model_params.elast, model_params.base_demand, model_params.base_price, ${cost}")
                                                {
                                                    rows
                                                }
                                            }
                                        }
                                    }
                                }`;
            return _.get(await sendQuery(query), 'data.dataset.streams.combined.report.rows');
          })(),
        ]);

        this.loading = false;
        let minValue = Number.MAX_VALUE;
        let maxValue = Number.MIN_VALUE;
        const updateMinMax = (x) => {
          if (x !== 0 && x !== 9999999) {
            if (x < minValue) {
              minValue = x;
            }
            if (x > maxValue) {
              maxValue = x;
            }
          }
        };
        const runs = _.map(
          _.get(data, 'dataset.streams.combined.report.runs.rows'),
          ([id, strategy_id, strategy_name, create_time, create_user]) => ({
            id,
            strategy: { id: strategy_id, name: strategy_name },
            time: create_time,
            user: create_user,
          }),
        );
        let result = _.get(data, 'dataset.streams.combined.report.result');
        if (result.rows.length > 0) {
          const [[currentPrice, modifiedCurrentPrice, optimalPrice, finalPrice, minMarkdown, maxMarkdown]] = result.rows;
          let markdown;

          if (!_.isEmpty(minMarkdown) && _.isEqual(minMarkdown, maxMarkdown)) {
            markdown = minMarkdown;
          }

          result = {
            currentPrice, modifiedCurrentPrice, optimalPrice, finalPrice, markdown,
          };
          updateMinMax(currentPrice);
          updateMinMax(modifiedCurrentPrice);
          updateMinMax(optimalPrice);
          updateMinMax(finalPrice);
        } else {
          result = {};
        }

        const report = _.get(data, 'dataset.streams.combined.report.report.report');
        let rows = [];

        for (const row of report.rows) {
          let [rule_id, stage, currentPrice, modifiedCurrentPrice, optimalPrice, finalPrice, leftBound, rightBound, centroid, error, status, rule] = row;
          rule = JSON.parse(rule || '{}');

          if (rule.type !== 'gross_profit') {
            const clamp = (val, min, max) => Math.min(Math.max(val, min), max);
            const minPrice = Math.min(currentPrice, modifiedCurrentPrice, optimalPrice, finalPrice);
            const maxPrice = Math.max(currentPrice, modifiedCurrentPrice, optimalPrice, finalPrice);
            updateMinMax(clamp(leftBound, minPrice / 1.5, maxPrice * 1.5));
            updateMinMax(clamp(rightBound, minPrice / 1.5, maxPrice * 1.5));
            updateMinMax(currentPrice);
            updateMinMax(modifiedCurrentPrice);
            updateMinMax(optimalPrice);
            updateMinMax(finalPrice);
          }

          // error /= rule.weight
          rows.push({
            rule_id, stage, currentPrice, modifiedCurrentPrice, optimalPrice, finalPrice, leftBound, rightBound, centroid, error, status, rule,
          });
        }
        rows = _.sortBy(rows, ({ rule }) => rule.number);
        rows = _.sortBy(rows, ({ stage }) => stage);

        if (minValue !== maxValue) {
          const padding = (maxValue - minValue) * 0.2;
          minValue = Math.max(0, minValue - padding);
          maxValue += padding;
        }

        const model = [];
        let elast;

        for (const param of params) {
          param[0] = seasons[param[0]] || 0;
        }

        for (let i = 0; i <= 100; ++i) {
          const price = minValue + (maxValue - minValue) * i / 100;
          let units = 0;
          let value = 0;
          let cogs = 0;

          for (const [season, item, store, elast, baseDemand, basePrice, costIncVat] of params) {
            if (basePrice && baseDemand && season) {
              units += baseDemand * season * Math.exp(elast * (1 - price / basePrice));
              value += price * units;
              cogs += costIncVat * units;
            }
          }

          const margin = value - cogs;

          if (units) {
            model.push({
              price,
              units,
              cogs,
              value,
              margin,
            });
          }

          elast = _(params)
            .filter(
              ([season, item, store, elast, baseDemand, basePrice, costIncVat]) => baseDemand > 0,
            )
            .map(
              ([season, item, store, elast, baseDemand, basePrice, costIncVat]) => elast,
            )
            .mean();
        }

        this.report = {
          rows, minValue, maxValue, result, runs, model, elast,
        };
      } catch (ex) {
        this.loading = false;
        console.warn('bounds query failed', ex);
      }
    },

    scale(x) {
      const { minValue, maxValue } = this.report;
      return (x - minValue) / (maxValue - minValue);
    },

    formatRuleFilter(rule) {
      return rule.filter.map((condition) => _(condition)
        .toPairs()
        .map(([k, v]) => {
          attribute = this.attributesByCalc[k];
          return `${attribute ? attribute.name : k}: ${v.join(', ')}`;
        })
        .join(` ${utils.l10n('AND')} `))
        .join(` ${utils.l10n('OR')} `);
    },

    formatRule(rule, leftBound, rightBound, centroid) {
      if (!rule.text) {
        return rule.name;
      }
      const parts = [];
      for (const segment of utils.l10n(rule.text).split('{')) {
        const closingPosition = segment.indexOf('}');
        if (closingPosition !== -1) {
          const prop = segment.slice(0, closingPosition);
          let value = _.get(rule, prop);
          if (value !== undefined) {
            switch (prop) {
              case 'min':
              case 'secondary_min':
                if (value === -99999 || value === -9999) {
                  value = '-∞';
                } else {
                  switch (rule.type) {
                    case 'relations':
                      value = `${this.formatPercent((value - 1) * 100)}`;
                      break;
                    default:
                      value = `${this.formatPercent((value - 1) * 100)} [${this.formatPrice(leftBound)}]`;
                  }
                }
                break;
              case 'max':
              case 'secondary_max':
                if (value === 100001 || value === 10001) {
                  value = '+∞';
                } else {
                  switch (rule.type) {
                    case 'relations':
                      value = `${this.formatPercent((value - 1) * 100)}`;
                      break;
                    default:
                      value = `${this.formatPercent((value - 1) * 100)} [${this.formatPrice(rightBound)}]`;
                  }
                }
                break;
              case 'target':
                if (value == null) {
                  value = '–';
                } else {
                  value = `${this.formatPercent((value - 1) * 100)}`;
                }
                break;
              case 'limit':
                if (value == null) {
                  value = '–';
                } else {
                  value = `${this.formatPercent(value * 100)}`;
                }
                break;
              case 'demand_limit':
                if (value == null) {
                  value = '–';
                } else {
                  value = this.formatUnits(value);
                }
                break;
              case 'budget':
                if (value == null) {
                  value = '–';
                } else {
                  value = this.formatMoney(value);
                }
                break;
              case 'range_start':
              case 'range_end':
              case 'rounding_ranges[0].start':
              case 'rounding_ranges[0].end':
              case 'revenue_limit':
              case 'margin_limit':
                if (value == null) {
                  value = '–';
                } else if (value === -10000000 || value === -1000000) {
                  value = '-∞';
                } else if (value === 10000000 || value === 1000000) {
                  value = '+∞';
                } else {
                  value = this.formatPrice(value);
                }
                break;
              case 'rounding_ranges[0].wholeEndings':
              case 'rounding_ranges[0].fractionalEndings':
              case 'rounding_ranges[0].ignorePrices':
              case 'percents':
                value = value.join(', ') || utils.l10n('<empty>');
                break;
              default:
                value = (
                  this.metricsByFormula[value]?.name
                                        || this.attributesByCalc[value]?.name
                                        || this.friendlyNames[value]
                                        || value);
            }
          }
          parts.push(value);
          const text = segment.slice(closingPosition + 1);
          parts.push(text);
        } else {
          parts.push(segment);
        }
      }
      return parts.join('');
    },

    formatTime(x) {
      return new Date(x > 1e12 ? x : x * 1000).toLocaleString(this.locale);
    },

    formatPercent(x) {
      return new Number(x / 100).toLocaleString(this.locale, {
        style: 'percent',
        maximumFractionDigits: 1,
      });
    },

    formatPrice(x) {
      return new Number(x).toLocaleString(this.locale, {
        style: 'currency',
        currency: this.currency,
        maximumFractionDigits: 2,
      });
    },

    formatUnits(x) {
      return new Number(x).toLocaleString(this.locale, {
        maximumFractionDigits: 2,
      });
    },

    formatMoney(x) {
      return new Number(x).toLocaleString(this.locale, {
        maximumFractionDigits: 2,
      });
    },

    formatFilter(filter) {
      for (const attribute of
        _(this.attributes)
          .sortBy(({ calc }) => -calc.length)
          .value()) {
        filter = filter.split(attribute.calc).join(attribute.name);
      }
      filter = filter.split(' == ').join(': ');
      filter = filter.split(' in ').join(` ${utils.l10n('IN')} `);
      filter = filter.split(' || ').join(` ${utils.l10n('OR')} `);
      filter = filter.split(' && ').join(` ${utils.l10n('AND')} `);
      return filter;
    },

    updateRulesInScope(report) {
      this.report = report;
    },

    lookupRule(ruleId) {
      if (localStorage.currentRules) {
        try {
          return JSON.parse(localStorage.currentRules).find((rule) => rule.id === ruleId);
        } catch (ex) {
          console.warn(ex);
        }
      }
      return null;
    },

    handleWsMessage(message) {
      if (message.progress
      && message.reportId
      && message.reportId.indexOf(this.reportId) !== -1) {
        this.$set(
          this.progresses,
          message.reportId,
          message.progress,
        );
      }
    },
  },
};

</script>

<style scoped>
.gp-bounds table {
  width: 100%;
  font-size: 0.9em;
}
.gp-bounds table td {
  border-top: 1px solid gray;
  padding: 4px;
  margin: 0;
}
.gp-bounds table td:nth-child(1) {
  /*white-space: pre-line;*/
}
.gp-bounds table td:nth-child(3) {
  text-align: right;
  white-space: nowrap;
}
.gp-bounds table td:nth-child(2) {
  width: 40%;
  height: 1px;
}
.gp-bounds-rule {
  position: relative;
  height: calc(100% + 8px);
  margin-top: -4px;
  margin-bottom: -4px;
  /*background-color: red;*/
  margin-left: 20px;
  margin-right: 20px;
  pointer-events: none;
}
.gp-bounds-bounds {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #52b78860;
  border-left: 2px solid #52b78860;
  border-right: 2px solid #52b78860;
}
.gp-bounds-bounds:before,
.gp-bounds-bounds:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background: repeating-linear-gradient(
      45deg,
      #52b78860,
      #52b78860 2px,
      transparent 6px,
      transparent 8px,
      #52b78860 10px);
  background-attachment: scroll;
}
.gp-bounds-bounds:before {
  right: calc(100% + 0px);
}
.gp-bounds-bounds:after {
  left: calc(100% + 0px);
}
.gp-bounds-current-price,
.gp-bounds-optimal-price,
.gp-bounds-final-price {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  transform: translate(-50%, 0);
  border-left: 0.5px solid white;
  border-right: 0.5px solid white;
  background-attachment: scroll;
}
.gp-bounds-current-price {
  background: var(--cyan);
}
.gp-bounds-optimal-price {
  background: var(--orange);
}
.gp-bounds-final-price {
  background: var(--red);
}
.gp-bounds em {
  font-style: normal;
  font-weight: bold;
}
.gp-bounds td {
  vertical-align: top;
}
.gp-bounds .loading {
  opacity: 0.7;
}
.gp-bounds .feather-icon svg {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}
.gp-bounds table td {
  position: relative;
}
.gp-bounds-rule {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
}
.my-dark-theme .gp-bounds-current-price,
.my-dark-theme .gp-bounds-optimal-price,
.my-dark-theme .gp-bounds-final-price {
  border-left: 0.5px solid #222;
  border-right: 0.5px solid #222;
}
.gp-bounds-filter {
  font-size: 0.9em;
  opacity: 0.9;
  margin: 0;
  margin-bottom: 4px;
}
.gp-bounds .feather-icon-check {
  color: var(--green);
}
.gp-bounds .feather-alert-triangle {
  color: var(--orange);
}
.gp-bounds .feather-alert-circle {
  color: var(--red);
}
.gp-bounds-left-price {
  font-size: 0.9em;
  float: left;
}
.gp-bounds-right-price {
  font-size: 0.9em;
  float: right;
}
.gp-bounds-legend {
  text-align: right;
  margin-bottom: 8px;
  font-weight: bold;
}
.gp-bounds .gp-check {
  margin-bottom: 4px;
  margin-left: 5px;
}
.gp-bounds-legend span {
  font-weight: normal;
}
.gp-bounds-legend-current-price:before,
.gp-bounds-legend-modified-current-price::before,
.gp-bounds-legend-optimal-price:before,
.gp-bounds-legend-final-price:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 1px 4px;
}
.gp-bounds-legend-current-price:before {
  background-color: var(--cyan);
}
.gp-bounds-legend-modified-current-price:before {
  background-color: var(--teal);
}
.gp-bounds-legend-optimal-price:before {
  background-color: var(--orange);
}
.gp-bounds-legend-final-price:before {
  background-color: var(--red);
}
.gp-bounds table p {
  margin: 4px 0;
  line-height: 1.3;
}
.gp-bounds table td {
  overflow: hidden;
}
.gp-bounds table .feather-icon {
  width: 20px;
  margin-right: -20px;
}
.gp-bounds table .feather-icon + * {
  padding-left: 22px;
  display: inline-block;
}
.gp-bounds-bounds,
.gp-bounds-current-price,
.gp-bounds-optimal-price,
.gp-bounds-final-price {
  transform: translatex(-2px);
}
.gp-bounds .strict > td:first-child a:after {
  content: " [strict]";
  color: var(--orange);
}
.gp-bounds-weight {
  float: right;
  margin-top: 2px;
  margin-left: 10px;
  color: var(--dark);
}
.gp-bounds-related .plain-table-slider {
  margin: 0;
  margin-top: 4px;
}
.gp-bounds-related .feather-icon-download {
  display: none;
}
.gp-bounds-runs {
  list-style: none;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 0;
  font-size: 0.9em;
}
.gp-bounds-series {
  margin-top: 8px;
}
.gp-bounds-series path:nth-child(1) {
  fill: none;
  stroke: var(--red);
  stroke-width: 0.5;
}
.gp-bounds-series path:nth-child(2) {
  fill: #20c99738;
}
.gp-bounds-series path:nth-child(3) {
  fill: none;
  stroke: var(--teal);
  stroke-width: 1.2;
}
.gp-bounds-series circle {
  fill: var(--teal);
  stroke: white;
  stroke-width: 1;
}
.gp-bounds td:first-child {
  vertical-align: middle;
}
.gp-bounds td:last-child {
  padding: 0;
  vertical-align: bottom;
}
.gp-bounds-series {
  transform: scale(1.04);
}
.gp-bounds {
  margin-bottom: 10px;
}
.gp-bounds table {
  margin-bottom: 20px;
}
.gp-bounds-runs {
  list-style: none;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 0;
  font-size: 0.9em;
}
.gp-bounds .strict > td:first-child a:after {
  content: " [strict]";
  color: var(--orange);
}
.gp-bounds-weight {
  float: right;
  margin-top: 2px;
  margin-left: 10px;
  color: var(--dark);
}
.gp-bounds-filter {
  font-size: 0.9em;
  opacity: 0.9;
  margin: 0;
  margin-bottom: 4px;
}
::-webkit-calendar-picker-indicator {
  padding: 0;
  margin: 0;
}
*[data-popper-placement] {
  z-index: 10 !important;
}
.gp-bounds .feather-icon-check {
  color: var(--green);
}
.gp-bounds .feather-alert-triangle {
  color: var(--orange);
}
.gp-bounds .feather-alert-circle {
  color: var(--red);
}
.gp-bounds-left-price {
  font-size: 0.9em;
  float: left;
}
.gp-bounds-right-price {
  font-size: 0.9em;
  float: right;
}
.gp-bounds-legend {
  text-align: right;
  margin-bottom: 8px;
  font-weight: bold;
}
.gp-bounds .gp-check {
  margin-bottom: 4px;
  margin-left: 5px;
}
.gp-bounds-legend span {
  font-weight: normal;
}
.gp-bounds-legend-current-price:before,
.gp-bounds-legend-optimal-price:before,
.gp-bounds-legend-final-price:before,
.gp-bounds-legend-markdown-price:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 1px 4px;
}
.gp-bounds-legend-current-price:before {
  background-color: var(--cyan);
}
.gp-bounds-legend-optimal-price:before {
  background-color: var(--orange);
}
.gp-bounds-legend-final-price:before {
  background-color: var(--red);
}
.gp-bounds-legend-markdown-price:before {
  background-color: var(--purple);
}
.gp-bounds table p {
  margin: 4px 0;
  line-height: 1.3;
}
.gp-bounds table td {
  overflow: hidden;
}
.gp-bounds table .feather-icon {
  width: 20px;
  margin-right: -20px;
}
.gp-bounds table .feather-icon + * {
  padding-left: 22px;
  display: inline-block;
}
.gp-bounds-bounds,
.gp-bounds-current-price,
.gp-bounds-optimal-price,
.gp-bounds-final-price {
  transform: translatex(-2px);
}
.gp-bounds-related .plain-table-slider {
  margin: 0;
  margin-top: 4px;
}
.gp-bounds-related .feather-icon-download {
  display: none;
}
.gp-bounds table {
  line-height: 1.4;
}
.gp-bounds-markdown .my-progress {
  display: none;
}
</style>
