<template>
  <div class="my-backups">
    <ul>
      <li v-for="backup, key in showMore ? backups : backups.slice(0,20)" :key="key">
        <a @click="toggleBackup(backup)" href="javascript:void(0)" nofollow>
          <feather-icon :name="opened[backup.page] ? 'chevron-down' : 'chevron-right'" size="18px"/>
          {{backup.date}}
        </a>
        <span style="display:inline-block;width:6px"/>
        <a :href="backup.link" nofollow target="_blank">
          <feather-icon name="external-link" size="18px"/>
        </a>
        <div v-if="opened[backup.page]">
          <div
            v-if="opened[backup.page].html"
            v-html="opened[backup.page].html"/>
          <div v-else>Loading...</div>
        </div>
      </li>
      <li v-if="backups.length > 20 && !showMore">
        <a href="javascript:void(0)" @click="showMore = true">and {{Number(backups.length-20).toLocaleString()}} more...</a>
      </li>
    </ul>
  </div>
</template>
<script>
let utils = require('./my-utils');
module.exports = {
  data() {
    return {
      backups: [],
      timer: null,
      opened: {},
      showMore: false,
    };
  },
  mounted() {
    this.refresh();
    this.timer = setInterval(this.refresh, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    path() {
      return window.location.pathname.split('/').slice(3,-1).join('/');
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss');
    },
    async toggleBackup(backup) {
      if (this.opened[backup.page]) {
        this.$delete(this.opened, backup.page);
      } else {
        this.$set(this.opened, backup.page, {});

        let fetchPage = async path => {
          let query = `query { page(path:${utils.quote(path)}) { assets { name link } } }`;
          let { data: { page } } = await utils.fetchWithAjaxOpts({
            url: '/graphql?id=backups&__toggleBackup__',
            method: 'POST',
            data: JSON.stringify({ query }),
            dataType: 'json',
            contentType: 'application/json',
          });
          for (let asset of page.assets) {
            if (asset.name.endsWith('.yml') ||
                            asset.name.endsWith('.md') ||
                            asset.name.endsWith('.js') ||
                            asset.name.endsWith('.css')) {
              asset.text = await (await fetch(asset.link)).text();
            }
          }
          return page;
        };

        let path2 = backup.path;
        let path1 = this.backups[this.backups.indexOf(backup)+1]?.path;
        let page1 = path1 ? await fetchPage(path1) : {};
        let page2 = path2 ? await fetchPage(path2) : {};

        if (!this.opened[backup.path]) {
          return;
        }

        Vue.set(this.opened[backup.path], 'page1', page1);
        Vue.set(this.opened[backup.path], 'page2', page2);

        if (page2) {
          let helper = await import('./backups.js');

          let computeDiff = (name, text1, text2) => {
            if (!text1) {
              text1 = '';
            }
            if (!text2) {
              text2 = '';
            }
            window.ATAT_REGEX = /^.*@@.+@@.*$/m;
            window.CR = /(?:\r?\n)/;
            window.CRE = /(?:\r?\n)$/;
            window.CRS = '(?:\r?\n)';
            if (text1 == text2) {
              return '';
            }
            let diff = helper.diff(text1, text2, { wordDiff: false });
            let lines = diff.split('\n');
            let context = new Set();
            let output = [];
            output.push(`index ${path1}..${path2} 100644`);
            output.push(`--- a/${name}`);
            output.push(`+++ b/${name}`);
            for (let i=0; i<lines.length; ++i) {
              if (lines[i].match(/^[-+]/)) {
                for (let j = i-10; j <= i+10; ++j) {
                  context.add(j);
                }
              }
            }
            let l = 0;
            let r = 0;
            for (let i=0; i<lines.length; ++i) {
              let line = lines[i];
              if (context.has(i)) {
                if (i == 0 || !context.has(i-1)) {
                  output.push(`@@ -${l},${0} +${r},0 @@`);
                }
                output.push(line);
              }
              if (!line.startsWith('+')) {
                l += 1;
              }
              if (!line.startsWith('-')) {
                r += 1;
              }
            }
            output = output.join('\n') + '\n';
            return output;
          };
          let formatDiff = diff => {
            return helper.html(diff, {
              matching: 'words',
              outputFormat: 'line-by-line',
            });
          };
          let diff = [];
          for (let { name, text: text1 } of page1.assets) {
            let text2 = page2.assets.find(asset => asset.name == name)?.text;
            diff.push(computeDiff(name, text1, text2));
          }
          for (let { name, text: text2 } of page2.assets) {
            if (!page1.assets.find(asset => asset.name == name)) {
              let text1 = undefined;
              diff.push(computeDiff(name, text1, text2));
            }
          }
          diff = diff.join('\n');

          Vue.set(this.opened[backup.path], 'diff', diff);

          let html = formatDiff(diff);
          Vue.set(this.opened[backup.path], 'html', html);
          // function html(diffInput: string | DiffFile[], configuration: Diff2HtmlConfig = {}): string;
        }
      }
    },
    refresh() {
      let path = window.location.pathname.split('/');
      let base = path.slice(2,-2).join('/');
      let mask = `^${path[path.length-2]}_.*`;
      let query = `query { pages(base:${utils.quote(base)}, mask:${utils.quote(mask)}) }`;
      utils.fetchWithAjaxOpts({
        url: '/graphql?id=backups&__refreshBackup__',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      })
        .then(({ data: { pages } }) => {
          this.backups = _(pages)
            .map((page) => {
              let link = `/pages/${page}/`;
              let path = page;
              let date = this.formatDate(_.last(page.split('_')));
              return { path, page, link, date };
            })
            .orderBy(['date'], ['desc'])
            .value();
        });
    },
  },
};
</script>
<style>
.my-backups ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.my-backups li .feather-icon svg {
    transform: translate(0, -3px);
}
</style>
