var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quarter-select-container" }, [
    _c(
      "label",
      { attrs: { for: "select-target-quarter" } },
      [_c("l10n", { attrs: { value: "Select target quarter" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.targetQuarters,
            expression: "targetQuarters"
          }
        ],
        staticClass:
          "form-control form-control-sm dropdown-select target-quater-select",
        attrs: { id: "select-target-quarter", multiple: "" },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.targetQuarters = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onSelectQuarter
          ]
        }
      },
      _vm._l(_vm.optionItems, function(option) {
        return _c(
          "option",
          {
            key: "target-quarter-" + option,
            domProps: {
              value: option,
              selected: _vm.targetQuarters.indexOf(option) !== -1
            }
          },
          [_c("l10n", { attrs: { value: _vm.getValueForOption(option) } })],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }