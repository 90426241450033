<!-- eslint-disable camelcase -->
<template>
  <my-popup
    :anchor="clickedButtonElement"
    placement="insideTable"
    @clickoutside="handleModalClose"
  >
    <div class="popover modal-container">
      <div :style="{ opacity: loading ? 0.3 : 1 }">
        <p>Выберите 3 изменяемых показателя</p>

        <div class="checkboxes-container">
          <div class="checkboxes-column">
            <gp-check
              :checked="receiptUnit"
              :disabled="isReceiptUnitDisabled"
              @change="handleReceiptUnitChange"
            >
              <l10n value="Receipt unit" />
            </gp-check>

            <gp-check
              :checked="receiptRub"
              :disabled="isReceiptRubDisabled"
              @change="handleReceiptRubChange"
            >
              <l10n value="Receipt rub" />
            </gp-check>

            <gp-check
              :checked="receiptCost"
              :disabled="isReceiptCostDisabled"
              @change="handleReceiptCostChange"
            >
              <l10n value="Receipt cost" />
            </gp-check>
          </div>
          <div class="checkboxes-column">
            <gp-check
              :checked="imu"
              :disabled="isImuDisabled"
              @change="handleImuChange"
            >
              <l10n value="IMU" />
            </gp-check>

            <gp-check
              :checked="costPrice"
              :disabled="isCostPriceDisabled"
              @change="handleCostPriceChange"
            >
              <l10n value="Cost price" />
            </gp-check>

            <gp-check
              :checked="rrp"
              :disabled="isRrpDisabled"
              @change="handleRrpChange"
            >
              <l10n value="RRP" />
            </gp-check>
          </div>
        </div>

        <div class="footer-buttons">
          <button
            type="button"
            class="btn btn-sm btn-primary btn-apply"
            :disabled="!threeCheckboxesChecked"
            @click="handleApplyChanges"
          >
            <l10n value="Apply changes" />
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="handleDeleteChanges"
          >
            <l10n value="Delete" />
          </button>
        </div>
      </div>
      <div v-if="loading" class="gp-season-base-lock-model__loader-container">
        <div class="loader" />
      </div>
    </div>
  </my-popup>
</template>

<script>
const utils = require('../my-utils');
const graphQl = require('../api/graphQl');

module.exports = {
  props: {
    isSeasonBaseLockModalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    clickedButtonElement: {
      type: [
        Object,
        HTMLElement,
      ],
      required: true,
    },
    bindingData: {
      type: Object,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      receiptUnit: false,
      receiptRub: false,
      receiptCost: false,
      imu: false,
      costPrice: false,
      rrp: false,
      count: 0,
      loading: false,
    };
  },

  computed: {
    threeCheckboxesChecked() {
      return this.count === 3;
    },
    isReceiptUnitDisabled() {
      return (!this.receiptUnit && this.threeCheckboxesChecked)
      || (this.receiptRub && this.rrp)
      || (this.receiptCost && this.costPrice);
    },
    isReceiptRubDisabled() {
      return (!this.receiptRub && this.threeCheckboxesChecked)
      || (this.receiptUnit && this.rrp)
      || (this.receiptCost && this.imu);
    },
    isReceiptCostDisabled() {
      return (!this.receiptCost && this.threeCheckboxesChecked)
      || (this.receiptUnit && this.costPrice)
      || (this.receiptRub && this.imu);
    },
    isImuDisabled() {
      return (!this.imu && this.threeCheckboxesChecked)
      || (this.costPrice && this.rrp)
      || (this.receiptRub && this.receiptCost);
    },
    isCostPriceDisabled() {
      return (!this.costPrice && this.threeCheckboxesChecked)
      || (this.imu && this.rrp)
      || (this.receiptUnit && this.receiptCost);
    },
    isRrpDisabled() {
      return (!this.rrp && this.threeCheckboxesChecked)
      || (this.imu && this.costPrice)
      || (this.receiptUnit && this.receiptRub);
    },
  },

  methods: {
    handleReceiptUnitChange(checked) {
      this.receiptUnit = checked;
      this.countChecked(checked);
    },
    handleReceiptRubChange(checked) {
      this.receiptRub = checked;
      this.countChecked(checked);
    },
    handleReceiptCostChange(checked) {
      this.receiptCost = checked;
      this.countChecked(checked);
    },
    handleImuChange(checked) {
      this.imu = checked;
      this.countChecked(checked);
    },
    handleCostPriceChange(checked) {
      this.costPrice = checked;
      this.countChecked(checked);
    },
    handleRrpChange(checked) {
      this.rrp = checked;
      this.countChecked(checked);
    },
    countChecked(checked) {
      if (checked) {
        this.count += 1;
      } else {
        this.count -= 1;
      }
    },
    handleModalClose() {
      this.$emit('season-base-lock-modal-close');
    },
    handleApplyChanges() {
      const brand = this.getValueFromBindingData('brand', 1);
      const season = this.getValueFromBindingData('season', 2);
      const collection = this.getValueFromBindingData('collection', 3);
      const division = this.getValueFromBindingData('division', 4);
      const targetGroup = this.getValueFromBindingData('target_group', 5);
      const category = this.getValueFromBindingData('category', 6);
      const categoryPlus = this.getValueFromBindingData('category_plus', 7);
      const year = this.getValueFromBindingData('year', 8);
      const halfYear = this.getValueFromBindingData('half_year', 9);
      const quarter = this.getValueFromBindingData('quarter', 10);
      const month = this.getValueFromBindingData('month', 11);
      const week = this.getValueFromBindingData('week', 12);
      const dateString = this.getValueFromBindingData('date_string', 13);
      const productionType = this.getValueFromBindingData('production_type', 14);
      const { level } = this.bindingData;
      const createUser = this.username;
      const createTime = Date.now();

      this.makeSnapshotRequest(
        brand,
        season,
        collection,
        division,
        targetGroup,
        category,
        categoryPlus,
        year,
        halfYear,
        quarter,
        month,
        week,
        dateString,
        productionType,
      ).then(async (response) => {
        if (!response.ok) {
          const responseData = await response.json();
          const errorMessage = responseData?.error?.message;

          if (errorMessage) {
            this.createNotification(errorMessage, 'error');
          } else {
            this.createNotification('An unknown error occured while fetching', 'error');
          }

          return;
        }

        const record = [
          brand,
          season,
          collection,
          division,
          targetGroup,
          category,
          categoryPlus,
          year,
          halfYear,
          quarter,
          month,
          week,
          dateString,
          productionType,
          createUser,
          createTime,
          this.receiptUnit ? level : -1,
          this.receiptCost ? level : -1,
          this.receiptRub ? level : -1,
          this.costPrice ? level : -1,
          this.imu ? level : -1,
          this.rrp ? level : -1,
        ];

        let records = [record];
        records = JSON.stringify(records);

        graphQl.makeGQRequest(`
      mutation {
        appendRecords(
          stream: "pp_season_indicators",
          format: "json",
          records: ${utils.quote(records)})
        }
      `, '/graphql?__changeSeasonBaseIndicators__').then((result) => {
          if (!result.errors && !result.error) {
            this.createNotification('Indicators been successfully changed', 'success');
            this.handleModalClose();
          }
        }).catch(() => {
          this.createNotification('Error while setting indicators', 'error');
        });
      }).catch(() => {
        this.createNotification('Error while making snapshot request', 'error');
      }).finally(() => {
        this.loading = false;
        this.$emit('refresh-table');
      });
    },
    makeSnapshotRequest(
      brand,
      season,
      collection,
      division,
      target_group,
      category,
      category_plus,
      year,
      half_year,
      quarter,
      month,
      week,
      date_string,
      production_type,
    ) {
      const url = '/api/product-plan/values-snapshot';
      const data = {
        user: this.username,
        filters: {
          brand,
          season,
          collection,
          division,
          target_group,
          category,
          category_plus,
          year,
          half_year,
          quarter,
          month,
          week,
          date_string,
          production_type,
        },
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      this.loading = true;

      return fetch(url, options);
    },
    getValueFromBindingData(key, level) {
      if (String(this.bindingData[key]).includes('***') || level > this.bindingData.level) {
        return '';
      }
      return this.bindingData[key];
    },
    handleDeleteChanges() {
      this.$emit('season-base-lock-delete');
    },
  },
};
</script>

<style scoped>
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", IBM Plex Sans, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8203125rem;
  word-wrap: break-word;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 1px 1px 5px;
}

.modal-container {
  padding: 0.5rem;
}

.checkboxes-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.checkboxes-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer-buttons {
  display: flex;
  justify-content: flex-end;
}

.btn-apply {
  margin-right: 5px;
}

.btn-apply:disabled {
  cursor: not-allowed;
  opacity: 0.1;
}
.gp-season-base-lock-model__loader-container {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .loader {
  --s: 25px;
  --g :5px;

  width: calc(2*(1.353*var(--s) + var(--g)));
  aspect-ratio: 1;
  background:
    linear-gradient(var(--cyan) 0 0) left/50% 100% no-repeat,
    conic-gradient(from -90deg at var(--s) calc(0.353*var(--s)),
      #fff 135deg,#666 0 270deg,#aaa 0);
  background-blend-mode: multiply;
  --_m:
    linear-gradient(to bottom right,
       #0000 calc(0.25*var(--s)),#000 0 calc(100% - calc(0.25*var(--s)) - 1.414*var(--g)),#0000 0),
    conic-gradient(from -90deg at right var(--g) bottom var(--g),#000 90deg,#0000 0);
  -webkit-mask: var(--_m);
          mask: var(--_m);
  background-size:   50% 50%;
  -webkit-mask-size: 50% 50%;
          mask-size: 50% 50%;
  -webkit-mask-composite: source-in;
          mask-composite: intersect;
  animation: l9 1.5s infinite;
}
@keyframes l9 {
  0%,12.5%    {background-position:0% 0%,0 0}
  12.6%,37.5% {background-position:100% 0%,0 0}
  37.6%,62.5% {background-position:100% 100%,0 0}
  62.6%,87.5% {background-position:0% 100%,0 0}
  87.6%,100%  {background-position:0% 0%,0 0}
}
</style>
