var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "root",
      staticClass: "my-chosen",
      attrs: { id: _vm.id, "data-selected-options": _vm.selection.length }
    },
    [
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _c(
        "select",
        {
          ref: "select",
          attrs: {
            multiple: _vm.multiple,
            placeholder: _vm.placeholder10n,
            "data-placeholder": _vm.placeholder10n
          }
        },
        [
          _c("option", {
            attrs: { value: "", disabled: "disabled" },
            domProps: { selected: _vm.selection.length == 0 }
          }),
          _vm._v(" "),
          _vm._l(_vm.options, function(option) {
            return _c(
              "option",
              {
                key: option[0],
                domProps: {
                  value: option[0],
                  selected: _vm.selection.find(function(selected) {
                    return selected[0] == option[0]
                  })
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.formatValue(option)) + "\n    ")]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }