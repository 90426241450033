<template>
  <div class="year-select-container">
    <label for="select-target-year">
      <l10n value="Select target year" />
    </label>
    <select
      id="select-target-year"
      v-model="targetYear"
      class="form-control form-control-sm dropdown-select year-select"
      @change="onSelectYear"
    >
      <option
        value=""
        disabled
        selected
      >
        {{ placeholder10n }}
      </option>
      <option
        v-for="item in optionItems"
        v-bind:key="item.targetYear"
        :value=item.targetYear
      >
        <l10n :value=item.targetYear /></option>
    </select>
  </div>
</template>

<script>
const graphQl = require('../api/graphQl');
const utils = require('../my-utils');

module.exports = {
  props: {
    username: {
      type: String,
      required: true,
      default: '',
    },
    stream: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      targetYear: '',
      optionItems: [],
      placeholder10n: utils.l10n('Select target year'),
    };
  },

  mounted() {
    this.getTargetYearsList();
    this.getSelectedYear();
  },

  methods: {
    getTargetYearsList() {
      graphQl.makeGQRequest(`
        query {
          dataset {
            source(name: "target_year_dictionary") {
              report(
                cores: 64,
                cache: true,
                dims: "target_year, LY, LLY, LLLY, LLLLY, LLLLLY",
                sort: [])
              {
                rows
              }
            }
          }
        }`, '/graphql?__getTargetYearsList__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;
        const data = rows.map((row) => ({
          targetYear: row[0],
          LY: row[1],
          LLY: row[2],
          LLLY: row[3],
          LLLLY: row[4],
          LLLLLY: row[5],
        }));
        this.optionItems = data;
      }).catch(() => {
        this.createNotification('Error while fetching target years', 'error');
      });
    },

    getSelectedYear() {
      graphQl.makeGQRequest(`
        query {
          dataset {
            source(name: "${this.stream}_last") {
              report(
                cores: 64,
                cache: true,
                dims: "target_year",
                sort: [])
              {
                rows
              }
            }
          }
        }`, '/graphql?__getSelectedTargetYear__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;
        const data = rows[0][0];
        this.targetYear = data;
        this.$emit('target-year-changed', this.targetYear);
      }).catch(() => {
        this.createNotification('Error while fetching selected target year', 'error');
      });
    },

    onSelectYear(e) {
      const targetYear = e.target.value;
      const { stream } = this;
      const createUser = this.username;
      const createTime = Date.now();
      const {
        LY,
        LLY,
        LLLY,
        LLLLY,
        LLLLLY,
      } = this.optionItems.find((item) => item.targetYear === targetYear);
      const record = [];

      record.push(createUser);
      record.push(createTime);
      record.push(targetYear);
      record.push(LY);
      record.push(LLY);
      record.push(LLLY);
      record.push(LLLLY);
      record.push(LLLLLY);

      let records = [];
      records.push(record);
      records = JSON.stringify(records);

      graphQl.makeGQRequest(`
      mutation {
        appendRecords(
          stream: ${utils.quote(stream)},
          format: "json",
          records: ${utils.quote(records)})
      },`, '/graphql?__changeTargetYear__').then((result) => {
        if (result.data) {
          this.createNotification('Target year has been successfully changed', 'success');
          this.$emit('target-year-changed', targetYear);
        } else if (result.errors) {
          result.errors.forEach((error) => {
            this.createNotification(error.message, 'error');
          });
        }
      }).catch(() => {
        this.createNotification('Error while setting target year', 'error');
      });
    },
  },
};
</script>

<style scoped>
.year-select-container {
  margin-bottom: 10px;
}
.year-select-container label {
  font-weight: bold;
}
</style>
