var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      ref: "select",
      staticStyle: { _display: "none" },
      attrs: {
        readonly: _vm.readonly,
        disabled: _vm.disabled,
        multiple: _vm.multiple,
        placeholder: _vm.placeholder,
        "data-placeholder": _vm.placeholder
      }
    },
    [
      true
        ? [
            _c("option", {
              key: "",
              attrs: { value: "", disabled: "disabled" }
            }),
            _vm._v(" "),
            _vm._l(_vm.optionsEx, function(option) {
              return _c(
                "option",
                {
                  ref: "options",
                  refInFor: true,
                  domProps: { value: option.value }
                },
                [_vm._v("\n      " + _vm._s(option.name) + "\n    ")]
              )
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }