var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-popup",
    {
      attrs: { anchor: _vm.clickedButtonElement, placement: "insideTable" },
      on: { clickoutside: _vm.handleModalClose }
    },
    [
      _c("div", { staticClass: "popover modal-container" }, [
        _c("div", { style: { opacity: _vm.loading ? 0.3 : 1 } }, [
          _c("p", [_vm._v("Выберите 3 изменяемых показателя")]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxes-container" }, [
            _c(
              "div",
              { staticClass: "checkboxes-column" },
              [
                _c(
                  "gp-check",
                  {
                    attrs: {
                      checked: _vm.receiptUnit,
                      disabled: _vm.isReceiptUnitDisabled
                    },
                    on: { change: _vm.handleReceiptUnitChange }
                  },
                  [_c("l10n", { attrs: { value: "Receipt unit" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "gp-check",
                  {
                    attrs: {
                      checked: _vm.receiptRub,
                      disabled: _vm.isReceiptRubDisabled
                    },
                    on: { change: _vm.handleReceiptRubChange }
                  },
                  [_c("l10n", { attrs: { value: "Receipt rub" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "gp-check",
                  {
                    attrs: {
                      checked: _vm.receiptCost,
                      disabled: _vm.isReceiptCostDisabled
                    },
                    on: { change: _vm.handleReceiptCostChange }
                  },
                  [_c("l10n", { attrs: { value: "Receipt cost" } })],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkboxes-column" },
              [
                _c(
                  "gp-check",
                  {
                    attrs: { checked: _vm.imu, disabled: _vm.isImuDisabled },
                    on: { change: _vm.handleImuChange }
                  },
                  [_c("l10n", { attrs: { value: "IMU" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "gp-check",
                  {
                    attrs: {
                      checked: _vm.costPrice,
                      disabled: _vm.isCostPriceDisabled
                    },
                    on: { change: _vm.handleCostPriceChange }
                  },
                  [_c("l10n", { attrs: { value: "Cost price" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "gp-check",
                  {
                    attrs: { checked: _vm.rrp, disabled: _vm.isRrpDisabled },
                    on: { change: _vm.handleRrpChange }
                  },
                  [_c("l10n", { attrs: { value: "RRP" } })],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer-buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary btn-apply",
                attrs: {
                  type: "button",
                  disabled: !_vm.threeCheckboxesChecked
                },
                on: { click: _vm.handleApplyChanges }
              },
              [_c("l10n", { attrs: { value: "Apply changes" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-danger",
                attrs: { type: "button" },
                on: { click: _vm.handleDeleteChanges }
              },
              [_c("l10n", { attrs: { value: "Delete" } })],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "gp-season-base-lock-model__loader-container" },
              [_c("div", { staticClass: "loader" })]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }