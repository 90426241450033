var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.errors, function(error) {
      return _c(
        "div",
        { staticClass: "alert alert-warning" },
        [
          error.message
            ? [_vm._v("\n      " + _vm._s(error.message) + "\n    ")]
            : error.responseJSON && error.responseJSON.errors
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(error.responseJSON.errors[0].message) +
                    "\n    "
                )
              ]
            : [
                _vm._v("\n      " + _vm._s(JSON.stringify(error)) + "\n      "),
                _c(
                  "a",
                  {
                    staticClass: "float-right",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.onRetry }
                  },
                  [_vm._v("\n        retry\n      ")]
                )
              ]
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }