<template>
  <gp-data
    class="pim-entries-attrs"
    v-model="report"
    :stream="tableConfig.stream"
    :source="tableConfig.source"
    :dims="tableConfig.dims"
    :vals="tableConfig.vals"
    :cols="tableConfig.cols"
    :filter1="tableConfig.filter1"
    :filter2="tableConfig.filter2"
  />
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  mixins: [
    utils.configHelpers,
    utils.columnsHelpers,
  ],
  props: {
    entries: { type: Array },
    fields: { type: Array },
    attrs: { type: Object },
    columns: { type: Array },
  },
  model: {
    prop: 'attrs',
    event: 'change',
  },
  data() {
    return {
      report: null,
      referenceDate: utils.formatDate(new Date()),
    };
  },
  methods: {
    makeStyles(column) {
      return [null, null];
    },
    sectionName(column) {
      return null;
    },
  },
  watch: {
    report(report) {
      const attrs = {};
      if (report) {
        const { meta, rows } = report;
        const names = meta.columns.map((column) => column.synonym);
        for (row of rows) {
          attrs[row[0]] = Object.freeze(_(names).zip(row).fromPairs());
        }
      }
      this.$emit('change', Object.freeze(attrs));
    },
  },
  computed: {
    type() {
      return this.entries && this.entries[0]?.type;
    },
    fieldGroups() {
      if (this.type == 'item') {
        return ['Item Attribute'];
      }
      if (this.type == 'variation') {
        return ['Variation Detail', 'Variation Flex Fields'];
      }
      return [];
    },
    metrics() {
      return this.config.metrics;
    },
    attributes() {
      return this.config.attributes;
    },
    formulas() {
      return this.config.formulas;
    },
    formats() {
      return this.config.formats;
    },
    timeframes() {
      return this.config.timeframes;
    },
    config() {
      const attributes = [{
        name: 'Item',
        calc: 'item.item_number',
      }, {
        name: 'Item Image',
        calc: 'image_html',
        format: 'image_html',
      }];
      const metrics = [];
      const formulas = {
        image_html: 'image',
      };
      const formats = {
        alphanumeric: (x) => x,
        boolean: (x) => (x ? 'yes' : 'no'),
        date: (x) => (x ? new Date(x).toLocaleDateString() : '-'),
        datetime: (x) => (x ? new Date(x).toLocaleString() : '-'),
        decimal: (x) => (x ? Number(x).toLocaleString() : '-'),
        dropdown: (x) => x,
        json: (x) => (x ? JSON.stringify(x) : '-'),
        multidropdown: (x) => x,
        numeric: (x) => Number(x).toLocaleString(),
        textarea: (x) => x,
        image_html: (x) => (x ? `<img src="${x}">` : ''),
      };
      const timeframes = {
        reference_date: {
          calc: (now) => [now, now],
          name: 'reference_date',
        },
      };
      for (const field of this.fields) {
        if (this.fieldGroups.includes(field.group_name)) {
          switch (field.field_type) {
            case 'alphanumeric':
            case 'textarea':
            case 'dropdown':
            case 'multidropdown':
              const attribute = {
                name: field.description || field.field_name,
                type: field.field_type,
                field,
                calc: field.api_name,
                format: field.field_type,
              };
              if (!field.is_derived || field.overridable) {
                attribute.editable = true;
                attribute.stream = field.api_name;
              }
              attributes.push(attribute);
              break;

            default:
              const metric = {
                name: field.description || field.field_name,
                type: field.field_type,
                field,
                formula: field.api_name,
                format: field.field_type,
              };
              if (!field.is_derived || field.overridable) {
                metric.editable = true;
                metric.stream = field.api_name;
              }
              metrics.push(metric);
          }
        }
      }
      return {
        attributes,
        metrics,
        formulas,
        formats,
        timeframes,
      };
    },
    tableConfig() {
      const vals = {};
      const cols = [];
      const section = {
        name: null,
        timeframe: 'reference_date_plus_1',
      };
      for (const column of this.columns) {
        this.makeVals(vals, section, column);
        this.makeCols(cols, section, column);
      }
      let stream = 'categories_items';
      const source = {
        dims: ['item_master_id'],
        filter1: `item_hierarchy_id in [${this.entries.map(({ id }) => `'${id}'`).join(', ')}]`,
        links: [{
          linkName: 'item',
          sourceName: 'items',
          columnPairs: [{
            srcColumn: 'item_master_id',
            dstColumn: 'id',
          }],
        }, {
          linkName: 'item_primary_image',
          sourceName: 'item_primary_images',
          columnPairs: [{
            srcColumn: 'item_master_id',
            dstColumn: 'item_master_id',
          }],
        }, {
          linkName: 'primary_image',
          sourceName: 'digital_assets',
          columnPairs: [{
            srcColumn: 'item_primary_image.digital_asset_id',
            dstColumn: 'id',
          }],
        }],
        funcs: [{
          name: 'image',
          calc: 'primary_image.link_url',
        }],
      };
      for (const column of this.columns) {
        const columnsMap = {
          alphanumeric: 'alphanumeric_data',
          boolean: 'boolean_data',
          date: 'date_data',
          datetime: 'datetime_data',
          decimal: 'decimal_data',
          dropdown: 'dropdown_data',
          json: 'json_data',
          multidropdown: 'dropdown_data',
          numeric: 'numeric_data',
          textarea: 'textarea_data',
          overridden: 'is_overridden',
        };
        const field = column.metric?.field || column.attribute?.field;
        if (field) {
          source.links.push({
            linkName: `${field.api_name}_last`,
            sourceName: JSON.stringify({
              source: 'item_attribute_data',
              filter1: `sku_master_field_id == '${field.id}'`,
              dims: 'item_master_id',
              vals: `last(${columnsMap[field.field_type]}, updated_at) as value`,
            }),
            columnPairs: [{
              srcColumn: 'item_master_id',
              dstColumn: 'item_master_id',
            }],
          });
          source.funcs.push({
            name: field.api_name,
            calc: `${field.api_name}_last.value`,
          });
        }
      }

      let filter1;
      let filter2;
      if (this.mode == 'simulation') {
        stream = 'items';
        const filters = [];
        for (const rule of this.entry.rules || []) {
          if (!rule.strategy
                        || (!rule.strategy.dateStart
                        || rule.strategy.dateStart <= `${this.simulationDay}T23:59:59`
                        && !rule.strategy.dateEnd
                        || rule.strategy.dateEnd >= `${this.simulationDay}T00:00:00`)) {
            filters.push(utils.makeFilter([
              this.makeFilter(rule.strategy?.itemFilter?.filter),
              this.makeFilter(rule.hasItemFilter && rule.itemFilter)]));
          }
        }
        filter1 = undefined;
        filter2 = filters.join(' || ');
      }
      return {
        stream,
        source,
        filter1,
        filter2,
        dims: ['item_master_id'],
        vals: _(vals)
          .toPairs()
          .map(([name, calc]) => ({
            name,
            calc: `${calc} as ${name}`,
            show: false,
          }))
          .sortBy('calc')
          .value(),
        cols: _(cols)
          .sortBy('calc')
          .value(),
      };
    },
  },
};
</script>
