<template>
  <div class="choropleth" ref="map" :style="{width,height}">
  </div>
</template>
<script>
module.exports = {
  props: {
    width: { type: Number, default: 400 },
    height: { type: Number, default: 300 },
  },
  mounted() {
    Promise.all([
      import('./lowres.geo.json'),
      import('d3-geo-projection')])
      .then(([geojson, projections]) => {
        window.projections = projections;

        let projection = d3.geoMercator()
          .scale([this.width / 2 / Math.PI])
          .translate([this.width / 2, this.height / 2]);

        window.projection = projection;

        let path = d3.geoPath().projection(projection);

        let svg = d3.select(this.$refs.map)
          .append('svg')
          .attr('width', this.width)
          .attr('height', this.height)
          .append('g');

        svg.selectAll('path')
          .data(geojson.features)
          .enter()
          .append('path')
          .attr('d', path)
          .style('fill', (d) => '#ccc');
      });
  },
};
</script>
<style
></style>
