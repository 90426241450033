var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-attributes", class: { loading: _vm.reportId } },
    [
      _vm.entries.length
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "pim-attributes-head" },
                [
                  _c(
                    "gp-check",
                    {
                      model: {
                        value: _vm.showEmptyValues,
                        callback: function($$v) {
                          _vm.showEmptyValues = $$v
                        },
                        expression: "showEmptyValues"
                      }
                    },
                    [_vm._v("Show empty attributes")]
                  ),
                  _vm._v(" "),
                  _c("my-search", {
                    model: {
                      value: _vm.searchString,
                      callback: function($$v) {
                        _vm.searchString = $$v
                      },
                      expression: "searchString"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.visibleFields, function(ref) {
                var field = ref.item
                var matches = ref.matches
                var formattedName = ref.formattedName
                return _vm.attributes[field.key] &&
                  (_vm.showEmptyValues ||
                    _vm.attributes[field.key].mixed ||
                    _vm.attributes[field.key].edited !== undefined ||
                    _vm.attributes[field.key].vals[_vm.cursor])
                  ? _c(
                      "div",
                      { key: field.key, staticClass: "form-group" },
                      [
                        field.type == "boolean"
                          ? _c(
                              "gp-check",
                              {
                                attrs: {
                                  checked:
                                    _vm.attributes[field.key].edited ||
                                    _vm.attributes[field.key].vals[_vm.cursor]
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.attributes[field.key],
                                      "edited",
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                formattedName
                                  ? _vm._l(formattedName, function(part) {
                                      return _c(
                                        "span",
                                        { class: { matched: part.matched } },
                                        [_vm._v(_vm._s(part.text))]
                                      )
                                    })
                                  : [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(field.name) +
                                          "\n        "
                                      )
                                    ],
                                _vm._v(" "),
                                _vm.attributes[field.key].edited !== undefined
                                  ? _c("span", { staticClass: "edited" }, [
                                      _vm._v(
                                        "\n          [edited]\n          "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$delete(
                                                _vm.attributes[field.key],
                                                "edited"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: { name: "x" }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm.attributes[field.key].mixed
                                  ? _c("span", { staticClass: "mixed" }, [
                                      _vm._v("\n          [mixed]\n        ")
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          : [
                              _c(
                                "label",
                                [
                                  formattedName
                                    ? _vm._l(formattedName, function(part) {
                                        return _c(
                                          "span",
                                          { class: { matched: part.matched } },
                                          [_vm._v(_vm._s(part.text))]
                                        )
                                      })
                                    : [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(field.name) +
                                            "\n          "
                                        )
                                      ],
                                  _vm._v(" "),
                                  _vm.attributes[field.key].edited !== undefined
                                    ? _c("span", { staticClass: "edited" }, [
                                        _vm._v(
                                          "\n            [edited]\n            "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "javascript:void" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$delete(
                                                  _vm.attributes[field.key],
                                                  "edited"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: { name: "x" }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm.attributes[field.key].mixed
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mixed",
                                          attrs: {
                                            title: _vm.attributes[
                                              field.key
                                            ].mixed.join("\n")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            [mixed]\n            "
                                          ),
                                          _c("feather-icon", {
                                            attrs: { name: "info" }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              field.type == "datetime"
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    attrs: { type: "datetime-local" },
                                    domProps: {
                                      value:
                                        _vm.attributes[field.key].edited ||
                                        _vm.attributes[field.key].vals[
                                          _vm.cursor
                                        ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.attributes[field.key],
                                          "edited",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              field.type == "date"
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    attrs: { type: "date" },
                                    domProps: {
                                      value:
                                        _vm.attributes[field.key].edited ||
                                        _vm.attributes[field.key].vals[
                                          _vm.cursor
                                        ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.attributes[field.key],
                                          "edited",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : field.type == "dropdown"
                                ? _c("select", {
                                    staticClass: "form-control form-control-sm",
                                    domProps: {
                                      value:
                                        _vm.attributes[field.key].edited ||
                                        _vm.attributes[field.key].vals[
                                          _vm.cursor
                                        ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.attributes[field.key],
                                          "edited",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : field.type == "textarea"
                                ? _c("textarea", {
                                    staticClass: "form-control form-control-sm",
                                    domProps: {
                                      value:
                                        _vm.attributes[field.key].edited ||
                                        _vm.attributes[field.key].vals[
                                          _vm.cursor
                                        ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.attributes[field.key],
                                          "edited",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : field.type == "alphanumeric"
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    domProps: {
                                      value:
                                        _vm.attributes[field.key].edited ||
                                        _vm.attributes[field.key].vals[
                                          _vm.cursor
                                        ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.attributes[field.key],
                                          "edited",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : field.type == "decimal" ||
                                  field.type == "numeric"
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    attrs: { type: "number" },
                                    domProps: {
                                      value:
                                        _vm.attributes[field.key].edited ||
                                        _vm.attributes[field.key].vals[
                                          _vm.cursor
                                        ]
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.$set(
                                          _vm.attributes[field.key],
                                          "edited",
                                          parseFloat($event.target.value)
                                        )
                                      }
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(field.type))])
                            ]
                      ],
                      2
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _c("div", { staticClass: "pim-hierarchies-attributes-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    attrs: { disabled: !_vm.attributesChanged },
                    on: { click: _vm.submitAttributes }
                  },
                  [_vm._v("\n        Submit changes\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { disabled: !_vm.attributesChanged },
                    on: { click: _vm.discardAttributes }
                  },
                  [_vm._v("\n        Discard changes\n      ")]
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.fields && _vm.entries.length
        ? _c("gp-data", {
            attrs: {
              stream: _vm.stream,
              groups: ["pim-attributes"],
              filter2:
                _vm.idColumn +
                " in [" +
                _vm.entries
                  .map(function(entry) {
                    return "'" + entry.id + "'"
                  })
                  .join(",") +
                "]",
              dims: ["id"],
              vals: _vm.fields.map(function(row) {
                return row.key
              }),
              throttled: false
            },
            on: {
              reportId: function($event) {
                _vm.reportId = $event
              }
            },
            model: {
              value: _vm.valuesReport,
              callback: function($$v) {
                _vm.valuesReport = $$v
              },
              expression: "valuesReport"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }