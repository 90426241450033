var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "my-dialog",
        {
          attrs: { title: "Добавить новый магазин" },
          on: { close: _vm.closeForm }
        },
        [
          _c("div", { staticClass: "inputblock" }, [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "store" } },
              [_c("l10n", { attrs: { value: "Store" } })],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.store,
                  expression: "store"
                }
              ],
              staticClass: "inputblock__input input",
              class: { input_invalid: _vm.tryToSendForm && !_vm.store.length },
              attrs: { id: "store", placeholder: "Введите название магазина" },
              domProps: { value: _vm.store },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.store = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c(
                "label",
                { staticClass: "inputblock__label", attrs: { for: "channel" } },
                [_vm._v("Канал")]
              ),
              _vm._v(" "),
              _c("my-multiselect", {
                attrs: {
                  id: "channel",
                  options: _vm.channels,
                  selectedOption: _vm.channel,
                  placeholder: "Выберите канал"
                },
                on: { select: _vm.onChannelSelect }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c(
                "label",
                { staticClass: "inputblock__label", attrs: { for: "brand" } },
                [_vm._v("Бренд")]
              ),
              _vm._v(" "),
              _c("my-multiselect", {
                attrs: {
                  id: "brand",
                  options: _vm.brands,
                  selectedOption: _vm.brand,
                  placeholder: "Выберите бренд"
                },
                on: { select: _vm.onBrandSelect }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "inputblock" }, [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "city" } },
              [_c("l10n", { attrs: { value: "City" } })],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.city,
                  expression: "city"
                }
              ],
              staticClass: "inputblock__input input",
              class: { input_invalid: _vm.tryToSendForm && !_vm.city.length },
              attrs: { id: "city", placeholder: "Введите название города" },
              domProps: { value: _vm.city },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.city = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputblock" }, [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "region" } },
              [_c("l10n", { attrs: { value: "Region" } })],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.region,
                  expression: "region"
                }
              ],
              staticClass: "inputblock__input input",
              class: { input_invalid: _vm.tryToSendForm && !_vm.region.length },
              attrs: { id: "region", placeholder: "Введите название региона" },
              domProps: { value: _vm.region },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.region = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputblock" }, [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "openingDate" }
              },
              [_c("l10n", { attrs: { value: "Opening date" } })],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.openingDate,
                  expression: "openingDate"
                }
              ],
              staticClass: "inputblock__input input",
              attrs: {
                id: "openingDate",
                placeholder: _vm.localizeValue("Select date"),
                type: "date"
              },
              domProps: { value: _vm.openingDate },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.openingDate = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-block" }, [
            _c(
              "button",
              {
                staticClass: "button button_primary",
                class: {
                  button_disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                attrs: {
                  type: "button",
                  disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                on: { click: _vm.onCreateShop }
              },
              [_c("l10n", { attrs: { value: "Save" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button_secondary",
                attrs: { type: "button" },
                on: { click: _vm.closeForm }
              },
              [_c("l10n", { attrs: { value: "Cancel" } })],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }