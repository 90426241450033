<template>
  <input class="form-control" type="search" :placeholder="l10n(placeholder)" v-model="controlValue"/>
</template>
<script>
let utils = require('./my-utils');
module.exports = {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String },
    placeholder: { type: String, default: 'Search...' },
  },
  data() {
    return {
      l10n: utils.l10n,
      controlValue: this.value,
    };
  },
  watch: {
    value() {
      this.controlValue = this.value;
    },
    controlValue: _.debounce(function() {
      this.$emit('change', this.controlValue);
    }, 500),
  },
};
</script>
