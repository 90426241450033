<template>
  <tr>
    <td>
      <div
        class="pim-tree-paginator"
        ref="paginator"
        :class="{ focused: entry.focused }"
        :style="{ 'margin-left': `${level * 20 - 4}px` }"
      >
        <a
          href="javascript:void(0)"
          :class="{ disabled: entry.page <= 1 }"
          @click="$set(entry.entry, 'page', entry.page - 1)">
          <feather-icon name="chevron-left" />
        </a>
        <div>
          {{l10n(entry.size)}}
          <template v-if="entry.size > 1">
            {{pluralize(entry.entry.childrenType)}}
          </template>
          <template v-else>
            {{entry.entry.childrenType}}
          </template>
          total,
        </div>
        <div>
          page
          <a
            href="javascript:void(0)"
            @click="promptPage(entry)"
          >{{l10n(entry.page)}}</a>
          of {{l10n(entry.pages)}},
        </div>
        <div>
          <a
            href="javascript:void(0)"
            @click="promptPageSize(entry)"
          >{{l10n(entry.take)}}</a>
          {{pluralize(classify(entry.entry.type))}} per page
        </div>
        <a
          href="javascript:void(0)"
          :class="{ disabled: entry.page >= entry.pages }"
          @click="$set(entry.entry, 'page', entry.page + 1)">
          <feather-icon name="chevron-right" />
        </a>
      </div>
    </td>
  </tr>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  props: {
    entry: { type: Object },
    classify: { type: Function },
    pluralize: { type: Function },
  },
  methods: {
    focus() {
    },
    l10n(x) {
      if (_.isNumber(x)) {
        return Number(x).toLocaleString();
      }
      return utils.l10n(x);
    },
    promptPage() {
      const page = parseInt(window.prompt('Please enter a page number:', this.entry.page));
      if (!isNaN(page)) {
        this.$set(this.entry.entry, 'page', page);
      }
    },
    promptPageSize() {
      const type = this.pluralize(this.classify(this.entry.entry.type));
      const take = parseInt(window.prompt(`Please a enter number of ${type} per page:`, this.entry.take));
      if (!isNaN(take)) {
        this.$set(this.entry.entry, 'take', take);
      }
    },
  },
  computed: {
    level() {
      let level = 0;
      let { entry } = this.entry;
      while (entry) {
        level += 1;
        entry = entry.parent;
      }
      return level;
    },
  },
};
</script>
<style>
.pim-tree-paginator {
  color: var(--dark);
  font-style: italic;
  line-height: 1.4;
  font-size: 0.95em;
  padding-bottom: 2px;
  display: inline-block;
}
.my-dark-theme .pim-tree-paginator {
  color: var(--light);
}
.pim-tree-paginator > div {
  display: inline-block;
}
.pim-tree-paginator svg {
  width: 18px;
  height: 18px;
  transform: translate(0, -1px);
}
.pim-tree-paginator  {
  user-select: none;
}
</style>
