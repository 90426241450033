module.exports = {
  data() {
    return {
      loadedIncludes: {},
      customIncludes: {},
      pendingIncludes: {},
    };
  },
  mounted() {
    utils.bridge.bind('setInclude', this.setCustomInclude);
    utils.bridge.bind('unsetInclude', this.unsetCustomInclude);
    this.loadIncludes();
  },
  beforeDestroy() {
    utils.bridge.unbind('setInclude', this.setCustomInclude);
    utils.bridge.unbind('unsetInclude', this.unsetCustomInclude);
  },
  computed: {
    includes() {
      return [];
    },
  },
  methods: {
    isChildNode(uid) {
      let loop = node => {
        if (node._uid == uid) {
          return true;
        }
        for (child of node.$children) {
          if (child.$vnode.componentOptions.tag != 'gp-page' && loop(child)) {
            return true;
          }
        }
        return false;
      };
      return loop(this);
    },
    resolveUid(uidOrElement) {
      if (_.isElement(uidOrElement)) {
        let uids = new Map();
        let loop = (component) => {
          uids.set(component.$el, component._uid);
          component.$children.forEach(loop);
        };
        loop(this);
        let element = uidOrElement;
        while (element) {
          let uid = uids.get(element);
          if (uid !== undefined) {
            return uid;
          }
          element = element.parentNode;
        }
      } else if (uidOrElement?._uid) {
        return uidOrElement._uid;
      } else {
        return uidOrElement;
      }
    },
    setCustomInclude(uidOrElement, json) {
      let uid = this.resolveUid(uidOrElement);
      if (this.isChildNode(uid)) {
        if (!_.isEqual(this.customIncludes[uid], json)) {
          this.$set(this.customIncludes, uid, json);
        }
      }
    },
    unsetCustomInclude(uidOrElement) {
      let uid = this.resolveUid(uidOrElement);
      if (this.isChildNode(uid)) {
        if (this.customIncludes[uid] !== undefined) {
          this.$delete(this.customIncludes, uid);
        }
      }
    },
    loadInclude(include) {
      let key = JSON.stringify(include);
      let promise = undefined;
      let info = include;
      if (info.fetch) {
        if (_.isFunction(info.fetch)) {
          promise = (async () => {
            return await info.fetch.bind(this)() || {};
          })();
        } else {
          promise = fetch(
            _.isPlainObject(info.fetch) ? info.fetch.url : info.fetch,
            _.isPlainObject(info.fetch) ? _.omit(info.fetch, 'url') : undefined)
            .then(response => {
              if (response.ok) {
                let contentType = response.headers.get('Content-Type');
                if (contentType.match(/application\/json/)) {
                  return response.json();
                }
                if (contentType.match(/text\/x-yaml/)) {
                  return response.text()
                    .then(text => jsyaml.safeLoad(text, { schema: utils.jsyamlSchema }));
                }
                return null;
              }
              if (response.status == 404) {
                return null;
              }
            });
        }
      } else if (info.query) {
        promise = utils.query(info.query);
      }

      if (promise !== undefined) {
        this.$set(this.pendingIncludes, key, promise);
        promise
          .then(data => {
            if (this.pendingIncludes[key] === promise) {
              this.$delete(this.pendingIncludes, key);
              this.$set(this.loadedIncludes, key, { info, data });
            }
          })
          .catch(error => {
            if (this.pendingIncludes[key] === promise) {
              this.$delete(this.pendingIncludes, key);
              console.warn('include', key, 'failed', error);
              this.$set(this.loadedIncludes, key, { info });
            }
          });
      }
    },
    loadIncludes() {
      let knownKeys = new Set();

      for (let include of this.includes) {
        if (_.isPlainObject(include)) {
          let key = JSON.stringify(include);
          knownKeys.add(key);
          if (this.loadedIncludes[key] === undefined &&
                        this.pendingIncludes[key] === undefined) {
            this.loadInclude(include);
          }
        }
      }
      for (let key of _.keys(this.loadedIncludes)) {
        if (!knownKeys.has(key)) {
          this.$delete(this.loadedIncludes, key);
        }
      }

      for (let key of _.keys(this.pendingIncludes)) {
        if (!knownKeys.has(key)) {
          this.$delete(this.pendingIncludes, key);
        }
      }
    },
    mergeIncludes(target) {
      let merge = (a,b) => {
        if (_.isObject(a) && _.isObject(b)) {
          for (let k of Object.keys(b)) {
            let ak = a[k];
            let bk = b[k];
            if (ak === undefined) {
              a[k] = bk;
            } else if (_.isObject(ak) && _.isObject(bk)) {
              if (Object.isFrozen(ak)) {
                ak = a[k] = _.clone(ak);
              }
              merge(ak, bk);
            } else {
              a[k] = bk;
            }
          }
        }
      };
      for (let { info, data } of _.values(this.loadedIncludes)) {
        if (data !== undefined) {
          if (info.transform) {
            data = eval(info.transform)(data);
          }
          if (_.isArray(info.mounts)) {
            for (let mount of info.mounts) {
              let subData = mount.subPath ? _.get(data, mount.subPath) : data;
              if (mount.transform) {
                subData = eval(mount.transform)(subData);
              }
              if (mount.mountPath) {
                let prevData = _.get(target, mount.mountPath);
                if (_.isPlainObject(subData) && _.isPlainObject(prevData)) {
                  merge(prevData, subData);
                } else {
                  _.set(target, mount.mountPath, subData);
                }
              } else if (_.isPlainObject(subData)) {
                merge(target, subData);
              }
            }
          } else if (_.isPlainObject(data)) {
            merge(target, data);
          }
        }
      }
      for (let data of _.values(this.customIncludes)) {
        merge(target, data);
      }
    },
  },
};
