var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pim-ai-cell" }, [
    _vm._v(
      "\n  " +
        _vm._s(_vm.row[_vm.column.i]) +
        "\n  " +
        _vm._s(_vm.column.options) +
        "\n"
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }