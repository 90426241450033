var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gauge-chart" },
    [
      _c("my-errors", {
        attrs: { errors: _vm.errors },
        on: { retry: _vm.requestData }
      }),
      _vm._v(" "),
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _c("my-progress", {
        key: _vm.reportId,
        attrs: {
          progress: _vm.progress,
          processRows: _vm.processRows,
          processTime: _vm.processTime,
          meta: _vm.meta,
          exportToFile: _vm.exportToFile,
          assembly: _vm.assembly
        }
      }),
      _vm._v(" "),
      _c("my-filters", {
        attrs: { chart: null },
        model: {
          value: _vm.filters,
          callback: function($$v) {
            _vm.filters = $$v
          },
          expression: "filters"
        }
      }),
      _vm._v(" "),
      _c("my-download", { attrs: { file: _vm.file } }),
      _vm._v(" "),
      _c("my-tooltip", {
        ref: "tooltip",
        attrs: { meta: _vm.meta, keepOnly: _vm.keepOnly, exclude: _vm.exclude }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gauge-chart-segments", style: { height: _vm.height } },
        _vm._l(_vm.segments, function(d) {
          return _c("div", {
            key: d.key.join("\n"),
            staticClass: "gauge-chart-segment",
            style: _vm.style(d),
            attrs: { title: _vm.title(d) }
          })
        }),
        0
      ),
      _vm._v(" "),
      _vm.renderLegend
        ? _c(
            "ul",
            { staticClass: "gauge-chart-legend" },
            _vm._l(_vm.segments, function(d) {
              return _c(
                "li",
                {
                  key: d.key.join("\n"),
                  staticClass: "gauge-chart-legend-item",
                  on: {
                    mouseenter: function($event) {
                      _vm.hoveredSegment = d
                    },
                    mouseleave: function($event) {
                      _vm.hoveredSegment = null
                    }
                  }
                },
                [
                  _c("span", { style: { "background-color": _vm.color(d) } }),
                  _vm._v("\n      " + _vm._s(d.key.join(", ")) + "\n    ")
                ]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }