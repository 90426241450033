var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "my-dialog",
        { attrs: { title: _vm.getModalTitle }, on: { close: _vm.closeForm } },
        [
          _vm.shopDonor
            ? _c("div", { staticClass: "inputblock" }, [
                _c(
                  "label",
                  {
                    staticClass: "inputblock__label",
                    attrs: { for: "shop-donor" }
                  },
                  [_vm._v("\n      Магазин-донор\n    ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shopDonor,
                      expression: "shopDonor"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: { id: "shop-donor", disabled: "" },
                  domProps: { value: _vm.shopDonor },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.shopDonor = $event.target.value
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.shopAcceptor
            ? _c("div", { staticClass: "inputblock" }, [
                _c(
                  "label",
                  {
                    staticClass: "inputblock__label",
                    attrs: { for: "shop-acceptor" }
                  },
                  [_vm._v("\n      Магазин-акцептор\n    ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shopAcceptor,
                      expression: "shopAcceptor"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: { id: "shop-acceptor", disabled: "" },
                  domProps: { value: _vm.shopAcceptor },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.shopAcceptor = $event.target.value
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock inputblock_select" },
            [
              _c(
                "label",
                { staticClass: "inputblock__label", attrs: { for: "shop" } },
                [_vm._v(_vm._s(_vm.getLabelForMultiselect))]
              ),
              _vm._v(" "),
              _c("my-multiselect", {
                attrs: {
                  id: "shop",
                  options: _vm.shops,
                  loading: _vm.isLoading,
                  searchable: true,
                  maxHeight: 190,
                  placeholder: "Выберите магазин"
                },
                on: { open: _vm.getShops },
                model: {
                  value: _vm.shop,
                  callback: function($$v) {
                    _vm.shop = $$v
                  },
                  expression: "shop"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-block" }, [
            _c(
              "button",
              {
                staticClass: "button button_primary",
                class: {
                  button_disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                attrs: {
                  type: "button",
                  disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                on: { click: _vm.onCreateShop }
              },
              [_c("l10n", { attrs: { value: "Save" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button_secondary",
                attrs: { type: "button" },
                on: { click: _vm.closeForm }
              },
              [_c("l10n", { attrs: { value: "Cancel" } })],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }