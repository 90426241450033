var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "gp-cache" },
    _vm._l(_vm.caches, function(cache) {
      return _c("tr", [
        _c("td", [_c("l10n", { attrs: { value: cache.name + " cache" } })], 1),
        _vm._v(" "),
        _c("td", [
          _c("div", { staticClass: "progress" }, [
            _c(
              "div",
              {
                staticClass: "progress-bar",
                style: {
                  width: (cache.size / cache.maxSize) * 100 + "%",
                  backgroundColor: _vm.d3.interpolateRdYlGn(
                    1 - cache.size / cache.maxSize
                  )
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.formatSize(cache.size)) +
                    "\n          –\n          " +
                    _vm._s(_vm.formatCount(cache.count)) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("td", [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.resetCache(cache.name)
                }
              }
            },
            [_c("l10n", { attrs: { value: "reset" } })],
            1
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }