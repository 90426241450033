var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-tasks" },
    [
      _vm.errorMessage
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _c(
              "a",
              {
                staticClass: "float-right",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.errorMessage = null
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "x" } })],
              1
            ),
            _vm._v(" "),
            _c("small", { staticStyle: { "white-space": "pre-line" } }, [
              _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticStyle: { float: "right" },
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.refreshTasks(true)
            }
          }
        },
        [
          _c("feather-icon", {
            attrs: { name: _vm.loading ? "clock" : "refresh-cw" }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.startingProcess
        ? _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-sm",
              on: {
                click: function($event) {
                  _vm.startingProcess = true
                }
              }
            },
            [_c("l10n", { attrs: { value: "Start process" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.startingProcess
        ? _c(
            "div",
            { staticClass: "gp-process-definitions" },
            [
              !_vm.processDefinition
                ? [
                    _c(
                      "label",
                      [
                        _c("l10n", {
                          attrs: { value: "Click on the process to start." }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.processDefinitions, function(
                        processDefinition
                      ) {
                        return processDefinition.key != "invoice"
                          ? _c("li", { key: processDefinition.id }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.startProcess(processDefinition)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        processDefinition.name ||
                                          processDefinition.key
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v("\n          –\n          "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.previewProcess(
                                        processDefinition
                                      )
                                    }
                                  }
                                },
                                [_c("l10n", { attrs: { value: "preview" } })],
                                1
                              )
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "gp-start-process-actions" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-secondary",
                          on: {
                            click: function($event) {
                              _vm.startingProcess = false
                            }
                          }
                        },
                        [_c("l10n", { attrs: { value: "Cancel" } })],
                        1
                      )
                    ])
                  ]
                : [
                    _c("h2", [_vm._v(_vm._s(_vm.processDefinition.name))]),
                    _vm._v(" "),
                    _c("markdown", {
                      ref: "processHtml",
                      staticClass: "gp-task-form",
                      attrs: {
                        darkTheme: _vm.darkTheme,
                        config: _vm.config,
                        username: _vm.username,
                        data: { users: _vm.users, username: _vm.username },
                        text: _vm.processHtml || "Loading..."
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "gp-start-process-actions" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-secondary",
                          on: {
                            click: function($event) {
                              _vm.processDefinition = null
                            }
                          }
                        },
                        [_c("l10n", { attrs: { value: "Cancel" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.submitProcess(_vm.processDefinition)
                            }
                          }
                        },
                        [_c("l10n", { attrs: { value: "Start process" } })],
                        1
                      )
                    ])
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gp-tasks-selection" },
        [
          _c(
            "gp-check",
            {
              model: {
                value: _vm.showTasksAssignedToMe,
                callback: function($$v) {
                  _vm.showTasksAssignedToMe = $$v
                },
                expression: "showTasksAssignedToMe"
              }
            },
            [
              _c("l10n", { attrs: { value: "Show tasks assigned to me" } }),
              _vm._v(" (" + _vm._s(_vm.tasksAssignedToMe.length) + ")")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              model: {
                value: _vm.showTasksCreatedByMe,
                callback: function($$v) {
                  _vm.showTasksCreatedByMe = $$v
                },
                expression: "showTasksCreatedByMe"
              }
            },
            [
              _c("l10n", { attrs: { value: "Show tasks created by me" } }),
              _vm._v(" (" + _vm._s(_vm.tasksCreatedByMe.length) + ")")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              model: {
                value: _vm.showTasksWithMyParticipation,
                callback: function($$v) {
                  _vm.showTasksWithMyParticipation = $$v
                },
                expression: "showTasksWithMyParticipation"
              }
            },
            [
              _c("l10n", {
                attrs: { value: "Show tasks with my participation" }
              }),
              _vm._v(" (" + _vm._s(_vm.tasksWithMyaticipation.length) + ")")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              model: {
                value: _vm.showUnassignedTasks,
                callback: function($$v) {
                  _vm.showUnassignedTasks = $$v
                },
                expression: "showUnassignedTasks"
              }
            },
            [
              _c("l10n", { attrs: { value: "Show unassigned tasks" } }),
              _vm._v(" (" + _vm._s(_vm.unassignedTasks.length) + ")")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              model: {
                value: _vm.showOverdueTasksOnly,
                callback: function($$v) {
                  _vm.showOverdueTasksOnly = $$v
                },
                expression: "showOverdueTasksOnly"
              }
            },
            [
              _c("l10n", { attrs: { value: "Show overdue tasks only" } }),
              _vm._v(" (" + _vm._s(_vm.overdueTasks.length) + ")")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("portal", { attrs: { to: "gp-tasks-counter" } }, [
        _vm.tasksAssignedToMe.length
          ? _c("span", [
              _vm._v("[" + _vm._s(_vm.tasksAssignedToMe.length) + "]")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "ol",
        _vm._l(_vm.visibleTasks, function(task) {
          return _c(
            "li",
            {
              key: task.id,
              class: { "gp-task": true, active: _vm.activeTaskId == task.id }
            },
            [
              _c(
                "div",
                {
                  staticClass: "gp-task-header",
                  on: {
                    click: function($event) {
                      return _vm.toogleTask(task)
                    },
                    mouseenter: function($event) {
                      return _vm.prefetchTask(task)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "gp-task-assignee" },
                    [
                      task.assignee
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(task.assignee) +
                                "\n          "
                            )
                          ]
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn-xs btn-secondary",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.claimTask(task)
                                }
                              }
                            },
                            [_c("l10n", { attrs: { value: "Claim" } })],
                            1
                          )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "gp-task-subject" }, [
                    _vm._v(_vm._s(task.subject))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "gp-task-name" }, [
                    _vm._v(_vm._s(task.name))
                  ]),
                  _vm._v(" "),
                  task.description != task.name
                    ? _c("span", { staticClass: "gp-task-description" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(task.description) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "gp-task-priority",
                      attrs: { "data-priority": "task.priority" }
                    },
                    [
                      task.priority < 50
                        ? _c("l10n", { attrs: { value: "Low priority" } })
                        : _vm._e(),
                      _vm._v(" "),
                      task.priority > 50
                        ? _c("l10n", { attrs: { value: "High priority" } })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "gp-task-dates" },
                    [
                      task.due
                        ? _c("l10n", {
                            attrs: {
                              value: "Due {time}",
                              time: _vm.formatTimeRelative(task.due)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      task.followUp
                        ? _c("l10n", {
                            attrs: {
                              value: "Follow-up {time}",
                              time: _vm.formatTimeRelative(task.followUp)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      task.created
                        ? _c("l10n", {
                            attrs: {
                              value: "Created {time}",
                              time: _vm.formatTimeRelative(task.created)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.activeTaskId == task.id
                ? [
                    task.creator == _vm.username
                      ? _c("div", { staticClass: "gp-task-actions" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("feather-icon", { attrs: { name: "bell" } }),
                              _vm._v(" "),
                              _c(
                                "label",
                                [_c("l10n", { attrs: { value: "Due" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control form-control-sm",
                                attrs: { type: "datetime-local" },
                                domProps: {
                                  value: task.due
                                    ? _vm.formatTime(
                                        task.due,
                                        "YYYY-MM-DDTHH:mm"
                                      )
                                    : ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.setDueDate(
                                      task,
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setDueDate(task, null)
                                    }
                                  }
                                },
                                [_c("feather-icon", { attrs: { name: "x" } })],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("feather-icon", { attrs: { name: "user" } }),
                              _vm._v(" "),
                              _c(
                                "label",
                                [_c("l10n", { attrs: { value: "Assignee" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  staticClass: "form-control form-control-sm",
                                  domProps: { value: task.assignee },
                                  on: {
                                    change: function($event) {
                                      return _vm.assignTask(
                                        task,
                                        $event.target.value
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: { value: task.assignee }
                                    },
                                    [_vm._v(_vm._s(task.assignee))]
                                  ),
                                  _vm._v(" "),
                                  _vm.username && _vm.username != task.assignee
                                    ? _c(
                                        "option",
                                        { domProps: { value: _vm.username } },
                                        [_vm._v(_vm._s(_vm.username))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("option", { attrs: { disabled: "" } }, [
                                    _vm._v("––––––––––––")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.users, function(user) {
                                    return _c(
                                      "option",
                                      { domProps: { value: user.id } },
                                      [_vm._v(_vm._s(user.id))]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.unassignTask(task)
                                    }
                                  }
                                },
                                [_c("feather-icon", { attrs: { name: "x" } })],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "nav nav-tabs" },
                      _vm._l(_vm.tabs, function(tab, tabId) {
                        return (tabId == "form"
                        ? task.formKey
                        : tabId == "description"
                        ? task.description
                        : true)
                          ? _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  class: {
                                    "nav-link": true,
                                    active: _vm.activeTabId == tabId
                                  },
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.activeTabId = tabId
                                    }
                                  }
                                },
                                [_c("l10n", { attrs: { value: tab } })],
                                1
                              )
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.activeTabId == "description" && task.description
                      ? _c("div", { staticClass: "gp-task-description" }, [
                          _c("p", [_vm._v(_vm._s(task.description))])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeTabId == "form" && task.formKey
                      ? _c(
                          "div",
                          { staticClass: "gp-task-form" },
                          [
                            _c("markdown", {
                              ref: "taskHtml",
                              refInFor: true,
                              attrs: {
                                darkTheme: _vm.darkTheme,
                                config: _vm.config,
                                username: _vm.username,
                                data: {
                                  users: _vm.users,
                                  username: _vm.username,
                                  task: task
                                },
                                text: _vm.getTaskForm(task)
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "gp-task-form-actions" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-primary",
                                  attrs: {
                                    disabled: task.assignee != _vm.username
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.submitTask(task)
                                    }
                                  }
                                },
                                [_c("l10n", { attrs: { value: "Submit" } })],
                                1
                              ),
                              _vm._v(" "),
                              task.assignee != _vm.username
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.claimTask(task)
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", {
                                        attrs: {
                                          value: "Claim the task to submit"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticStyle: { "flex-grow": "1" } }),
                              _vm._v(" "),
                              task.creator == _vm.username
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-danger",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteProcessInstance(task)
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", { attrs: { value: "Delete" } })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeTabId == "history"
                      ? _c("div", { staticClass: "gp-task-history" }, [
                          _c(
                            "table",
                            _vm._l(_vm.taskHistory[task.id], function(
                              operations
                            ) {
                              return _c(
                                "tbody",
                                [
                                  _vm._l(operations, function(operation, i) {
                                    return [
                                      _c("tr", [
                                        i == 0
                                          ? _c(
                                              "td",
                                              {
                                                attrs: {
                                                  rowspan: operations.length * 2
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatTime(
                                                        operation.timestamp,
                                                        "MMM D"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatTime(
                                                operation.timestamp,
                                                "LT"
                                              )
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "2" } }, [
                                          _vm._v(
                                            _vm._s(operation.operationType)
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(operation.userId))
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.variableName(
                                                operation.property
                                              )
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          [
                                            parseFloat(operation.newValue) >
                                            1000000000000
                                              ? [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        new Date(
                                                          parseFloat(
                                                            operation.newValue
                                                          )
                                                        ).toLocaleString()
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(operation.newValue)
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      ])
                                    ]
                                  })
                                ],
                                2
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "gp-task-form-actions" }, [
                            _c("div", { staticStyle: { "flex-grow": "1" } }),
                            _vm._v(" "),
                            task.creator == _vm.username
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteProcessInstance(task)
                                      }
                                    }
                                  },
                                  [_c("l10n", { attrs: { value: "Delete" } })],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.product != "pim"
        ? _c("gp-data", {
            attrs: {
              id: "gp-tasks-scopes",
              stream: "combined",
              groups: ["tasks-scopes", "search"],
              filter0: _vm.scopesFilter0,
              filter1: _vm.scopesFilter1,
              vals: _vm.scopesVals
            },
            model: {
              value: _vm.scopesReport,
              callback: function($$v) {
                _vm.scopesReport = $$v
              },
              expression: "scopesReport"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.bpmn
        ? _c(
            "my-dialog",
            {
              attrs: { xlarge: true, title: "Process definitions diagram" },
              on: {
                close: function($event) {
                  _vm.bpmn = null
                }
              }
            },
            [_c("div", { ref: "bpmn" })]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }