<template>
  <tr>
    <td>
      <div
        class="pim-hierarchies-entry"
        :class="{ focused: entry.focused }"
        :style="{ 'margin-left': level * 20 + 'px', 'margin-right': '10px' }"
      >
        <!--a class="pim-hierarchies-open" v-if="entry.loading">
                    <feather-icon name="clock"/>
                </a-->
        <a
          href="javascript:void(0)"
          class="pim-hierarchies-open"
          v-if="entry.children != null && entry.children.length"
          tabindex="-1"
          @click="$emit(entry.opened ? 'close' : 'open', entry)">
          <feather-icon :name="entry.opened ? 'book-open' : 'book'" />
        </a>
        <div class="pim-hierarchies-entry-head">
          <gp-check v-if="multiselect" v-model="entry.selected" :inline="true" />
          <span
            ref="name"
            class="pim-hierarchies-entry-name"
            tabindex="0"
            @focus="$emit('focus', entry)"
            @blur="$emit('blur', entry)">
            <template v-if="formattedName">
              <span
                v-for="part in formattedName"
                :class="{ matched: part.matched }"
              >{{part.text}}</span>
            </template>
            <template v-else>
              {{entry.name || "[name]"}}
            </template>
          </span>
          <span class="pim-hierarchies-entry-desc" v-if="entry.desc">
            –
            <template v-if="formattedDesc">
              <span
                v-for="part in formattedDesc"
                :class="{ matched: part.matched }"
              >{{part.text}}</span>
            </template>
            <template v-else>
              {{entry.desc}}
            </template>
          </span>
          <span class="pim-hierarchies-entry-error" v-if="entry.error">
            – {{entry.error}}
          </span>
        </div>
      </div>
    </td>
    <!--td v-for="{key,val} of entry.attrs" :class="`pim-hierarchies-entry-attr-${key}`">
            <feather-icon name="chevron-right" v-if="entry.focused"/>
            <span>{{formatColumn(key, val)}}</span>
        </td-->
  </tr>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  props: {
    entry: { type: Object },
    multiselect: { type: Boolean },
    formatColumn: { type: Function },
  },
  methods: {
    focus() {
      this.$refs.name.focus();
    },
  },
  computed: {
    level() {
      let level = 0;
      let { entry } = this;
      while (entry) {
        level += 1;
        entry = entry.parent;
      }
      return level;
    },
    formattedName() {
      const { search } = this.entry;
      if (search && search.matches) {
        return utils.formatSearchItem(this.entry.name, search.matches.filter((match) => match.key == 'name'));
      }
    },
    formattedDesc() {
      const { search } = this.entry;
      if (search && search.matches) {
        return utils.formatSearchItem(this.entry.name, search.matches.filter((match) => match.key == 'desc'));
      }
    },
  },
};
</script>
