<template>
  <div class="gp-column-stats">
    <a
      href="javascript:void(0)"
      @click="expanded = !expanded">
      <feather-icon :name="expanded ? 'chevron-down' : 'chevron-right'" />
      <l10n value="Statistics" />
    </a>
    <template v-if="expanded">
      <table>
        <tbody>
          <tr>
            <th><l10n value="Min:" /></th>
            <td>{{formatValue(minValue)}}</td>
          </tr>
          <tr v-if="vals.length >= 10">
            <th><l10n value="10% quantile:" /></th>
            <td>{{formatValue(q10Value)}}</td>
          </tr>
          <tr v-if="vals.length >= 10">
            <th><l10n value="25% quantile:" /></th>
            <td>{{formatValue(q25Value)}}</td>
          </tr>
          <tr>
            <th><l10n value="Avg:" /></th>
            <td>{{formatValue(avgValue)}}</td>
          </tr>
          <tr>
            <th><l10n value="Median:" /></th>
            <td>{{formatValue(medValue)}}</td>
          </tr>
          <tr v-if="modValues.length > 0">
            <th><l10n value="Modes:" /></th>
            <td>{{modValues.map(formatValue).join(" ")}}</td>
          </tr>
          <tr v-if="vals.length >= 10">
            <th><l10n value="75% quantile:" /></th>
            <td>{{formatValue(q75Value)}}</td>
          </tr>
          <tr v-if="vals.length >= 10">
            <th><l10n value="90% quantile:" /></th>
            <td>{{formatValue(q90Value)}}</td>
          </tr>
          <tr>
            <th><l10n value="Max:" /></th>
            <td>{{formatValue(maxValue)}}</td>
          </tr>
          <tr>
            <th><l10n value="Sum:" /></th>
            <td>{{formatValue(sumValue)}}</td>
          </tr>
        </tbody>
      </table>
      <gp-check v-model="excludeZeros">
        <l10n value="Exclude zero values" />
      </gp-check>
    </template>
  </div>
</template>
<script>
module.exports = {
  props: {
    rows: { type: Array },
    column: { type: Object },
    format: { type: String },
    formats: { type: Object },
  },
  data() {
    return {
      expanded: false,
      excludeZeros: true,
    };
  },
  computed: {
    vals() {
      const { i } = this.column;
      return _(this.rows)
        .map((row) => row[i])
        .filter((val) => !this.excludeZeros || val != 0)
        .sortBy()
        .value();
    },
    q10Value() {
      return this.vals[Math.round((this.vals.length - 1) * 0.1)];
    },
    q25Value() {
      return this.vals[Math.round((this.vals.length - 1) * 0.25)];
    },
    q75Value() {
      return this.vals[Math.round((this.vals.length - 1) * 0.75)];
    },
    q90Value() {
      return this.vals[Math.round((this.vals.length - 1) * 0.9)];
    },
    avgValue() {
      return _.mean(this.vals);
    },
    minValue() {
      return this.vals[0];
    },
    maxValue() {
      return this.vals[this.vals.length - 1];
    },
    sumValue() {
      return _.sum(this.vals);
    },
    medValue() {
      // 2 -> [0,1]
      // 3 -> 0,[1],2
      // 4 -> 0,[1,2],3
      // 5 -> 0,1,[2],3,4
      return (
        this.vals[Math.floor((this.vals.length - 1) / 2)]
                + this.vals[Math.ceil((this.vals.length - 1) / 2)]) / 2;
    },
    modValues() {
      const freqs = _.countBy(this.vals);
      const maxFreq = _(freqs).values().max();
      const modes = _(freqs)
        .toPairs()
        .filter(([val, freq]) => freq == maxFreq)
        .map(([val, freq]) => val)
        .value();
      return modes.length < 10 ? modes : [];
    },
  },
  methods: {
    formatValue(x) {
      try {
        const row = [];
        row[this.column.i] = x;
        return eval(this.formats[this.format])(x, row, this.column);
      } catch (ex) {
        return new Number(x).toLocaleString();
      }
    },
  },
};
</script>
<style>
.gp-column-stats table {
    font-size: 0.9em;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 8px;
}
.gp-column-stats table th {
    width: 50%;
    padding-left: 6px;
    padding-right: 4px;
}
.gp-column-stats table td {
    width: 50%;
    padding-left: 4px;
    padding-right: 6px;
    text-align: right;
}
.gp-column-stats .gp-check {
    text-align: center;
    font-size: 1.1em;
}
.gp-column-stats table tr:hover {
    background-color: rgb(233 242 250);
}
</style>
