var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "my-dialog",
        { attrs: { title: "Add new type" }, on: { close: _vm.closeForm } },
        [
          _c("div", { staticClass: "inputblock" }, [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "typeOfProduction" }
              },
              [_vm._v("\n      Type of production\n    ")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.typeOfProduction,
                  expression: "typeOfProduction"
                }
              ],
              staticClass: "inputblock__input input",
              class: {
                input_invalid: _vm.tryToSendForm && !_vm.typeOfProduction
              },
              attrs: { id: "typeOfProduction" },
              domProps: { value: _vm.typeOfProduction },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.typeOfProduction = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-block" }, [
            _c(
              "button",
              {
                staticClass: "button button_primary",
                class: {
                  button_disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                attrs: {
                  type: "button",
                  disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                on: { click: _vm.onCreateNewType }
              },
              [_c("l10n", { attrs: { value: "Save" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button_secondary",
                attrs: { type: "button" },
                on: { click: _vm.closeForm }
              },
              [_c("l10n", { attrs: { value: "Cancel" } })],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }