<template>
  <div class="pim-items">
    <div class="pim-items-body">
      <div class="pim-items-main">
        <div class="pim-items-main-head">
          <chosen
            stream="categories"
            :dims="['brand.id']"
            :vals="['brand.name']"
            placeholder="Storefront"
            :multiple="true"
            @change="brands = $event"
            :initialSelection="brands"
            :format="(x, row) => row[1]"
          />
          <div>
            <chosen
              ref="categories"
              stream="categories"
              :dims="['id']"
              :vals="['name']"
              placeholder="Category"
              :multiple="true"
              @change="categories = $event"
              :initialSelection="brands"
              :format="(x, row) => row[1]"
            />
            <button class="btn btn-secondary btn-sm" @click="browseCategories = true">Browse</button>
            <fancytree
              v-if="browseCategories"
              rootKey=""
              placeholder="Category"
              stream="categories"
              catalog="categories"
              keyColumn="id"
              groupColumn="parent_id"
              labelColumn="name"
              isGroupColumn="has_children"
              :showInput="false"
              @submit="browseCategories = false; fancytreeSubmit($event)"
              @change="browseCategories = false; fancytreeSubmit($event)"
              @cancel="browseCategories = false"
              @clickoutside="browseCategories = false"
            />
          </div>
        </div>
        <gp-table
          ref="table"
          product="pim"
          stream="items"
          :groups="[
            'pim-items',
            'pim-items-search',
          ]"
          :attributes="attributes"
          :metrics="metrics"
          :formulas="formulas"
          :formats="formats"
          :timeframes="timeframes"
          :username="username"
          :filter2="filter2"
          @row-selected="rowSelected"
          @rows-selected="rowsSelected"
        />
      </div>
      <div class="pim-items-side">
        <div class="pim-items-side-tabs">
          <ul>
            <li :class="{ active: mode == 'settings' }">
              <a href="javascript:void(0)" @click="mode = 'settings'">
                <span>Settings</span>
              </a>
            </li>
            <li :class="{ active: mode == 'attributes' }">
              <a href="javascript:void(0)" @click="mode = 'attributes'">
                <span>Attributes</span>
              </a>
            </li>
            <li :class="{ active: mode == 'assignment' }">
              <a href="javascript:void(0)" @click="mode = 'assignment'">
                <span>Assignment</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="pim-items-side-main">
          <portal-target
            name="gp-settings"
            v-show="mode == 'settings'"
          />
          <template v-if="mode == 'attributes'">
            <h2>Attributes</h2>
            <p v-if="!entries.length">
              Please select lines on the left side to show attributes.
            </p>
            <pim-attributes
              stream="items"
              idColumn="key"
              :search="search"
              :fields="fields"
              :focus="focus"
              :entries="entries"
              :username="username"
            />
          </template>
          <template v-if="mode == 'assignment'">
            <h2>Assignment</h2>
            <p v-if="!entries.length">
              Please select lines on the left side to show attributes.
            </p>
            <plain-table
              :stream="assignmentConfig.stream"
              :groups="assignmentConfig.groups"
              :filter0="assignmentConfig.filter0"
              :filter1="assignmentConfig.filter1"
              :filter2="assignmentConfig.filter2"
              :dims="assignmentConfig.dims"
              :vals="assignmentConfig.vals"
              :cols="assignmentConfig.cols"
            />
          </template>
        </div>
      </div>
    </div>
    <gp-data
      stream="fields"
      filter2="api_group_name in ['Item Attribute', 'Item Digital Asset Flex Fields', 'Variation Detail', 'Variation Flex Fields']"
      :dims="['api_name']"
      :vals="['field_name', 'field_type']"
      v-model="fieldsReport"
    />
  </div>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  props: {
    attributes: { type: Array },
    metrics: { type: Array },
    formulas: { type: Object },
    formats: { type: Object },
    timeframes: { type: Object },
    username: { type: String },
    search: {
      type: Object,
      default: () => ({
        threshold: 0,
        minMatchCharLength: 2,
        isCaseSensitive: false,
        includeMatches: true,
        ignoreLocation: true,
        useExtendedSearch: true,
        findAllMatches: true,
        shouldSort: false,
        includeScore: true,
        keys: ['name'],
      }),
    },
  },
  data() {
    return {
      mode: localStorage['pim-items-mode'] || 'settings',
      entry: null,
      entries: [],
      fieldsReport: null,
      brands: JSON.parse(localStorage['pim-items-brands'] || '[]'),
      categories: JSON.parse(localStorage['pim-items-categories'] || '[]'),
      browseCategories: false,
    };
  },
  mounted() {
    window.pim = this;
  },
  watch: {
    mode() {
      localStorage['pim-items-mode'] = this.mode;
    },
    brands() {
      localStorage['pim-items-brands'] = JSON.stringify(this.brands);
    },
    categories() {
      localStorage['pim-items-categories'] = JSON.stringify(this.categories);
    },
  },
  computed: {
    fields() {
      return _(this.fieldsReport?.rows)
        .map(([key, name, type]) => ({ key, name, type }))
        .value();
    },
    types() {
      return _(this.fields).map((field) => [field.key, field.type]).fromPairs().value();
    },
    filter2() {
      filters = [];
      if (this.brands.length) {
        const config = {
          source: 'categories_items',
          dims: 'item_master_id',
          filter2: `brand_id in ${utils.quote(_.map(this.brands, 0))}`,
        };
        filters.push(`id in report(${utils.quote(JSON.stringify(config))}, 'item_master_id')`);
      }
      if (this.categories.length) {
        const config = {
          source: 'categories_items',
          dims: 'item_master_id',
          filter2: `item_hierarchy_id in ${utils.quote(_.map(this.categories, 0))}`,
        };
        filters.push(`id in report(${utils.quote(JSON.stringify(config))}, 'item_master_id')`);
      }
      return filters.join(' && ');
    },
    assignmentConfig() {
      const stream = 'items';
      const groups = ['pim-items-assignment'];
      const filter0 = undefined;
      const filter1 = undefined;
      const filter2 = `
                master.item_number in ${utils.quote(this.entries.map((entry) => entry.id))} ||
                master.variation_number in ${utils.quote(this.entries.map((entry) => entry.id))}
                `;
      const dims = [{
        calc: 'key',
        name: 'Key',
      }];
      const vals = [{
        calc: 'type',
        name: 'Type',
      }, {
        calc: 'image',
        name: 'Image',
        type: 'html',
        format: (x) => (x ? `<img src="${x}">` : ''),
      }];
      const cols = undefined;
      return {
        stream,
        groups,
        filter0,
        filter1,
        filter2,
        dims,
        vals,
        cols,
      };
    },
  },
  methods: {
    fancytreeSubmit(node) {
      const select = $(this.$refs.categories.$refs.select);
      const vals = select.val();
      vals.push(node.key);
      select.val(vals);
      select.trigger('chosen:updated');
    },
    fancytreeChange(node) {

    },
    makeEntries(rows) {
      const { table } = this.$refs.table.$refs;
      const { meta } = table;
      const idColumnNames = ['key', 'vendor', 'item'];
      const idColumns = _(table.columns)
        .filter((column) => idColumnNames.indexOf(column.calc) != -1)
        .sortBy((column) => idColumnNames.indexOf(column.calc))
        .value();

      return _(rows)
        .map((row) => {
          const rows = table.report?.rows || [];
          const i = parseInt(row) + table.offset;

          return rows[i];
        })
        .filter()
        .map((row) => {
          const id = _(idColumns)
            .map((column) => row[column.i])
            .filter()
            .head();
          return { id };
        })
        .value();
    },
    rowSelected(row) {
      this.entry = this.makeEntries([row])[0] || null;
    },
    rowsSelected(rows) {
      this.entries = this.makeEntries(_.keys(rows));
    },
  },
};
</script>
