<template>
  <div>
    <gp-stored
      ref="timeframes"
      family="timeframes"
      :config="customTimeframeConfig"
      :compact="false"
      :username="username"
      @change="onCustomTimeframeConfigChange"
    />

    <div>
      <p>
        <l10n value="Timeframes group:" />
        <gp-select
          ref="groupSelect"
          v-model="selectedGroup"
          :options="groups"
          :placeholder="$options.utils.l10n('Group name')"
          @change="onSelectChange"
          @select-input="onSelectInput"
        />
      </p>

      <p>
        <l10n value="Start timeframe:" />
        <inline-select
          v-model="customTimeframeConfig.startType"
          :options="startTypes"
          @input="updateCalcString"
        />
        <inline-input
          v-if="customTimeframeConfig.startType === 'fixDate'"
          v-model="customTimeframeConfig.startValueDate"
          type="date"
          @input="updateCalcString"
        />
        <template v-else>
          <inline-input
            v-model="customTimeframeConfig.startValue"
            type="number"
            :noSpin="true"
            @input="updateCalcString"
          />
          <inline-select
            v-model="customTimeframeConfig.startInterval"
            :options="intervals"
            @input="updateCalcString"
          />
          <inline-select
            v-model="customTimeframeConfig.startDirection"
            class="startDirection"
            :options="directions"
            @input="updateCalcString"
          />
        </template>
        <span>.</span>
        <l10n value="After" />
        <inline-select
          v-model="customTimeframeConfig.startTransform"
          :options="transforms"
          @input="updateCalcString"
        />
      </p>

      <p>
        <l10n value="End timeframe:" />
        <inline-select
          v-model="customTimeframeConfig.endType"
          :options="endTypes"
          @input="updateCalcString"
        />
        <inline-input
          v-if="customTimeframeConfig.endType === 'fixDate'"
          v-model="customTimeframeConfig.endValueDate"
          type="date"
          @input="updateCalcString"
        />
        <template v-else>
          <inline-input
            v-model="customTimeframeConfig.endValue"
            type="number"
            :noSpin="true"
            @input="updateCalcString"
          />
          <inline-select
            v-model="customTimeframeConfig.endInterval"
            :options="intervals"
            @input="updateCalcString"
          />
          <inline-select
            v-if="customTimeframeConfig.endType === 'referenceDate'"
            v-model="customTimeframeConfig.endDirection"
            class="endDirection"
            :options="directions"
            @input="updateCalcString"
          />
        </template>
      </p>

      <p class="timeframe-function">
        <l10n value="Timeframe function" />
        <span>:</span>
        <br />
        <textarea>{{ timeframeFunction }}</textarea>
      </p>
    </div>
  </div>
</template>

<script>
const utils = require('../my-utils');

const defaultTimeframeConfig = () => ({
  startType: 'referenceDate',
  startValueDate: utils.formatDate(new Date()),
  startValue: '0',
  startInterval: 'days',
  startDirection: 'future',
  startTransform: 'stay',
  endType: 'referenceDate',
  endValueDate: utils.formatDate(new Date()),
  endValue: '0',
  endInterval: 'days',
  endDirection: 'future',
  group: '',
  isCustom: true,
});

module.exports = {
  utils,

  props: {
    username: {
      type: String,
      default: null,
    },

    timeframes: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const startTypes = {
      fixDate: 'specific date',
      referenceDate: 'from the reference date',
    };
    const intervals = {
      days: 'days',
      weeks: 'weeks',
      months: 'months',
      years: 'years',
    };
    const directions = {
      past: 'ago',
      future: 'ahead',
    };
    const transforms = {
      stay: 'keep this date',
      startWeekend: 'find the beginning of the weekend',
      startWeek: 'find the beginning of the week',
      startMonth: 'find the beginning of the month',
      startSeason: 'find the beginning of the season',
      startQuarter: 'find the beginning of the quarter',
      startYear: 'find the beginning of the year',
    };
    const endTypes = {
      fixDate: 'specific date',
      referenceDate: 'from the reference date',
      startDate: 'from the start of the timeframe',
    };
    const customTimeframeConfig = defaultTimeframeConfig();

    return {
      selectedGroup: {},
      customTimeframeConfig,
      startTypes,
      intervals,
      directions,
      transforms,
      endTypes,
    };
  },

  computed: {
    startFunc() {
      switch (this.customTimeframeConfig.startInterval) {
        case 'months':
          return 'Month';
        case 'years':
          return 'FullYear';
        default:
          return 'Date';
      }
    },

    startDateFirstStep() {
      const startDirection = this.customTimeframeConfig.startDirection === 'past' ? '-' : '+';
      const startInterval = this.customTimeframeConfig.startInterval === 'weeks' ? '7*' : '';

      switch (this.customTimeframeConfig.startType) {
        case 'fixDate':
          return `
            let start = utils.parseDate('${this.customTimeframeConfig.startValueDate}');
          `;
        default:
          return `
            let start = new Date(today);
            start.set${this.startFunc}(start.get${this.startFunc}() ${startDirection} ${startInterval}${this.customTimeframeConfig.startValue});
          `;
      }
    },

    startDateSecondStep() {
      switch (this.customTimeframeConfig.startTransform) {
        case 'startWeekend':
          return `
            start.setDate(start.getDate() - (start.getDay() || 7) + 6);
          `;
        case 'startWeek':
          return `
            start.setDate(start.getDate() - (start.getDay() || 7) + 1);
          `;
        case 'startMonth':
          return `
            start.setDate(1);
          `;
        case 'startSeason':
          return `
            start.setDate(1);
            let startMonth = start.getMonth();
            let startYear = start.getFullYear();
            if (startMonth < 2 || startMonth === 11)
              start = new Date(startYear - 1, 11, 1);
            else if (startMonth < 5)
              start = new Date(startYear, 2, 1);
            else if (startMonth < 8)
              start = new Date(startYear, 5, 1);
            else
              start = new Date(startYear, 8, 1);
          `;
        case 'startQuarter':
          return `
            start.setDate(1);
            let startMonth = start.getMonth();
            start.setMonth(startMonth < 3 ? 0 : (startMonth < 6 ? 3 : (startMonth < 9 ? 6 : 9)));
          `;
        case 'startYear':
          return `
            start.setDate(1);
            start.setMonth(0);
          `;
        default:
          return '';
      }
    },

    endFunc() {
      switch (this.customTimeframeConfig.endInterval) {
        case 'months':
          return 'Month';
        case 'years':
          return 'FullYear';
        default:
          return 'Date';
      }
    },

    endDate() {
      const endDirection = this.customTimeframeConfig.endDirection === 'past' ? '-' : '+';
      const endInterval = this.customTimeframeConfig.endInterval === 'weeks' ? '7*' : '';

      switch (this.customTimeframeConfig.endType) {
        case 'fixDate':
          return `
            let end = utils.parseDate('${this.customTimeframeConfig.endValueDate}');
          `;
        case 'referenceDate':
          return `
            let end = new Date(today);
            end.set${this.endFunc}(end.get${this.endFunc}() ${endDirection} ${endInterval}${this.customTimeframeConfig.endValue});
          `;
        default:
          return `
            let end = new Date(start);
            end.set${this.endFunc}(end.get${this.endFunc}() + ${endInterval}${this.customTimeframeConfig.endValue});
            end.setDate(end.getDate() - 1);
          `;
      }
    },

    timeframeFunction() {
      const fnText = `
        (referenceDate) => {
          let today = new Date(referenceDate);
          today.setDate(today.getDate() + 1);
          ${this.startDateFirstStep}
          ${this.startDateSecondStep}
          ${this.endDate}
          return [start, end];
        };
      `;

      return fnText
        .split('\n')
        .map((s) => s.trim())
        .filter((s) => s)
        .map((s, i, a) => ((i === 0 || i === a.length - 1) ? s : `    ${s}`))
        .join('\n');
    },

    customTimeframes() {
      return _.filter(this.timeframes, ({ isCustom }) => isCustom);
    },

    groups() {
      const groups = _(this.customTimeframes)
        .map((i) => i.group)
        .filter((i) => i)
        .sortedUniq()
        .map((name) => ({ name }))
        .value();

      return [
        {
          id: '__new',
          name: utils.l10n('Create new group'),
        },
        ...groups,
      ];
    },

    customTimeframeConfigOrDefault() {
      return this.customTimeframeConfig || defaultTimeframeConfig();
    },
  },

  async mounted() {
    this.updateCalcString();
  },

  methods: {
    updateCalcString() {
      if (this.timeframeFunction.search('undefined') !== -1) {
        this.customTimeframeConfig.calc = '';
        return;
      }

      this.customTimeframeConfig.calc = this.timeframeFunction;
    },

    onSelectInput($event) {
      const group = $event.currentTarget?.value || '';
      this.$set(this.customTimeframeConfig, 'group', group);
    },

    createNewTimeframe() {
      return {
        id: utils.randomId(),
        name: '',
        user: this.username,
        isCustom: true,
      };
    },

    onSelectChange(selectedGroup = {}) {
      if (selectedGroup?.id == '__new') {
        this.selectedGroup = this.createNewTimeframe();
        this.$set(this.customTimeframeConfig, 'group', '');
      } else {
        this.$set(this.customTimeframeConfig, 'group', selectedGroup.name);
      }
    },

    onCustomTimeframeConfigChange(newCustomTimeframeConfig) {
      const defaultConfig = defaultTimeframeConfig();
      Object.keys(defaultConfig).forEach((field) => {
        if (!(field in newCustomTimeframeConfig)) {
          newCustomTimeframeConfig[field] = defaultConfig[field];
        }
      });
      this.customTimeframeConfig = newCustomTimeframeConfig;
      if (!newCustomTimeframeConfig.name) {
        this.selectedGroup = this.createNewTimeframe();

        return;
      }

      if (newCustomTimeframeConfig.group) {
        this.selectedGroup = { name: newCustomTimeframeConfig.group };
      }

      this.updateCalcString();
    },
  },
};
</script>

<style scoped>
textarea {
  resize:  both;
  min-height: 200px;
  min-width: 400px;
}
.timeframe-function {
  display: none;
}
</style>
