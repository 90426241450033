var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kpis3", class: { loading: _vm.reportId } },
    [
      _vm.editing
        ? [
            _c(
              "gp-portal",
              { attrs: { to: "modal" } },
              [
                _c(
                  "my-popup",
                  {
                    attrs: { placement: "bottom", anchor: _vm.getReference },
                    on: { escape: _vm.stopEditing }
                  },
                  [
                    _c("div", { staticClass: "kpi3-form" }, [
                      _c("table", [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "th",
                              [_c("l10n", { attrs: { value: "KPI name" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editingEntryName,
                                    expression: "editingEntryName"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { autofocus: "" },
                                domProps: { value: _vm.editingEntryName },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.editingEntryName = $event.target.value
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "th",
                              [_c("l10n", { attrs: { value: "Metric 1" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-select", {
                                  attrs: {
                                    options: _vm.metricOptions,
                                    recentOptionsKey: "recentMetrics"
                                  },
                                  model: {
                                    value: _vm.editingMetric1,
                                    callback: function($$v) {
                                      _vm.editingMetric1 = $$v
                                    },
                                    expression: "editingMetric1"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "th",
                              [_c("l10n", { attrs: { value: "Timeframe 1" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-select", {
                                  attrs: {
                                    options: _vm.timeframeOptions,
                                    recentOptionsKey: "recentTimeframes"
                                  },
                                  model: {
                                    value: _vm.editingTimeframe1,
                                    callback: function($$v) {
                                      _vm.editingTimeframe1 = $$v
                                    },
                                    expression: "editingTimeframe1"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "th",
                              [_c("l10n", { attrs: { value: "Metric 2" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-select", {
                                  attrs: {
                                    options: _vm.metricOptions,
                                    recentOptionsKey: "recentMetrics"
                                  },
                                  model: {
                                    value: _vm.editingMetric2,
                                    callback: function($$v) {
                                      _vm.editingMetric2 = $$v
                                    },
                                    expression: "editingMetric2"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "th",
                              [_c("l10n", { attrs: { value: "Timeframe 2" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-select", {
                                  attrs: {
                                    options: _vm.timeframeOptions,
                                    recentOptionsKey: "recentTimeframes"
                                  },
                                  model: {
                                    value: _vm.editingTimeframe2,
                                    callback: function($$v) {
                                      _vm.editingTimeframe2 = $$v
                                    },
                                    expression: "editingTimeframe2"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "th",
                              [_c("l10n", { attrs: { value: "Metric 3" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-select", {
                                  attrs: {
                                    options: _vm.metricOptions,
                                    recentOptionsKey: "recentMetrics"
                                  },
                                  model: {
                                    value: _vm.editingMetric3,
                                    callback: function($$v) {
                                      _vm.editingMetric3 = $$v
                                    },
                                    expression: "editingMetric3"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "th",
                              [_c("l10n", { attrs: { value: "Timeframe 3" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-select", {
                                  attrs: {
                                    options: _vm.timeframeOptions,
                                    recentOptionsKey: "recentTimeframes"
                                  },
                                  model: {
                                    value: _vm.editingTimeframe3,
                                    callback: function($$v) {
                                      _vm.editingTimeframe3 = $$v
                                    },
                                    expression: "editingTimeframe3"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "kpi3-form-actions" }, [
                        _vm.editingEntry
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                on: {
                                  click: function($event) {
                                    _vm.removeEntry()
                                    _vm.stopEditing()
                                  }
                                }
                              },
                              [_c("l10n", { attrs: { value: "Delete" } })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span"),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              disabled:
                                !_vm.editingMetric1 ||
                                !_vm.editingTimeframe1 ||
                                !_vm.editingEntryName
                            },
                            on: { click: _vm.submitEditing }
                          },
                          [
                            _vm.editingEntry
                              ? _c("l10n", { attrs: { value: "Submit" } })
                              : _c("l10n", { attrs: { value: "Create" } })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: { click: _vm.stopEditing }
                          },
                          [_c("l10n", { attrs: { value: "Cancel" } })],
                          1
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "kpi3" }, [
        _c("label", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: { primary: _vm.primaryMetric == 0 },
            staticStyle: { "text-align": "right" }
          },
          [
            _c(
              "a",
              {
                attrs: { href: _vm.primaryMetric == 0 ? "" : "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.primaryMetric = 0
                  }
                }
              },
              [_c("l10n", { attrs: { value: _vm.metric1 } })],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: { primary: _vm.primaryMetric == 1 },
            staticStyle: { "text-align": "right" }
          },
          [
            _c(
              "a",
              {
                attrs: { href: _vm.primaryMetric == 1 ? "" : "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.primaryMetric = 1
                  }
                }
              },
              [_c("l10n", { attrs: { value: _vm.metric2 } })],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: { primary: _vm.primaryMetric == 2 },
            staticStyle: { "text-align": "right" }
          },
          [
            _c(
              "a",
              {
                attrs: { href: _vm.primaryMetric == 2 ? "" : "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.primaryMetric = 2
                  }
                }
              },
              [_c("l10n", { attrs: { value: _vm.metric3 } })],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.entries, function(entry, i) {
        return _c(
          "div",
          { staticClass: "kpi3" },
          [
            _c("label", [
              _vm._v("\n      " + _vm._s(entry.name) + "\n      "),
              _c(
                "a",
                {
                  staticClass: "kpi3-edit",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.startEditing(entry)
                    }
                  }
                },
                [_c("feather-icon", { attrs: { name: "edit-3" } })],
                1
              )
            ]),
            _vm._v(" "),
            _vm._l(entry.metrics, function(metric, j) {
              return _vm.values.has(_vm.metricKey(metric))
                ? [
                    _c("div", { class: { primary: j == _vm.primaryMetric } }, [
                      _c(
                        "span",
                        {
                          attrs: {
                            set1: (_vm.val0 = entry.metrics[_vm.primaryMetric]
                              ? _vm.values.get(
                                  _vm.metricKey(
                                    entry.metrics[_vm.primaryMetric]
                                  )
                                ).value
                              : null),
                            set2: (_vm.valn = _vm.values.get(
                              _vm.metricKey(metric)
                            ).value),
                            set3: (_vm.column = _vm.values.get(
                              _vm.metricKey(metric)
                            ).column),
                            set4: (_vm.format = _vm.values.get(
                              _vm.metricKey(metric)
                            ).column.format)
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.format(_vm.valn, [], _vm.column)) +
                              "\n          "
                          ),
                          j != _vm.primaryMetric &&
                          _vm.val0 != null &&
                          _vm.val0 != 0 &&
                          (_vm.isPositive(_vm.valn, _vm.val0) ||
                            _vm.isNegative(_vm.valn, _vm.val0))
                            ? [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      positive: _vm.isPositive(
                                        _vm.valn,
                                        _vm.val0
                                      ),
                                      negative: _vm.isNegative(
                                        _vm.valn,
                                        _vm.val0
                                      )
                                    }
                                  },
                                  [
                                    _vm.val0 != 0
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.formatPercent(
                                                  _vm.valn,
                                                  _vm.val0
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.valn > _vm.val0 ? "+" : ""
                                              ) +
                                              _vm._s(
                                                _vm.format(
                                                  _vm.valn - _vm.val0,
                                                  [],
                                                  _vm.column
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ],
                                    _vm._v(" "),
                                    _vm.isPositive(_vm.valn, _vm.val0)
                                      ? _c("feather-icon", {
                                          attrs: { name: "trending-up" }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isNegative(_vm.valn, _vm.val0)
                                      ? _c("feather-icon", {
                                          attrs: { name: "trending-down" }
                                        })
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ]
                : _vm._e()
            })
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "kpi3-add" }, [
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.startEditing()
              }
            }
          },
          [_c("feather-icon", { attrs: { name: "plus" } })],
          1
        )
      ]),
      _vm._v(" "),
      _vm.referenceDate
        ? _c("gp-data", {
            ref: "data",
            attrs: {
              id: "gp-kpis3",
              stream: _vm.stream,
              source: _vm.source,
              groups: _vm.groups,
              vars: _vm.vars,
              vals: _vm.vals,
              instant: false
            },
            on: {
              reportId: function($event) {
                _vm.reportId = $event
              }
            },
            model: {
              value: _vm.report,
              callback: function($$v) {
                _vm.report = $$v
              },
              expression: "report"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }