<template>
  <small class="text-muted">
    <markdown
      :text="text"
      :html="false"
      :breaks="false"
      :emoji="false"
      :xhtml-out="false"
      :task-lists="false"
      quotes="&quot;&quot;''"
    />
  </small>
</template>
<script>
module.exports = {
  props: ['text'],
};
</script>
