var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-hierarchies" },
    [
      _c(
        "div",
        { staticClass: "pim-hierarchies-body" },
        [
          _c(
            "div",
            {
              ref: "main",
              staticClass: "pim-hierarchies-main",
              attrs: { tabindex: "0" }
            },
            [
              _c("gp-news", {
                attrs: { id: "news", darkTheme: _vm.darkTheme }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "pim-hierarchies-move" }, [
                _c("div", [
                  _c("span"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { disabled: !_vm.canMoveUp },
                      attrs: {
                        tabindex: "-1",
                        href: "javascript:void(0)",
                        title: "Shift+ArrowUp"
                      },
                      on: { click: _vm.moveUp }
                    },
                    [_c("feather-icon", { attrs: { name: "arrow-up" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("span")
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "a",
                    {
                      class: { disabled: !_vm.canMoveLeft },
                      attrs: {
                        tabindex: "-1",
                        href: "javascript:void(0)",
                        title: "Shift+ArrowLeft"
                      },
                      on: { click: _vm.moveLeft }
                    },
                    [_c("feather-icon", { attrs: { name: "arrow-left" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { disabled: !_vm.focus },
                      attrs: { tabindex: "-1", href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          _vm.showMenu = true
                        }
                      }
                    },
                    [
                      _c("feather-icon", {
                        ref: "menu",
                        attrs: { name: "menu" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { disabled: !_vm.canMoveRight },
                      attrs: {
                        tabindex: "-1",
                        href: "javascript:void(0)",
                        title: "Shift+ArrowRight"
                      },
                      on: { click: _vm.moveRight }
                    },
                    [_c("feather-icon", { attrs: { name: "arrow-right" } })],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { disabled: !_vm.canMoveDown },
                      attrs: {
                        tabindex: "-1",
                        href: "javascript:void(0)",
                        title: "Shift+ArrowDown"
                      },
                      on: { click: _vm.moveDown }
                    },
                    [_c("feather-icon", { attrs: { name: "arrow-down" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("span")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pim-hierarchies-head" }, [
                _c(
                  "div",
                  { staticClass: "pim-hierarchies-search" },
                  [
                    _c("my-search", {
                      ref: "search",
                      model: {
                        value: _vm.searchString,
                        callback: function($$v) {
                          _vm.searchString = $$v
                        },
                        expression: "searchString"
                      }
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "gp-check",
                      {
                        model: {
                          value: _vm.multiselect,
                          callback: function($$v) {
                            _vm.multiselect = $$v
                          },
                          expression: "multiselect"
                        }
                      },
                      [_vm._v("Enable multiselect mode")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pim-hierarchies-entries" }, [
                _c("table", [
                  _vm.entriesReport && _vm.entriesReport.meta.vals.length > 5
                    ? _c("thead", [
                        _c(
                          "tr",
                          [
                            _c("th"),
                            _vm._v(" "),
                            _vm._l(
                              _vm.entriesReport.meta.vals.slice(5),
                              function(ref) {
                                var name = ref.name
                                return _c("th", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(name) +
                                      "\n              "
                                  )
                                ])
                              }
                            )
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.visibleEntries, function(entry) {
                      return _c("pim-hierarchies-entry", {
                        key: entry.id,
                        ref: "entries",
                        refInFor: true,
                        class: { focused: entry == _vm.focus },
                        attrs: {
                          entry: entry,
                          multiselect: _vm.multiselect,
                          formatColumn: _vm.formatColumn
                        },
                        on: {
                          focus: function($event) {
                            _vm.focus = $event
                          }
                        }
                      })
                    }),
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.focus && _vm.showMenu
                ? _c(
                    "my-popup",
                    {
                      attrs: {
                        draggable: true,
                        placement: "bottom",
                        anchor: _vm.$refs.menu.$el
                      },
                      on: {
                        clickoutside: function($event) {
                          _vm.showMenu = false
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "popover pim-hierarchies-menu" },
                        [
                          _c("div", { staticClass: "popover-body" }, [
                            _c("label", [_vm._v(_vm._s(_vm.focus.name))]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              [
                                _vm.focus.parent.parent
                                  ? [
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showMenu = false
                                                _vm.createSubCategory()
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: { name: "file-plus" }
                                            }),
                                            _vm._v(
                                              "Create subcategory\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showMenu = false
                                                _vm.createCategoryAfter()
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: { name: "file-plus" }
                                            }),
                                            _vm._v(
                                              "Create category right after\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showMenu = false
                                                _vm.removeThisCategory()
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: { name: "trash" }
                                            }),
                                            _vm._v(
                                              "Remove this category\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  : [
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showMenu = false
                                                _vm.createSubCategory()
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: { name: "file-plus" }
                                            }),
                                            _vm._v(
                                              "Create category\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          _vm.showMenu = false
                                          _vm.exportAttributes()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "download" }
                                      }),
                                      _vm._v(
                                        "Export attributes\n                "
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          _vm.showMenu = false
                                          _vm.importAttributes()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "upload" }
                                      }),
                                      _vm._v(
                                        "Import attributes\n                "
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          _vm.showMenu = false
                                          _vm.showColumns = true
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "list" }
                                      }),
                                      _vm._v("Manage columns\n                ")
                                    ],
                                    1
                                  )
                                ])
                              ],
                              2
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("pim-hierarchies-side", {
            ref: "side",
            attrs: {
              entry: _vm.focus,
              entries: _vm.multiselect ? _vm.selectedEntries : undefined,
              username: _vm.username,
              config: _vm.config,
              fields: _vm.fields,
              search: _vm.search
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pim-hierarchies-foot", {
        ref: "foot",
        attrs: {
          entry: _vm.focus,
          config: _vm.config,
          username: _vm.username,
          metrics: _vm.config.metrics,
          formats: _vm.config.formats,
          formulas: _vm.config.formulas,
          attributes: _vm.config.attributes,
          timeframes: _vm.config.timeframes
        }
      }),
      _vm._v(" "),
      _c("gp-data", {
        attrs: {
          stream: "fields",
          filter2: "api_group_name == 'Category Flex Field'",
          dims: ["api_name"],
          vals: ["field_name", "field_type"]
        },
        model: {
          value: _vm.fieldsReport,
          callback: function($$v) {
            _vm.fieldsReport = $$v
          },
          expression: "fieldsReport"
        }
      }),
      _vm._v(" "),
      _c("gp-data", {
        attrs: {
          stream: _vm.stream,
          dims: ["id"],
          vals: [
            "level",
            "brand.name",
            "name",
            "sequence_number",
            "ancestry"
          ].concat(_vm.columns),
          initialSort: [2, 1],
          filter2: "is_deleted == '0'"
        },
        model: {
          value: _vm.entriesReport,
          callback: function($$v) {
            _vm.entriesReport = $$v
          },
          expression: "entriesReport"
        }
      }),
      _vm._v(" "),
      _vm.showColumns
        ? _c(
            "my-popup",
            {
              attrs: {
                draggable: true,
                placement: "bottom",
                anchor: _vm.$refs.menu.$el
              },
              on: {
                clickoutside: function($event) {
                  _vm.showColumns = false
                }
              }
            },
            [
              _c("div", { staticClass: "popover pim-hierarchies-columns" }, [
                _c(
                  "div",
                  { staticClass: "popover-body" },
                  [
                    _c("gp-section-columns", {
                      attrs: {
                        autofocus: true,
                        attributes: _vm.columnsConfig.attributes,
                        metrics: _vm.columnsConfig.metrics,
                        columns: _vm.columns
                      },
                      on: {
                        submit: function($event) {
                          _vm.showColumns = false
                          _vm.columns = $event.columns
                        },
                        cancel: function($event) {
                          _vm.showColumns = false
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("form", { ref: "uploadForm", staticStyle: { display: "none" } }, [
        _c("input", {
          ref: "uploadInput",
          attrs: { type: "file" },
          on: { change: _vm.handleFileUpload }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n            Use arrows to navigate through the hierarchy."),
      _c("br"),
      _vm._v(
        "\n            Use Shift + arrows to move categories around.\n          "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }