<template>
  <table class="gp-cache">
    <tr v-for="cache in caches">
      <td>
        <l10n :value="`${cache.name} cache`" />
      </td>
      <td>
        <div class="progress">
          <div
            class="progress-bar"
            :style="{
              width: `${cache.size / cache.maxSize * 100}%`,
              backgroundColor: d3.interpolateRdYlGn(1 - cache.size / cache.maxSize),
            }">
            {{formatSize(cache.size)}}
            –
            {{formatCount(cache.count)}}
          </div>
        </div>
      </td>
      <td>
        <a href="javascript:void(0)" @click="resetCache(cache.name)"><l10n value="reset" /></a>
      </td>
    </tr>
  </table>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  data() {
    return {
      d3,
      caches: [],
      destroyed: false,
    };
  },
  mounted() {
    this.update();
  },
  beforeDestroy() {
    this.destroyed = true;
  },
  methods: {
    formatSize(size) {
      return d3.format('.3s')(size);
    },
    formatCount(count) {
      return new Number(count).toLocaleString();
    },
    resetCache(name) {
      const query = `mutation { resetCache(name:${utils.quote(name)}) }`;
      $.ajax({
        url: '/graphql?__resetCache__',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      });
    },
    update() {
      const query = `
                query {
                  caches {
                    name
                    size
                    count
                    maxTime
                    maxSize
                  }
                }`;
      utils.fetchWithAjaxOpts({
        url: '/graphql?__updateCache__',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      })
        .then(({ data }) => this.caches = data.caches || [])
        .finally(() => {
          if (!this.destroyed) {
            setTimeout(this.update, 1000);
          }
        });
    },
  },
};
</script>
<style>
.gp-cache .progress {
    height: 20px;

}
.gp-cache .progress-bar {
    color: black;
    text-shadow: 1px 1px 4px white;
}
.gp-cache {
    width: 100%;
    white-space: nowrap;
    margin-top: 20px;
    margin-bottom: 20px;
}
.gp-cache a {
    font-size: 0.9em;
}
.gp-cache td {
}
.gp-cache td:nth-child(2) {
    width: 100%;
    padding: 2px 10px;
}
.gp-cache .progress {
    border-radius: 0;
}
.gp-cache {
    width: 100%;
    white-space: nowrap;
    margin-top: 20px;
    margin-bottom: 20px;
}
.gp-cache a {
    font-size: 0.9em;
}
.gp-cache td {
}
.gp-cache td:nth-child(2) {
    width: 100%;
    padding: 2px 10px;
}
.gp-cache .progress {
    border-radius: 0;
}
</style>
