var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "my-dialog",
        {
          attrs: { title: "Add new season collection" },
          on: { close: _vm.closeForm }
        },
        [
          _c(
            "div",
            { staticClass: "inputblock" },
            [
              _c(
                "label",
                { staticClass: "inputblock__label", attrs: { for: "seasons" } },
                [_vm._v("Seasons")]
              ),
              _vm._v(" "),
              _c("my-multiselect", {
                attrs: {
                  id: "seasons",
                  options: _vm.seasons,
                  maxHeight: 190,
                  loading: _vm.isLoading,
                  placeholder: "Выберите сезон"
                },
                on: { open: _vm.getSeasons },
                model: {
                  value: _vm.season,
                  callback: function($$v) {
                    _vm.season = $$v
                  },
                  expression: "season"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputblock inputblock_select" },
            [
              _c(
                "label",
                {
                  staticClass: "inputblock__label",
                  attrs: { for: "collections" }
                },
                [_vm._v("Collections")]
              ),
              _vm._v(" "),
              _c("my-multiselect", {
                attrs: {
                  id: "collections",
                  options: _vm.collections,
                  maxHeight: 190,
                  loading: _vm.isLoading,
                  searchable: true,
                  placeholder: "Выберите коллекцию"
                },
                on: { open: _vm.getCollections },
                model: {
                  value: _vm.collection,
                  callback: function($$v) {
                    _vm.collection = $$v
                  },
                  expression: "collection"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-block" }, [
            _c(
              "button",
              {
                staticClass: "button button_primary",
                class: {
                  button_disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                attrs: {
                  type: "button",
                  disabled: _vm.hasEmptyField && _vm.tryToSendForm
                },
                on: { click: _vm.onCreateNewSeasonCollection }
              },
              [_c("l10n", { attrs: { value: "Save" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button_secondary",
                attrs: { type: "button" },
                on: { click: _vm.closeForm }
              },
              [_c("l10n", { attrs: { value: "Cancel" } })],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }