<template>
  <div class="my-progress">
    <my-assembly v-if="showAssembly" @close="showAssembly=false" :assembly="assemblyWithQuery"/>
    <small v-if="editMode && processTime !== null" class="text-muted">
      {{new Number(processRows).toLocaleString()}} records
      processed in {{new Number(processTime).toLocaleString()}} ms
    </small>

    <template v-if="progress100 > 0 && progress100 < 100">
      <span
        class="vue-progress-path"
        style="width: 22px; height: 22px;">
        <svg width="22" height="22" viewBox="0 0 22 22">
          <g class="container">
            <path :d="path"
                  :stroke-dasharray="dasharray"
                  :stroke-dashoffset="dashoffset"
                  class="progress">
            </path>
          </g>
        </svg>
      </span>
      <small class="my-progress-text text-muted">
        {{progress100}}
      </small>
    </template>
    <template v-else-if="sorting">
      <span
        class="vue-progress-path"
        style="width: 22px; height: 22px;">
        <svg width="22" height="22" viewBox="0 0 22 22">
          <g class="container">
            <path :d="path"
                  :stroke-dasharray="dasharray"
                  :stroke-dashoffset="dashoffset"
                  class="progress">
            </path>
          </g>
        </svg>
      </span>
      <small class="my-progress-text">
        <feather-icon name="shuffle"/>
      </small>
    </template>
    <span
      class="my-progress-text"
      v-else-if="downloading"
    >
      <feather-icon name="download-cloud"/>
    </span>
    <span
      class="my-progress-text"
      v-else-if="working"
    >
      <feather-icon name="clock"/>
    </span>
    <a
      v-else-if="editMode && progress100 === 100 && assembly"
      href="javascript:void(0)" @click="showAssembly=true">
      <feather-icon name="code"></feather-icon>
    </a>
    <a
      v-else-if="refreshData && progress100 === 100"
      href="javascript:void(0)" @click="refreshData()">
      <feather-icon name="refresh-cw"></feather-icon>
    </a>
    <a
      v-else-if="exportToFile && progress100 === 100"
      href="javascript:void(0)" @click="exportToFile()">
      <feather-icon name="download"></feather-icon>
    </a>
  </div>
</template>
<script>
let utils = require('./my-utils');
module.exports = {
  props: {
    sorting: { default: false },
    working: { default: false },
    progress: { default: null },
    downloading: { default: false },
    meta: { type: Object },
    assembly: { type: Object },
    processTime: { default: null },
    processRows: { default: null },
    exportToFile: { type: Function },
    refreshData: { type: Function },
  },
  data: function() {
    return {
      showAssembly: false,
      editMode: localStorage.editMode === 'true',
    };
  },
  computed: {
    progress100: function() {
      return Math.round(this.progress * 100);
    },
    path: function() {
      let cx = 11;
      let cy = 11;
      let r = 8.5;
      return `
                M ${cx - r}, ${cy}
                a ${r},${r} 0 1,0 ${r * 2},0
                a ${r},${r} 0 1,0 -${r * 2},0`;
    },
    dashoffset: function() {
      0;
      // let r = 8.5
      // let l = 2*Math.PI*r
      // return `${this.progress*l}`
    },
    dasharray: function() {
      let r = 8.5;
      if (this.sorting) {
        let s = 2*Math.PI*r/16;
        return `${s/2},${s/2+s}`;
      } else {
        let a = Math.max(0.01, this.progress) * 2;
        let l1 = 2*Math.PI*r/4 * a;
        let l2 = 2*Math.PI*r/4 * (1-a);
        return `${l1/2},${l2+l1/2}`;
      }
    },
    assemblyWithQuery: function() {
      let result = {};
      _.assign(result, this.assembly);
      if (this.meta && this.meta.query) {
        result.Query =
                    this.meta.query
                      .split('\n')
                      .map((line) => line.slice(16))
                      .map((line) => line.replace(/^[ ]+/,
                        (prefix) => prefix.slice(0, prefix.length/2)))
                      .join('\n')
                      .trim();
      }
      return result;
    },
  },
  mounted() {
    utils.bridge.bind('modeChanged', this.modeChanged);
  },
  beforeDestroy() {
    utils.bridge.unbind('modeChanged', this.modeChanged);
  },
  methods: {
    modeChanged() {
      this.editMode = localStorage.editMode === 'true';
    },
  },
};
</script>
<style>
.vue-progress-path {
    display: inline-block
}

.vue-progress-path path {
    fill: none;
    stroke-width: 6;
    transition: stroke-dashoffset .3s ease-in-out
}

.vue-progress-path .background {
    stroke: #ddd
}

.vue-progress-path .progress {
    stroke: #40b883
}

.vue-progress-path.indeterminate path {
    transition: none
}
.vue-progress-path path {
    stroke-width: 2.2;
    transition:
        stroke-dashoffset .1s ease-in-out,
        stroke-dasharray .1s ease-in-out;
}
.vue-progress-path svg {
    animation-name: progress;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.my-progress .feather-icon-download svg,
.my-progress .feather-icon-file-text svg {
    /*width: 20px;*/
    /*height: 20px;*/
    padding: 1px;
}
.my-progress-text .feather-icon svg {
    width: 10px!important;
    height: 10px!important;
}
.my-progress-text .feather-icon.feather-icon-clock svg {
    width: 18px!important;
    height: 18px!important;
    margin-top: -3px;
    color: #40b883;
}
.my-progress-text .feather-icon.feather-icon-download-cloud svg {
    width: 20px!important;
    height: 20px!important;
    margin-top: -4px;
    color: #40b883;
}
.my-progress {
    float: right;
    position: relative;
    text-align: right;
    height: 22px;
    margin-left: 8px;
}
.my-progress-text {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 10px;
    width: 22px;
    height: 22px;
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: center;
    font-size: 10px;
}
.my-light-theme .my-progress-text {
    margin-top: 7px;
}
</style>
