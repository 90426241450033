var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isboundAttribute1Loaded &&
    _vm.isboundAttribute2Loaded &&
    _vm.attributeToNestLoaded &&
    _vm.isIndividualAttributeLoaded
    ? _c(
        "my-popup",
        {
          attrs: { anchor: _vm.clickedButtonElement, placement: "insideTable" },
          on: { clickoutside: _vm.handleModalClose }
        },
        [
          _c(
            "div",
            { staticClass: "popover modal-container" },
            [
              _c(
                "h6",
                { staticClass: "attributes-select-header" },
                [
                  _c("l10n", { attrs: { value: "Bind attribute:" } }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.attributeBindingData.baseAttribute.name) +
                      "\n    "
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.attributeToNest && !_vm.individualAttribute
                ? _c("div", { staticClass: "attributes-select-container" }, [
                    _c(
                      "p",
                      { staticClass: "attributes-select-title" },
                      [_c("l10n", { attrs: { value: "Bind to:" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "attributes-selects" },
                      [
                        _c("my-select", {
                          attrs: {
                            placeholder: "Select attribute to bind",
                            incomingOptions:
                              _vm.attributeBindingData.attributes,
                            initialSelection: _vm.initialBoundAttribute1
                          },
                          on: {
                            "select-change": function($event) {
                              return _vm.handleSelectChange(
                                $event,
                                "boundAttribute1"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("my-select", {
                          attrs: {
                            placeholder: "Select attribute to bind",
                            incomingOptions:
                              _vm.attributeBindingData.attributes,
                            initialSelection: _vm.initialBoundAttribute2,
                            disabled: _vm.boundAttribute1 === null
                          },
                          on: {
                            "select-change": function($event) {
                              return _vm.handleSelectChange(
                                $event,
                                "boundAttribute2"
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.boundAttribute1 && !_vm.individualAttribute
                ? _c(
                    "div",
                    { staticClass: "attributes-select-container" },
                    [
                      _c(
                        "p",
                        { staticClass: "attributes-select-title" },
                        [_c("l10n", { attrs: { value: "Nest to:" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("my-select", {
                        attrs: {
                          placeholder: "Select attribute to be nested to",
                          incomingOptions: _vm.attributeBindingData.attributes,
                          initialSelection: _vm.initialAttributeToNest
                        },
                        on: {
                          "select-change": function($event) {
                            return _vm.handleSelectChange(
                              $event,
                              "attributeToNest"
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.boundAttribute1
                ? _c(
                    "gp-check",
                    {
                      attrs: { checked: _vm.individualAttribute },
                      on: { change: _vm.handleIndividualAttributeChange }
                    },
                    [
                      _c("l10n", {
                        attrs: { value: "Is individual attribute" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "attributes-select-buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary btn-apply",
                    attrs: { type: "button" },
                    on: { click: _vm.handleApplyChanges }
                  },
                  [_c("l10n", { attrs: { value: "Apply changes" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.handleClose }
                  },
                  [_c("l10n", { attrs: { value: "Discard" } })],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }