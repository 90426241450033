module.exports.productPlanColumnsWithBehavior = {
  'pp_product_plan_ss_sales_ty, avg_pp_product_plan_ss_growth_ly, avg_pp_product_plan_ss_growth_lly': '/api/product-plan/balance-set/revenue-calc/ss-revenue-sales-rub',
  'pp_product_plan_fw_sales_ty, avg_pp_product_plan_fw_growth_ly, avg_pp_product_plan_fw_growth_lly': '/api/product-plan/balance-set/revenue-calc/fw-revenue-sales-rub',
  'pp_product_plan_ba_sales_ty, avg_pp_product_plan_ba_growth_ly, avg_pp_product_plan_ba_growth_lly': '/api/product-plan/balance-set/revenue-calc/base-revenue-sales-rub',
  'avg_pp_product_plan_ss_growth_ly, avg_pp_product_plan_ss_growth_lly, pp_product_plan_ss_sales_ty, pp_product_plan_ss_sales_ly': '/api/product-plan/balance-set/revenue-calc/ss-growth-ly-sales-rub',
  'avg_pp_product_plan_ss_growth_lly, avg_pp_product_plan_ss_growth_ly, pp_product_plan_ss_sales_ty, pp_product_plan_ss_sales_lly': '/api/product-plan/balance-set/revenue-calc/ss-growth-lly-sales-rub',
  'avg_pp_product_plan_fw_growth_ly, avg_pp_product_plan_fw_growth_lly, pp_product_plan_fw_sales_ty, pp_product_plan_fw_sales_ly': '/api/product-plan/balance-set/revenue-calc/fw-growth-ly-sales-rub',
  'avg_pp_product_plan_fw_growth_lly, avg_pp_product_plan_fw_growth_ly, pp_product_plan_fw_sales_ty, pp_product_plan_fw_sales_lly': '/api/product-plan/balance-set/revenue-calc/fw-growth-lly-sales-rub',
  'avg_pp_product_plan_ba_growth_ly, avg_pp_product_plan_ba_growth_lly, pp_product_plan_ba_sales_ty, pp_product_plan_ba_sales_ly': '/api/product-plan/balance-set/revenue-calc/base-growth-ly-sales-rub',
  'avg_pp_product_plan_ba_growth_lly, avg_pp_product_plan_ba_growth_ly, pp_product_plan_ba_sales_ty, pp_product_plan_ba_sales_lly': '/api/product-plan/balance-set/revenue-calc/base-growth-lly-sales-rub',
  pp_product_plan_ttl_sales_ty: '/api/product-plan/balance-set/revenue-calc/total-revenue-sales-rub',
  'avg_pp_product_plan_ttl_growth_ly, avg_pp_product_plan_ttl_growth_lly, pp_product_plan_ttl_sales_ty, pp_product_plan_ttl_sales_ly': '/api/product-plan/balance-set/revenue-calc/total-growth-ly-sales-rub',
  'avg_pp_product_plan_ttl_growth_lly, avg_pp_product_plan_ttl_growth_ly, pp_product_plan_ttl_sales_ty, pp_product_plan_ttl_sales_lly': '/api/revenueproduct-plan/balance-set/revenue-calc_calc/total-growth-lly-sales-rub',
  'avg_pp_share_division_ss_ty, avg_pp_share_target_group_ss_ty, avg_pp_share_category_ss_ty, avg_pp_share_category_plus_ss_ty, avg_pp_share_year_ss_ty, avg_pp_share_half_year_ss_ty, avg_pp_share_quarter_ss_ty, avg_pp_share_month_ss_ty, avg_pp_share_week_ss_ty': '/api/product-plan/balance-set/revenue-calc/ss-share-sales-rub',
  'avg_pp_share_division_fw_ty, avg_pp_share_target_group_fw_ty, avg_pp_share_category_fw_ty, avg_pp_share_category_plus_fw_ty, avg_pp_share_year_fw_ty, avg_pp_share_half_year_fw_ty, avg_pp_share_quarter_fw_ty, avg_pp_share_month_fw_ty, avg_pp_share_week_fw_ty': '/api/product-plan/balance-set/revenue-calc/fw-share-sales-rub',
  'avg_pp_share_division_base_ty, avg_pp_share_target_group_base_ty, avg_pp_share_category_base_ty, avg_pp_share_category_plus_base_ty, avg_pp_share_year_base_ty, avg_pp_share_half_year_base_ty, avg_pp_share_quarter_base_ty, avg_pp_share_month_base_ty, avg_pp_share_week_base_ty': '/api/product-plan/balance-set/revenue-calc/base-share-sales-rub',
  'avg_pp_share_division_total_ty, avg_pp_share_target_group_total_ty, avg_pp_share_category_total_ty, avg_pp_share_category_plus_total_ty, avg_pp_share_year_total_ty, avg_pp_share_half_year_total_ty, avg_pp_share_quarter_total_ty, avg_pp_share_month_total_ty, avg_pp_share_week_total_ty': '/api/product-plan/balance-set/revenue-calc/total-share-sales-rub',
  pp_product_plan_ss_receipt_ty: '/api/product-plan/balance-set/receipt-calc/ss-revenue-sales-rub',
  pp_product_plan_fw_receipt_ty: '/api/product-plan/balance-set/receipt-calc/fw-revenue-sales-rub',
  pp_product_plan_base_receipt_ty: '/api/product-plan/balance-set/receipt-calc/base-revenue-sales-rub',
  pp_product_plan_total_receipt_ty: '/api/product-plan/balance-set/receipt-calc/total-revenue-sales-rub',
  'pp_ss_receipt_growth_ly, pp_product_plan_ss_receipt_ty, pp_product_plan_ss_receipt_ly': '/api/product-plan/balance-set/receipt-calc/ss-growth-ly-sales-rub',
  'pp_fw_receipt_growth_ly, pp_product_plan_fw_receipt_ty, pp_product_plan_fw_receipt_ly': '/api/product-plan/balance-set/receipt-calc/fw-growth-ly-sales-rub',
  'pp_base_receipt_growth_ly, pp_product_plan_base_receipt_ty, pp_product_plan_base_receipt_ly': '/api/product-plan/balance-set/receipt-calc/base-growth-ly-sales-rub',
  'pp_total_receipt_growth_ly, pp_product_plan_total_receipt_ty, pp_product_plan_total_receipt_ly': '/api/product-plan/balance-set/receipt-calc/total-growth-ly-sales-rub',
  'pp_ss_receipt_growth_lly, pp_product_plan_ss_receipt_ty, pp_product_plan_ss_receipt_lly': '/api/product-plan/balance-set/receipt-calc/ss-growth-lly-sales-rub',
  'pp_fw_receipt_growth_lly, pp_product_plan_fw_receipt_ty, pp_product_plan_fw_receipt_lly': '/api/product-plan/balance-set/receipt-calc/fw-growth-lly-sales-rub',
  'pp_base_receipt_growth_lly, pp_product_plan_base_receipt_ty, pp_product_plan_base_receipt_lly': '/api/product-plan/balance-set/receipt-calc/base-growth-lly-sales-rub',
  'pp_total_receipt_growth_lly, pp_product_plan_total_receipt_ty, pp_product_plan_total_receipt_lly': '/api/product-plan/balance-set/receipt-calc/total-growth-lly-sales-rub',
};

module.exports.writeoffPlanColumnsWithBehavior = {
  pp_product_plan_ss_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/ss-revenue-sales-rub',
  'pp_ss_writeoff_growth_ly, pp_product_plan_ss_writeoff_ty, pp_product_plan_ss_writeoff_ly': '/api/product-plan/balance-set/writeoff-calc/ss-growth-ly-sales-rub',
  'pp_ss_writeoff_growth_lly, pp_product_plan_ss_writeoff_ty, pp_product_plan_ss_writeoff_lly': '/api/product-plan/balance-set/writeoff-calc/ss-growth-lly-sales-rub',
  'pp_ss_percent_writeoff_ty, pp_product_plan_ss_writeoff_ty, pp_product_plan_ss_sales_ty': '/api/product-plan/balance-set/writeoff-calc/ss-revenue-percent',
  'pp_fw_writeoff_growth_ly, pp_product_plan_fw_writeoff_ty, pp_product_plan_fw_writeoff_ly': '/api/product-plan/balance-set/writeoff-calc/fw-growth-ly-sales-rub',
  'pp_fw_writeoff_growth_lly, pp_product_plan_fw_writeoff_ty, pp_product_plan_fw_writeoff_lly': '/api/product-plan/balance-set/writeoff-calc/fw-growth-lly-sales-rub',
  'pp_fw_percent_writeoff_ty, pp_product_plan_fw_writeoff_ty, pp_product_plan_fw_sales_ty': '/api/product-plan/balance-set/writeoff-calc/fw-revenue-percent',
  pp_product_plan_fw_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/fw-revenue-sales-rub',
  'pp_base_writeoff_growth_ly, pp_product_plan_base_writeoff_ty, pp_product_plan_base_writeoff_ly': '/api/product-plan/balance-set/writeoff-calc/base-growth-ly-sales-rub',
  'pp_base_writeoff_growth_lly, pp_product_plan_base_writeoff_ty, pp_product_plan_base_writeoff_lly': '/api/product-plan/balance-set/writeoff-calc/base-growth-lly-sales-rub',
  'pp_base_percent_writeoff_ty, pp_product_plan_base_writeoff_ty, pp_product_plan_ba_sales_ty': '/api/product-plan/balance-set/writeoff-calc/base-revenue-percent',
  pp_product_plan_base_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/base-revenue-sales-rub',
  'pp_total_writeoff_growth_ly, pp_product_plan_total_writeoff_ty, pp_product_plan_total_writeoff_ly': '/api/product-plan/balance-set/writeoff-calc/total-growth-ly-sales-rub',
  'pp_total_writeoff_growth_lly, pp_product_plan_total_writeoff_ty, pp_product_plan_total_writeoff_lly': '/api/product-plan/balance-set/writeoff-calc/total-growth-lly-sales-rub',
  'pp_total_percent_writeoff_ty, pp_product_plan_total_writeoff_ty, pp_product_plan_ttl_sales_ty': '/api/product-plan/balance-set/writeoff-calc/total-revenue-percent',
  pp_product_plan_total_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/total-revenue-sales-rub',
};

module.exports.recallPlanColumnsWithBehavior = {
  pp_product_plan_ss_recall_ty: '/api/product-plan/balance-set/recall-calc/ss-revenue-sales-rub',
  pp_product_plan_fw_recall_ty: '/api/product-plan/balance-set/recall-calc/fw-revenue-sales-rub',
  pp_product_plan_base_recall_ty: '/api/product-plan/balance-set/recall-calc/base-revenue-sales-rub',
  pp_product_plan_total_recall_ty: '/api/product-plan/balance-set/recall-calc/total-revenue-sales-rub',
};

module.exports.productPlanColumnsWithClick = {
  pp_season_receipts_ss: '/api/product-plan/balance-set/receipt-calc/ss-season-receipts',
  pp_season_receipts_fw: '/api/product-plan/balance-set/receipt-calc/fw-season-receipts',
  pp_season_receipts_base: '/api/product-plan/balance-set/receipt-calc/base-season-receipts',
  pp_season_receipts_total: '/api/product-plan/balance-set/receipt-calc/total-season-receipts',
  'pp_product_plan_ss_sales_ty, avg_pp_product_plan_ss_growth_ly, avg_pp_product_plan_ss_growth_lly': '/api/product-plan/balance-set/revenue-calc/ss-reset-revenue',
  'pp_product_plan_fw_sales_ty, avg_pp_product_plan_fw_growth_ly, avg_pp_product_plan_fw_growth_lly': '/api/product-plan/balance-set/revenue-calc/fw-reset-revenue',
  'pp_product_plan_ba_sales_ty, avg_pp_product_plan_ba_growth_ly, avg_pp_product_plan_ba_growth_lly': '/api/product-plan/balance-set/revenue-calc/base-reset-revenue',
  pp_product_plan_ttl_sales_ty: '/api/product-plan/balance-set/revenue-calc/total-reset-revenue',
  pp_product_plan_ss_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/ss-reset-writeoff',
  pp_product_plan_fw_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/fw-reset-writeoff',
  pp_product_plan_base_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/base-reset-writeoff',
  pp_product_plan_total_writeoff_ty: '/api/product-plan/balance-set/writeoff-calc/total-reset-writeoff',
  pp_product_plan_ss_recall_ty: '/api/product-plan/balance-set/recall-calc/ss-reset-recall',
  pp_product_plan_fw_recall_ty: '/api/product-plan/balance-set/recall-calc/fw-reset-recall',
  pp_product_plan_base_recall_ty: '/api/product-plan/balance-set/recall-calc/base-reset-recall',
  pp_product_plan_total_recall_ty: '/api/product-plan/balance-set/recall-calc/total-reset-recall',
  pp_product_plan_ss_receipt_ty: '/api/product-plan/balance-set/receipt-calc/ss-reset-receipts',
  pp_product_plan_fw_receipt_ty: '/api/product-plan/balance-set/receipt-calc/fw-reset-receipts',
  pp_product_plan_base_receipt_ty: '/api/product-plan/balance-set/receipt-calc/base-reset-receipts',
  pp_product_plan_total_receipt_ty: '/api/product-plan/balance-set/receipt-calc/total-reset-receipts',
  pp_recall_un_ss_ty: '/api/product-plan/balance-set/recall-un-cost/ss-reset-recall-un',
  pp_recall_un_fw_ty: '/api/product-plan/balance-set/recall-un-cost/fw-reset-recall-un',
  pp_recall_un_base_ty: '/api/product-plan/balance-set/recall-un-cost/base-reset-recall-un',
  pp_recall_un_total_ty: '/api/product-plan/balance-set/recall-un-cost/total-reset-recall-un',
  pp_recall_cost_ss_ty: '/api/product-plan/balance-set/recall-un-cost/ss-reset-recall-cost',
  pp_recall_cost_fw_ty: '/api/product-plan/balance-set/recall-un-cost/fw-reset-recall-un',
  pp_recall_cost_base_ty: '/api/product-plan/balance-set/recall-un-cost/base-reset-recall-cost',
  pp_recall_cost_total_ty: '/api/product-plan/balance-set/recall-un-cost/total-reset-recall-cost',
};

module.exports.bopCoefficientColumns = {
  avg_pp_koef_bop_ss: '/api/product-plan/balance-set/un-cost-calc/update-un-cost-plan',
  avg_pp_koef_bop_fw: '/api/product-plan/balance-set/un-cost-calc/update-un-cost-plan',
  avg_pp_koef_bop_base: '/api/product-plan/balance-set/un-cost-calc/update-un-cost-plan',
};

module.exports.recallCostUnColumns = {
  pp_recall_un_ss_ty: '/api/product-plan/balance-set/recall-un-cost/ss-recall-un',
  pp_recall_un_fw_ty: '/api/product-plan/balance-set/recall-un-cost/fw-recall-un',
  pp_recall_un_base_ty: '/api/product-plan/balance-set/recall-un-cost/base-recall-un',
  pp_recall_un_total_ty: '/api/product-plan/balance-set/recall-un-cost/total-recall-un',
  pp_recall_cost_ss_ty: '/api/product-plan/balance-set/recall-un-cost/ss-recall-cost',
  pp_recall_cost_fw_ty: '/api/product-plan/balance-set/recall-un-cost/fw-reset-recall-un',
  pp_recall_cost_base_ty: '/api/product-plan/balance-set/recall-un-cost/base-recall-cost',
  pp_recall_cost_total_ty: '/api/product-plan/balance-set/recall-un-cost/total-recall-cost',
};
