<template>
  <div class="gp-column-filters">
    <a
      v-if="hasMeaningfulConditions"
      href="javascript:void(0)"
      @click="conditionExpanded = !conditionExpanded">
      <feather-icon :name="conditionExpanded ? 'chevron-down' : 'chevron-right'" />
      <l10n value="Filter by condition" />
    </a>
    <template v-if="hasMeaningfulConditions && conditionExpanded">
      <div class="gp-column-filters__meaningful-condition">
        <select
          class="form-control form-control-sm"
          :value="currentCondition"
          @change="handleConditionChange">
          <option
            v-for="condition, key in validConditions"
            :value="key"
            ref="conditions"
          >
            <l10n :value="condition.name" />
          </option>
        </select>
      </div>
      <div class="gp-column-filters-args" v-if="conditions[currentCondition]">
        <input
          class="form-control form-control-sm"
          v-for="type, i in conditions[currentCondition].args"
          :type="type"
          :value="currentValue(i)"
          @change="handleValueChange($event, i)" />
      </div>
    </template>
    <a
      v-if="columnType === 'text'"
      href="javascript:void(0)"
      @click="valueExpanded = !valueExpanded">
      <feather-icon :name="valueExpanded ? 'chevron-down' : 'chevron-right'" />
      <l10n value="Filter by value" />
    </a>
    <template v-if="columnType === 'text' && valueExpanded">
      <input
        class="form-control form-control-sm"
        type="search"
        :placeholder="l10n('Search...')"
        @change="updateSearchString"
        @search="updateSearchString"
        @click="updateSearchString"
        @keyup="updateSearchString" />
      <ul>
        <li v-if="product == 'pim' && !valuesReport && !valuesErrors">
          <l10n class="text-muted" value="Loading..." />
        </li>
        <li v-if="valuesErrors">{{valuesErrors}}</li>
        <li v-for="value in visibleValues.slice(0, moreThreshold)">
          <gp-check
            :checked="currentValues[value.item ? value.item[columnIndex] : value[columnIndex]] !== false"
            @change="handleValuesChange(value.item ? value.item[columnIndex] : value[columnIndex], $event)"
          >
            <template v-if="value.item">
              <span
                v-for="part in formatSearchItem(value)"
                :class="{ matched: part.matched }"
              >{{part.text}}</span>
            </template>
            <template v-else-if="value[columnIndex]">{{value[columnIndex]}}</template>
            <template v-else><l10n value="(Blanks)" /></template>
          </gp-check>
        </li>
        <li v-if="visibleValues.length > moreThreshold">
          <a href="#" @click.prevent="moreThreshold = moreThreshold * 2">
            <l10n value="and {more} more..." :more="new Number(visibleValues.length - moreThreshold).toLocaleString()" />
          </a>
        </li>
      </ul>
      <div class="gp-column-filters-values-actions">
        <a
          href="javascript:void(0)"
          @click="selectAllValues">
          <l10n value="Select all" />
        </a>
        –
        <a
          href="javascript:void(0)"
          @click="clearValues">
          <l10n value="Select none" />
        </a>
      </div>
      <gp-data
        v-if="product == 'pim'"
        id="gp-column-filters"
        :cache="false"
        :stream="stream"
        :groups="groups"
        :source="{
          dims,
          vals,
          cols,
          filter0,
          filter1,
          filter2,
          filter3,
          source,
        }"
        :dims="[`col${column.i + 1}`]"
        :initialSort="[1]"
        :throttled="false"
        v-model="valuesReport"
        @errors="valuesErrors = $event && $event.length > 0 ? $event : null"
      />
    </template>
  </div>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  props: {
    stream: { type: String, default: 'default' },
    groups: { type: Array, default: () => [] },
    source: { type: Object },
    filter0: { type: String, default: '' },
    filter1: { type: String, default: '' },
    filter2: { type: String, default: '' },
    filter3: { type: String, default: '' },
    dims: { type: Array, default: () => [] },
    vals: { type: Array, default: () => [] },
    cols: { type: Array, default: () => [] },
    vars: { type: Object, default: () => ({}) },

    conditions: { type: Object, default: () => ({}) },
    condition: { type: String, default: 'none' },
    args: { type: Array, default: () => [] },
    values: { type: Object, default: () => ({}) },
    column: { type: Object },
    rows: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    product: { type: String },
  },
  data() {
    return {
      l10n: utils.l10n,
      conditionExpanded: this.condition && this.condition !== 'none',
      valueExpanded: !_.isEmpty(this.values),
      changes: {},
      searchString: '',
      valuesReport: null,
      valuesErrors: null,
      moreThreshold: 100,
    };
  },
  watch: {
    changes: {
      deep: true,
      handler: _.debounce(function (changes) {
        if (!_.isEmpty(changes.values)) {
          changes = _.clone(changes);
          const { values } = changes;
          let include = [];
          let exclude = [];
          for (const value of _.keys(values)) {
            if (values[value]) {
              include.push(value);
            } else {
              exclude.push(value);
            }
          }
          if (exclude.length == this.uniqueValues.length) {
            exclude = undefined;
            include = [];
          } else if (!include.length && exclude.length) {
            include = undefined;
          } else if (!exclude.length && include.length) {
            exclude = undefined;
          } else if (exclude.length + include.length == this.uniqueValues.length) {
            if (exclude.length < this.uniqueValues.length / 2) {
              include = undefined;
            } else {
              exclude = undefined;
            }
          } else {
            include = undefined;
          }

          changes.include = include;
          changes.exclude = exclude;
        }
        this.$emit('change', changes);
      }, 300, { trailing: true }),
    },
  },
  computed: {
    columnIndex() {
      return this.product == 'pim' ? 0 : this.column.i;
    },
    columnFormat() {
      return this.column.metric?.format || this.column.attribute?.format;
    },
    columnType() {
      if (this.columnFormat === 'timestamp') {
        return 'timestamp';
      }

      switch (this.column.type) {
        case 'string':
        case 'tagged':
          return 'text';

        case 'docid':
        case 'int8':
        case 'int16':
        case 'int32':
        case 'int64':
        case 'float':
        case 'double':
          return 'number';

        default:
          return this.column.type;
      }
    },
    validConditions() {
      return _(this.conditions)
        .toPairs()
        .filter(([key, { args }]) => key === 'none'
                    || !args && this.columnType === 'text'
                    || args && args[0] === this.columnType)
        .fromPairs()
        .value();
    },
    hasMeaningfulConditions() {
      return _.keys(this.validConditions).some((key) => key !== 'none');
    },
    somethingChanged() {
      return !_.isEmpty(this.changes);
    },
    currentCondition() {
      return this.changes.condition || this.condition || 'none';
    },
    currentValues() {
      return this.changes.values || this.values || {};
    },
    currentArgs() {
      return this.changes.args && this.changes.args || this.args || [];
    },
    uniqueValues() {
      const { i } = this.column;
      return this.product == 'pim'
        ? this.valuesReport?.rows || []
        : _(this.rows)
          .filter((row) => _.every(this.columns, (column) => column.i == i
                        || !column.filter
                        || column.filter(row[column.i], row, column)))
          .uniqBy((row) => row[i])
          .sortBy((row) => row[i])
          .value();
    },
    knownValues() {
      return new Fuse(this.uniqueValues, {
        isCaseSensitive: false,
        shouldSort: true,
        includeMatches: true,
        keys: [`${this.columnIndex}`],
      });
    },
    visibleValues() {
      if (this.knownValues && this.searchString) {
        return this.knownValues.search(this.searchString);
      }
      return this.uniqueValues;
    },
  },
  methods: {
    parseValue(x) {
      if (this.columnType === 'text') {
        return x;
      }
      if (this.columnFormat === 'percent') {
        return parseFloat(x) / 100;
      }
      return parseFloat(x);
    },
    formatValue(x) {
      if (this.columnType === 'text') {
        return x;
      }
      if (this.columnFormat === 'percent') {
        return `${x * 100}`;
      }
      return `${x}`;
    },
    currentValue(i) {
      const x = this.currentArgs[i];
      if (x === undefined) {
        if (this.columnType === 'number') {
          const args = _.clone(this.currentArgs);
          args[i] = this.parseValue('0');
          this.$set(this.changes, 'args', args);

          return '0';
        }
        return '';
      }
      return this.formatValue(this.currentArgs[i]);
    },

    updateSearchString(e) {
      const { value } = e.target;
      clearTimeout(this.searchStringTimeout);
      if (value === '') {
        this.searchString = '';
      } else {
        this.searchStringTimeout = setTimeout(() => this.searchString = value, 200);
      }
    },
    handleConditionChange(e) {
      const condition = this.$refs.conditions.find(({ selected }) => selected);
      this.$set(this.changes, 'condition', condition.value);
    },
    handleValueChange(e, i) {
      const args = _.clone(this.currentArgs);
      args[i] = this.parseValue(e.target.value);
      this.$set(this.changes, 'args', args);
    },
    handleValuesChange(value, checked) {
      let values = _.clone(this.currentValues);
      values[value] = checked;
      if (_(values).values().every((x) => x)) {
        values = {};
      }
      this.$set(this.changes, 'values', values);
    },
    selectAllValues() {
      if (this.searchString === '') {
        this.$set(this.changes, 'values', {});
      } else {
        const i = this.columnIndex;
        const values = _.clone(this.currentValues);
        for (const value of this.visibleValues) {
          values[value.item ? value.item[i] : value[i]] = true;
        }
        this.$set(this.changes, 'values', values);
      }
    },
    clearValues() {
      const i = this.columnIndex;
      const values = _.clone(this.currentValues);
      for (const value of this.visibleValues) {
        values[value.item ? value.item[i] : value[i]] = false;
      }
      this.$set(this.changes, 'values', values);
    },
    formatSearchItem({ item, matches }) {
      let indices = _(matches).map(({ indices }) => indices).flatten().value();
      let i = 0;
      const text = item[this.columnIndex];
      const parts = [];
      while (i < text.length) {
        if (indices.length > 0) {
          const [a, b] = indices[0];
          if (i === a) {
            parts.push({
              text: text.slice(a, b + 1),
              matched: true,
            });
            i = b + 1;
            indices = indices.slice(1);
          } else {
            parts.push({
              text: text.slice(i, a),
              matched: false,
            });
            i = a;
          }
        } else {
          parts.push({
            text: text.slice(i),
            matched: false,
          });
          i = text.length;
        }
      }
      return parts;
    },
  },
};
</script>
<style>
.gp-column-filters-args {
    display: flex!important;
    margin-right: -8px;
}
.gp-column-filters-args > * {
    margin-right: 8px;
    min-width: 0;
    flex-grow: 1;
    flex-basis: 1px;
    width: 90px;
}
.gp-column-filters > * {
    display: block;
    margin-bottom: 6px;
}
.gp-column-filters > ul {
    padding-left: 10px;
    padding-bottom: 4px;
    overflow-y: auto;
    max-height: 150px;
}
.gp-column-filters > ul:not(:empty) {
    border: 1px solid #ced4da;
    border-radius: 5px;
}
.gp-column-filters > ul > li label {
    line-height: 1.1em;
}
.gp-column-filters-actions {
    display: flex;
    margin-top: 8px;
    margin-right: -8px;
    margin-bottom: 4px;
}
.gp-column-filters-actions > .btn-sm {
    flex-grow: 1;
    flex-basis: 1px;
    margin-right: 8px;
    height: auto;
    line-height: 16px;
}
.gp-column-filters .matched {
    color: var(--red);
}
.gp-column-filters__meaningful-condition {
    margin-left: 22px;
}
</style>
