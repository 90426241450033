module.exports.titlesDictionary = {
  pp_season_receipts_ss: 'Перенос из Season Base',
  pp_season_receipts_fw: 'Перенос из Season Base',
  pp_season_receipts_base: 'Перенос из Season Base',
  pp_season_receipts_total: 'Перенос из Season Base',
  'pp_product_plan_ss_sales_ty, avg_pp_product_plan_ss_growth_ly, avg_pp_product_plan_ss_growth_lly': 'Сбросить на данном уровне',
  'pp_product_plan_fw_sales_ty, avg_pp_product_plan_fw_growth_ly, avg_pp_product_plan_fw_growth_lly': 'Сбросить на данном уровне',
  'pp_product_plan_ba_sales_ty, avg_pp_product_plan_ba_growth_ly, avg_pp_product_plan_ba_growth_lly': 'Сбросить на данном уровне',
  pp_product_plan_ttl_sales_ty: 'Сбросить на данном уровне',
  pp_product_plan_ss_writeoff_ty: 'Сбросить на данном уровне',
  pp_product_plan_fw_writeoff_ty: 'Сбросить на данном уровне',
  pp_product_plan_base_writeoff_ty: 'Сбросить на данном уровне',
  pp_product_plan_total_writeoff_ty: 'Сбросить на данном уровне',
  pp_product_plan_ss_recall_ty: 'Сбросить на данном уровне',
  pp_product_plan_fw_recall_ty: 'Сбросить на данном уровне',
  pp_product_plan_base_recall_ty: 'Сбросить на данном уровне',
  pp_product_plan_total_recall_ty: 'Сбросить на данном уровне',
  pp_product_plan_ss_receipt_ty: 'Сбросить на данном уровне',
  pp_product_plan_fw_receipt_ty: 'Сбросить на данном уровне',
  pp_product_plan_base_receipt_ty: 'Сбросить на данном уровне',
  pp_product_plan_total_receipt_ty: 'Сбросить на данном уровне',
};
