<template>
  <div class="plain-table-tile-size">
    <feather-icon name="maximize-2"/>
    <label>Tile size</label>
    <input
      class="form-control form-control-sm"
      :value="value"
      type="number"
      min="100"
      max="1000"
      step="10"
      @change="$emit('change', $event.target.value)"/>
  </div>
</template>
<script>
module.exports = {
  props: {
    value: { type: [Number, String] },
  },
};
</script>
