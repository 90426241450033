var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        { staticClass: "gp-paths" },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("my-search", {
                model: {
                  value: _vm.searchString,
                  callback: function($$v) {
                    _vm.searchString = $$v
                  },
                  expression: "searchString"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.visibleStreams, function(stream, name) {
            return [
              _c("p", [
                _c("label", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.$set(_vm.opened, name, !_vm.opened[name])
                        }
                      }
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          name: _vm.opened[name]
                            ? "folder-minus"
                            : "folder-plus"
                        }
                      }),
                      _vm._v(" "),
                      _c("l10n", { attrs: { value: name } })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "gp-paths-stream-info" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.formatSize(stream.size)) + " "
                    ),
                    _c("l10n", { attrs: { value: "records" } }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.browseStream(name)
                          }
                        }
                      },
                      [_c("l10n", { attrs: { value: "browse" } })],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.opened[name]
                  ? _c(
                      "ul",
                      [
                        _vm._l(stream.groups, function(group, groupKey) {
                          return [
                            group.length != 1
                              ? [
                                  _c(
                                    "li",
                                    { staticClass: "gp-paths-group-head" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$set(
                                                _vm.opened,
                                                groupKey,
                                                !_vm.opened[groupKey]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              name: _vm.opened[groupKey]
                                                ? "folder-minus"
                                                : "folder-plus"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "gp-paths-date" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.extractDate(group[0])
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(groupKey) +
                                              "\n                "
                                          ),
                                          stream.loadedPathsRows[groupKey] !==
                                          undefined
                                            ? _c("l10n", {
                                                staticClass: "gp-paths-rows",
                                                attrs: {
                                                  value: "{rows} rows",
                                                  rows: new Number(
                                                    stream.loadedPathsRows[
                                                      groupKey
                                                    ]
                                                  ).toLocaleString()
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.includePaths(
                                                name,
                                                group
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("l10n", {
                                            attrs: { value: "include" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.excludePaths(
                                                name,
                                                group
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("l10n", {
                                            attrs: { value: "exclude" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.browseStream(name, [
                                                { __file__: group }
                                              ])
                                            }
                                          }
                                        },
                                        [
                                          _c("l10n", {
                                            attrs: { value: "browse" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            group.length == 1 || _vm.opened[groupKey]
                              ? _vm._l(group, function(path) {
                                  return _c(
                                    "li",
                                    {
                                      class: {
                                        "gp-paths-group-item": group.length != 1
                                      }
                                    },
                                    [
                                      stream.excludedPaths.indexOf(path) !== -1
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-muted",
                                                staticStyle: {
                                                  "text-decoration":
                                                    "line-through"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "gp-paths-date"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.extractDate(path)
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(path)
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.includePaths(
                                                      name,
                                                      [path]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("l10n", {
                                                  attrs: { value: "include" }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deletePaths(
                                                      name,
                                                      [path]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("l10n", {
                                                  attrs: { value: "delete" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : stream.pendingPaths.indexOf(path) !==
                                          -1
                                        ? [
                                            _c(
                                              "i",
                                              { staticClass: "text-muted" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "gp-paths-date"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.extractDate(path)
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(path) +
                                                    "\n                  "
                                                ),
                                                _c("l10n", {
                                                  attrs: { value: "pending" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : stream.skippedPaths.indexOf(path) !==
                                          -1
                                        ? [
                                            _c(
                                              "i",
                                              { staticClass: "text-muted" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "gp-paths-date"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.extractDate(path)
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(path) +
                                                    "\n                  "
                                                ),
                                                _c("l10n", {
                                                  attrs: { value: "skipped" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : [
                                            _c(
                                              "span",
                                              { staticClass: "gp-paths-date" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.extractDate(path))
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            stream.loadedPathsRows[path] === 0
                                              ? _c("feather-icon", {
                                                  attrs: {
                                                    name: "alert-triangle"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(path) +
                                                "\n                "
                                            ),
                                            stream.loadedPathsRows[path] !==
                                            undefined
                                              ? _c("l10n", {
                                                  staticClass: "gp-paths-rows",
                                                  attrs: {
                                                    value: "{rows} rows",
                                                    rows: new Number(
                                                      stream.loadedPathsRows[
                                                        path
                                                      ]
                                                    ).toLocaleString()
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.excludePaths(
                                                      name,
                                                      [path]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("l10n", {
                                                  attrs: { value: "exclude" }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.browseStream(
                                                      name,
                                                      [{ __file__: [path] }]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("l10n", {
                                                  attrs: { value: "browse" }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            stream.loadedPathsInfo.has(path)
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showLoadingLogs(
                                                          name,
                                                          [path]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("l10n", {
                                                      attrs: {
                                                        value: "show logs"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadFile(
                                                name,
                                                path
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("l10n", {
                                            attrs: { value: "download" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                })
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ]
          }),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th"),
                  _vm._v(" "),
                  _vm._l(_vm.streams, function(stream) {
                    return _vm.hasSpouts(stream)
                      ? _c("th", [
                          _c(
                            "span",
                            [_c("l10n", { attrs: { value: stream } })],
                            1
                          )
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c("th")
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.dates, function(date) {
                return date !== "null"
                  ? _c(
                      "tr",
                      [
                        _c("th", [_vm._v(_vm._s(date))]),
                        _vm._v(" "),
                        _vm._l(_vm.streams, function(stream) {
                          return _vm.hasSpouts(stream)
                            ? _c(
                                "td",
                                [
                                  stream == "combined" && _vm.rebuilding[date]
                                    ? _c("feather-icon", {
                                        attrs: { name: "clock" }
                                      })
                                    : _vm.getPaths(date, stream, "loadedPaths")
                                        .length > 0
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.browseStream(stream, [
                                                  {
                                                    __file__: _vm.getPaths(
                                                      date,
                                                      stream,
                                                      "loadedPaths"
                                                    )
                                                  }
                                                ])
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                name: "check",
                                                title: _vm
                                                  .getPaths(
                                                    date,
                                                    stream,
                                                    "loadedPaths"
                                                  )
                                                  .join("\n")
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.getPaths(
                                              date,
                                              stream,
                                              "loadedPaths"
                                            ).length > 1
                                              ? _c("feather-icon", {
                                                  attrs: { name: "check" }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm.getPaths(date, stream, "skippedPaths")
                                        .length > 0
                                    ? _c("feather-icon", {
                                        staticClass: "gp-path-ignored",
                                        attrs: {
                                          name: "check",
                                          title: _vm
                                            .getPaths(
                                              date,
                                              stream,
                                              "skippedPaths"
                                            )
                                            .join("\n")
                                        }
                                      })
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _c("td", [
                          !_vm.rebuilding[date]
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.rebuildDate(date)
                                    }
                                  }
                                },
                                [_c("l10n", { attrs: { value: "rebuild" } })],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      2
                    )
                  : _vm._e()
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm.browsingStream
            ? _c(
                "my-dialog",
                {
                  attrs: {
                    xlarge: true,
                    scrollable: true,
                    title: _vm.browsingStream
                  },
                  on: {
                    close: function($event) {
                      _vm.browsingStream = null
                      _vm.browsingFilter = null
                    }
                  }
                },
                [
                  _c("gp-stream", {
                    attrs: {
                      stream: _vm.browsingStream,
                      filter: _vm.browsingFilter
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.logsStream && _vm.logsPaths
            ? _c(
                "my-dialog",
                {
                  attrs: {
                    large: true,
                    scrollable: true,
                    title: _vm.logsStream
                  },
                  on: {
                    close: function($event) {
                      _vm.logsStream = null
                      _vm.logsPaths = null
                    }
                  }
                },
                [
                  _vm._l(_vm.logsPaths, function(path) {
                    return _c("label", [_vm._v(_vm._s(path))])
                  }),
                  _vm._v(" "),
                  _c("gp-loaded-path-info", {
                    attrs: { stream: _vm.logsStream, paths: _vm.logsPaths }
                  })
                ],
                2
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }