<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <div class="my-design-panel">
    <ul class="nav nav-tabs">
      <li class="nav-item" style="position: relative;">
        <small class="text-muted">^1</small>
        <a
          aria-label="template"
          href="javascript:void(0)"
          :class="{ 'nav-link': true, active: editTab === 'template' }"
          @click="editTab = 'template'"
        ><l10n value="Template" /></a>
        <span id="my-text-warning" v-if="textWarning" :title="textWarning">
          <feather-icon name="alert-triangle" />
        </span>
      </li>
      <li class="nav-item" style="position: relative;">
        <small class="text-muted">^2</small>
        <a
          aria-label="elements"
          href="javascript:void(0)"
          @click="editTab = 'elements'"
          :class="{ 'nav-link': true, active: editTab === 'elements' }"
        ><l10n value="Elements" /></a>
        <span id="my-data-warning" v-if="dataWarning" :title="dataWarning">
          <feather-icon name="alert-triangle" />
        </span>
      </li>
      <li class="nav-item">
        <small class="text-muted">^3</small>
        <a
          aria-label="schema"
          href="javascript:void(0)"
          :class="{ 'nav-link': true, active: editTab === 'sources' }"
          @click="editTab = 'sources'"
        ><l10n value="Schema" /></a>
      </li>
      <li class="nav-item">
        <small class="text-muted">^4</small>
        <a
          aria-label="assets"
          href="javascript:void(0)"
          :class="{ 'nav-link': true, active: editTab === 'assets' }"
          @click="editTab = 'assets'"
        ><l10n value="Assets" /></a>
      </li>
      <li class="nav-item">
        <small class="text-muted">^5</small>
        <a
          aria-label="versions"
          href="javascript:void(0)"
          :class="{ 'nav-link': true, active: editTab === 'versions' }"
          @click="editTab = 'versions'"
        ><l10n value="Versions" /></a>
      </li>
      <li class="nav-item">
        <small class="text-muted">^6</small>
        <a
          aria-label="sitemap"
          href="javascript:void(0)"
          :class="{ 'nav-link': true, active: editTab === 'sitemap' }"
          @click="editTab = 'sitemap'"
        ><l10n value="Sitemap" /></a>
      </li>
      <li class="nav-item">
        <small class="text-muted">^6</small>
        <a
          aria-label="variables"
          href="javascript:void(0)"
          :class="{ 'nav-link': true, active: editTab === 'variables' }"
          @click="editTab = 'variables'"
        ><l10n value="Variables" /></a>
      </li>
    </ul>

    <ace-editor
      :value="text"
      mode="markdown"
      ref="templateEditor"
      v-if="visitedTabs.template"
      v-show="editTab === 'template'"
      :persistKey="`${path}text/state`"
      :darkTheme="darkTheme"
      :readOnly="!original"
      @change="$emit('change:text', $event)" />

    <ace-editor
      :value="data"
      mode="yaml"
      ref="elementsEditor"
      v-if="visitedTabs.elements"
      v-show="editTab === 'elements'"
      :persist-key="`${path}data/state`"
      :darkTheme="darkTheme"
      :readOnly="!original"
      :getCompletions="getCompletions"
      @change="$emit('change:data', $event)" />

    <div
      class="my-variables-view"
      v-show="editTab === 'variables'"
      v-if="visitedTabs.variables">
      <div class="form-group">
        <input class="form-control" v-model="variablesRoot" placeholder="Elements path" />
        <my-search class="form-control" v-model="variablesSearch" placeholder="Search..." />
      </div>
      <ace-editor
        v-model="variables"
        :readOnly="true"
        mode="yaml"
        :darkTheme="darkTheme" />
    </div>

    <div v-show="editTab === 'sources'" class="my-sources-view" v-if="visitedTabs.sources">
      <div v-if="selectedStream">
        <a
          aria-label="streams"
          href="javascript:void(0)"
          class="btn btn-secondary btn-sm"
          style="float:left;margin-right:10px;margin-top:-3px"
          @click="selectedStream = null"
        >
          <feather-icon name="chevron-left" />
          <l10n value="Streams" />
        </a>
        <h5>
          {{ selectedStream.name }}
          <a
            aria-label="refresh"
            href="javascript:void(0)"
            @click="$refs.stream.reload()"
          >
            <feather-icon name="refresh-cw" />
          </a>
          <a
            aria-label="external-link"
            target="_blank"
            rel="noopener noreferrer"
            :href="`/admin#/dataset/streams/${selectedStream.name}`"
          >
            <feather-icon name="external-link" />
          </a>
        </h5>
        <my-description :text="selectedStream.description" />
        <div class="form-group">
          <table>
            <thead>
              <tr>
                <td colspan="6">
                  <gp-check
                    :checked="selectedStreamHideColumns.length != selectedStream.columns.length"
                    :indeterminate="selectedStreamHideColumns.length > 0
                      && selectedStreamHideColumns.length < selectedStream.columns.length"
                    @change="$event
                      ? selectedStreamHideColumns.splice(0, selectedStreamHideColumns.length)
                      : selectedStreamHideColumns = selectedStream.columns
                        .map(column => column.gqlName)"
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="column of selectedStream.columns" :key="column.name">
                <td>
                  <gp-check
                    :inline="true"
                    :checked="!selectedStreamHideColumns.includes(column.gqlName)"
                    @change="$event
                      ? selectedStreamHideColumns
                        .splice(selectedStreamHideColumns.indexOf(column.gqlName), 1)
                      : selectedStreamHideColumns.push(column.gqlName)"
                  />
                  {{ column.synonym || column.gqlName }}
                </td>
                <td class="text-muted">{{ column.type }}</td>
                <td class="text-muted"><span v-if="column.indexed">indexed</span></td>
                <td class="text-muted"><span v-if="column.name != column.gqlName">{{ column.name }}</span></td>
                <td class="text-muted"><span v-if="column.synonym && column.synonym != column.gqlName">{{ column.gqlName }}</span></td>
                <td><my-description :text="column.description" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
          <gp-stream :stream="selectedStream.name" :hide-columns="selectedStreamHideColumns" ref="stream" />
        </div>
      </div>
      <div v-else-if="selectedReport">
        <a
          aria-label="back"
          href="javascript:void(0)"
          class="btn btn-secondary btn-sm"
          style="float:left;margin-right:10px;margin-top:-3px"
          @click="selectedReport = null"
        >
          <feather-icon name="chevron-left" />
          <l10n value="Reports" />
        </a>
        <h5>
          {{ selectedReport.name }}
          <a
            aria-label="reload"
            href="javascript:void(0)"
            @click="$refs.report.reload()"
          >
            <feather-icon name="refresh-cw" />
          </a>
          <a
            aria-label="gotoreport"
            target="_blank"
            rel="noopener norefferer"
            :href="`/admin#/dataset/reports/${selectedReport.name}`"
          >
            <feather-icon name="external-link" />
          </a>
        </h5>
        <my-description :text="selectedReport.description" />
        <div class="form-group">
          <table>
            <tbody>
              <tr v-for="column of selectedReportColumns" :key="column.name">
                <td>{{ column.synonym || column.gqlName }}</td>
                <td class="text-muted">{{ column.type }}</td>
                <td class="text-muted"><span v-if="column.indexed">indexed</span></td>
                <td class="text-muted"><span v-if="column.name != column.gqlName">{{ column.name }}</span></td>
                <td class="text-muted"><span v-if="column.synonym && column.synonym != column.gqlName">{{ column.gqlName }}</span></td>
                <td><my-description :text="column.description" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
          <gp-report :report="selectedReport.name" @columns="selectedReportColumns = $event" ref="report" />
        </div>
      </div>
      <div v-else>
        <h5>
          <a
            aria-label="streams"
            href="javascript:void(0)"
            @click="schemaTab = 'streams'"
            :class="{ active: schemaTab == 'streams' }"
          >
            <l10n value="Streams" />
          </a>
          <a
            aria-label="reports"
            href="javascript:void(0)"
            @click="schemaTab = 'reports'"
            :class="{ active: schemaTab == 'reports' }"
          >
            <l10n value="Reports" />
          </a>
        </h5>
        <div class="form-group">
          <my-search v-model="search" />
        </div>
        <div class="form-group" v-if="schemaTab == 'streams'">
          <table>
            <tbody>
              <tr
                v-for="stream in streams"
                :key="stream.name"
                v-if="!search || stream.name.indexOf(search) !== -1"
              >
                <td>
                  <a href="javascript:void(0)" @click="selectedStream = stream; selectedStreamHideColumns = []">
                    {{ stream.name }}
                  </a>
                </td>
                <td class="text-muted"><span v-if="stream.paused">paused</span></td>
                <td class="text-muted" style="text-align:right;">{{ Number(stream.size).toLocaleString() }}</td>
                <td class="text-muted"><my-description :text="stream.description" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group" v-if="schemaTab == 'reports'">
          <table>
            <tbody>
              <tr
                v-for="report in reports"
                :key="report.name"
                v-if="!search || report.name.indexOf(search) !== -1"
              >
                <td>
                  <a href="javascript:void(0)" @click="selectedReport = report; selectedReportColumns = null">
                    {{ report.name }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="editTab === 'assets'" class="my-assets-view">
      <template v-if="selectedAsset">
        <div class="my-selected-asset-head">
          <button
            type="button"
            class="btn btn-xs btn-secondary"
            :class="selectedAsset.data !== selectedAsset._data ? 'disabled' : null"
            @click="tryDismissAsset"
          >
            <feather-icon name="chevron-left" />
          </button>
          <a
            :href="selectedAsset.link"
            target="_blank"
            rel="noopener noreferrer"
            nofollow
          >
            <h3>{{ selectedAsset.name }}</h3>
          </a>
          <span style="flex-grow:1" />
          <span class="text-muted">{{ formatSize(selectedAsset.size) }}</span>
          <template
            v-if="isEditable"
          >
            <button
              id="submit-asset"
              type="button"
              class="btn btn-xs btn-primary"
              :disabled="selectedAsset.data === selectedAsset._data"
              @click="submitAssetChanges(selectedAsset)"
            >
              Submit changes
            </button>
            <button
              id="discard-asset"
              type="button"
              class="btn btn-xs btn-secondary"
              :disabled="selectedAsset.data === selectedAsset._data"
              @click="discardAssetChanges(selectedAsset)"
            >
              Discard changes
            </button>
          </template>
        </div>
        <ace-editor
          v-if="selectedAsset.data !== undefined && isEditable"
          v-model="selectedAsset.data"
          :darkTheme="darkTheme"
          :mode="getModeByAssetType"
        />
        <img
          v-if="isImage"
          :alt="selectedAsset.name"
          :src="selectedAsset.link"
        />
      </template>
      <template v-else>
        <table class="table table-sm table-hover table-striped">
          <tbody>
            <tr v-for="asset in assets" :key="asset.name">
              <td>
                <a href="javascript:void(0)" @click="selectedAsset = asset">
                  <feather-icon name="edit" />
                  {{ asset.name }}
                </a>
              </td>
              <td>{{ formatSize(asset.size) }}</td>
              <td>
                <a
                  aria-label="removeasset"
                  v-if="asset.name !== 'elements.yml'
                    && asset.name !== 'template.md'
                    && asset.name !== 'translation.json'"
                  href="javascript:void(0)"
                  @click="deleteAsset(asset)"
                >
                  <feather-icon name="trash" />
                </a>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <a href="javascript:void(0)" @click="createAsset">
                  <feather-icon name="plus-square" />
                  create new asset
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="drop-zone"
          ref="dropZone"
          @dragover="$event.preventDefault(); $refs.dropZone.classList.add('highlight')"
          @dragenter="$event.preventDefault(); $refs.dropZone.classList.add('highlight')"
          @dragleave="$event.preventDefault(); $refs.dropZone.classList.remove('highlight')"
          @drop="$event.preventDefault(); $refs.dropZone.classList.remove('highlight'); uploadAssets($event)"
        >
          <l10n value="Drag & drop new files here" />
        </div>
      </template>
    </div>
    <div v-if="editTab === 'versions'" class="my-versions-view">
      <backups />
    </div>
    <div v-if="editTab === 'sitemap'" class="my-sitemap-view">
      <sitemap :path="path" />
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {
    path: { type: String },
    hash: { type: String },
    text: { type: String },
    data: { type: String },
    original: { type: Boolean },
    darkTheme: { type: Boolean },
    textWarning: { type: String },
    dataWarning: { type: String },
    pageWarning: { type: String },
    textThrottled: { type: String },
    dataThrottled: { type: Object },
  },
  data() {
    const editTab = localStorage.editTab || 'template';
    const visitedTabs = {};
    visitedTabs[editTab] = true;
    return {
      search: '',
      editTab,
      visitedTabs,
      assets: [],
      selectedStream: null,
      selectedReport: null,
      selectedReportColumns: null,
      selectedStreamHideColumns: [],
      selectedReportHideColumns: [],
      editField: null,
      schemaTab: 'streams',
      schema: null,
      localStorage,
      variablesRoot: localStorage.variablesRoot || '_',
      variablesSearch: localStorage.variablesSearch || '',
      selectedAsset: null,
    };
  },
  mounted() {
    this.loadSchema();
    this.loadAssets();
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  computed: {
    variables() {
      try {
        let variables = (this.variablesRoot ? _.get(this.dataThrottled, this.variablesRoot) : this.dataThrottled) || null;
        if (this.variablesSearch) {
          let test = text => text.indexOf(this.variablesSearch) != -1;
          try {
            let regex = new RegExp(this.variablesSearch);
            test = text => text.match(regex);
          } catch (ex) {}
          let trim = node => {
            if (_.isString(node)) {
              return test(node) ? node : undefined;
            }
            if (_.isArray(node)) {
              node = node.map(trim).filter(x => x !== undefined);
              return node.length ? node : undefined;
            }
            if (_.isPlainObject(node)) {
              node = _(node)
                .toPairs()
                .map(([k,v]) => test(k) ? [k,v] : [k, trim(v)])
                .filter(([k,v]) => v !== undefined)
                .fromPairs()
                .value();
              return !_.isEmpty(node) ? node : undefined;
            }
            return undefined;
          };
          variables = trim(variables);
        }
        let replacer = (key, value) => typeof(value) === Function ? undefined : value;
        return jsyaml.dump(variables, { replacer });
      } catch (ex) {
        return ex.message;
      }
    },
    types() {
      return this.schema !== null
        ? _(this.schema.types)
          .sortBy((type) => type.name)
          .map((type) => [type.name, type])
          .fromPairs()
          .value()
        : {};
    },
    keywords() {
      const score = 0;
      let keywords = [];
      for (let stream of this.streams) {
        keywords.push({ value: stream.name, score, meta: 'stream' });
        for (let column of stream.columns) {
          keywords.push({ value: column.name, score, meta: stream.name });
        }
        for (let link of stream.links) {
          keywords.push({ value: link.linkName, score, meta: `@${stream.name}` });
        }
        for (let func of stream.funcs) {
          keywords.push({ value: func.name, score, meta: stream.name });
        }
      }
      for (let report of this.reports) {
        keywords.push({ value: report.name, score, meta: 'report' });
        for (let link of report.links) {
          keywords.push({ value: link.linkName, score, meta: `@${report.name}` });
        }
        for (let func of report.funcs) {
          keywords.push({ value: func.name, score, meta: report.name });
        }
      }
      keywords = _(keywords)
        .groupBy('value')
        .values()
        .map((keywords) => _.assign({}, keywords[0], { meta: _(keywords).map('meta').join(', ') }))
        .value();
      return keywords;
    },
    streams() {
      return this.schema?.streams || [];
    },
    reports() {
      return this.schema?.reports || [];
    },
    isEditable() {
      return this.selectedAsset.name.endsWith('.yml')
            || this.selectedAsset.name.endsWith('.md')
            || this.selectedAsset.name.endsWith('.js')
            || this.selectedAsset.name.endsWith('.css')
            || this.selectedAsset.name.endsWith('.json');
    },
    isImage() {
      return this.selectedAsset.name.endsWith('.png')
            || this.selectedAsset.name.endsWith('.svg')
            || this.selectedAsset.name.endsWith('.jpg')
            || this.selectedAsset.name.endsWith('.jpeg');
    },
    getModeByAssetType() {
      switch (true) {
        case this.selectedAsset.name.endsWith('.yml'):
          return 'yaml';
        case this.selectedAsset.name.endsWith('.md'):
          return 'markdown';
        case this.selectedAsset.name.endsWith('.js'):
          return 'javascript';
        case this.selectedAsset.name.endsWith('.css'):
          return 'css';
        case this.selectedAsset.name.endsWith('.json'):
          return 'json';
        default:
          return 'plain_text';
      }
    },
  },
  watch: {
    path() {
      this.loadAssets();
    },
    assets() {
      if (!this.selectedAsset && localStorage.selectedAsset) {
        this.selectedAsset = this.assets.find((asset) => asset.link === localStorage.selectedAsset);
      }
    },
    variablesRoot() {
      localStorage.variablesRoot = this.variablesRoot;
    },
    variablesSearch() {
      localStorage.variablesSearch = this.variablesSearch;
    },
    selectedStream() {
      localStorage.selectedStream = this.selectedStream?.name;
      localStorage.selectedReport = this.selectedReport?.name;
    },
    selectedReport() {
      localStorage.selectedStream = this.selectedStream?.name;
      localStorage.selectedReport = this.selectedReport?.name;
    },
    schema() {
      if (localStorage.selectedStream) {
        this.selectedStream = this.streams
          .find((stream) => stream.name === localStorage.selectedStream);
      }

      if (localStorage.selectedReport) {
        this.selectedReport = this.reports
          .find((report) => report.name === localStorage.selectedReport);
      }
    },
    selectedAsset: {
      deep: true,
      handler(asset) {
        if (asset) {
          localStorage.selectedAsset = asset.link;
          const unsavedAssetKey = this.unsavedAssetKey(asset);
          if (asset.data === undefined) {
            if (localStorage[unsavedAssetKey]) {
              this.$set(asset, 'data', localStorage[unsavedAssetKey]);
            }
            const loadAsset = async () => {
              const data = await (await fetch(asset.link)).text();
              if (asset.data === undefined) {
                this.$set(asset, 'data', data);
              }
              this.$set(asset, '_data', data);
            };
            loadAsset();
          } else if (asset._data !== undefined) {
            if (asset.data !== asset._data) {
              localStorage[unsavedAssetKey] = asset.data;
            } else {
              delete localStorage[unsavedAssetKey];
            }
          }
        } else {
          delete localStorage.selectedAsset;
        }
      },
    },
    editTab() {
      localStorage.editTab = this.editTab;
      this.$set(this.visitedTabs, this.editTab, true);
      switch (this.editTab) {
        case 'template':
          Vue.nextTick(() => this.$refs.templateEditor.editor?.focus());
          break;
        case 'elements':
          Vue.nextTick(() => this.$refs.elementsEditor.editor?.focus());
          break;
        default: break;
      }
    },
  },
  methods: {
    unsavedAssetKey(asset) {
      return `unsaved_${_.snakeCase(asset.link)}`;
    },
    tryDismissAsset() {
      this.selectedAsset = null;
    },
    async submitAssetChanges(asset) {
      const formData = new FormData();
      formData.append('file', new File([asset.data], asset.name));

      await fetch(this.path, { method: 'POST', body: formData });
      Vue.set(asset, '_data', asset.data);
      Vue.set(asset, 'size', asset.data.length);

      if (!this.assets.includes(asset)) {
        this.assets.push(asset);
      }

      this.$emit('change:asset', asset);
    },
    discardAssetChanges(asset) {
      Vue.set(asset, 'data', asset._data);
    },
    createAsset() {
      const name = prompt('Please enter a new asset name');
      let asset = this.assets?.find((existAsset) => existAsset.name === name);
      if (!asset) {
        asset = {
          name,
          link: this.path + name,
          size: 0,
          data: '',
          _data: '',
        };
      }
      this.selectedAsset = asset;
    },
    deleteAsset(asset) {
      if (window.confirm(utils.l10n('Are you sure you want to delete {asset}?').replace('{asset}', asset.name))) {
        this.assets.splice(this.assets.indexOf(asset), 1);
        $.ajax({
          url: asset.link,
          method: 'DELETE',
        });
      }
    },
    async uploadAssets(e) {
      const formData = new FormData();
      for (let file of e.dataTransfer.files) {
        formData.append('file', file);
      }

      await fetch(this.path, {
        method: 'POST',
        body: formData,
      });

      this.loadAssets();
    },
    formatSize(size) {
      return size < 100 ? size : d3.format('.3s')(size);
    },
    getCompletions(editor, session, pos, prefix, callback) {
      callback(null, this.keywords);
    },
    handleKeyDown(e) {
      if (e.ctrlKey) {
        switch (e.key) {
          case '1': this.editTab = 'template'; break;
          case '2': this.editTab = 'elements'; break;
          case '3': this.editTab = 'sources'; break;
          case '4': this.editTab = 'assets'; break;
          case '5': this.editTab = 'versions'; break;
          case '6': this.editTab = 'sitemap'; break;
          default: break;
        }
      }
      if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
        if (this.selectedAsset) {
          this.submitAssetChanges(this.selectedAsset);
          e.preventDefault();
        }
        if (this.editTab === 'template' || this.editTab === 'elements') {
          e.preventDefault();
        }
      }
    },
    async loadAssets() {
      const { path } = this;
      const query = `
                query {
                    page(path:${utils.quote_string(path.slice(7))}) {
                        assets {
                            name
                            link
                            size
                        }
                    }
                }`;
      const { data: { page: { assets } } } = await utils.fetchWithAjaxOpts({
        url: '/graphql?id=page&__loadAssets__',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      });
      if (path === this.path) {
        this.assets = assets.filter((asset) => !asset.name.startsWith('.'));
      }
    },
    async loadSchema() {
      const query = `
                query {
                    dataset {
                        streams {
                            __items {
                                name
                                size
                                paused
                                managed
                                description
                                columns { name type synonym gqlName indexed }
                                links { linkName }
                                funcs { name }
                            }
                        }
                        reports {
                            name
                            managed
                            description
                            links { linkName }
                            funcs { name }
                        }
                    }
                }`;
      const { streams: { __items: streams }, reports } = (await (await fetch('/graphql?id=schema&__loadSchema__', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      })).json()).data.dataset;
      this.schema = {
        streams: _.sortBy(streams, 'name'),
        reports: _.sortBy(reports, 'name'),
      };
    },
  },
};
</script>

<style>
.my-settings {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
}
.my-settings .form-group {
    margin-top: 4px;
    padding: 6px 15px;
}
.my-settings .form-group:first-child {
    margin-top: 0;
}
.my-settings .form-group:last-child {
    margin-bottom: 0;
}
.my-light-theme .my-settings:hover {
    outline: 1px solid gray;
    background-color: white;
}
.my-dark-theme .my-settings:hover {
    outline: 1px solid gray;
    background-color: black;
}
.my-settings:hover .feather-icon-menu {
    display:none;
}
.my-settings:not(:hover) .form-group {
    display:none;
}
.my-settings:not(:hover) #my-page-warning > span:last-child {
    display: none;
}
.my-settings .feather-icon,
.my-settings .feather-icon svg {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
}
.my-sources-view .gp-check.form-check-inline {
    margin-right: 0;
    transform: translate(0, 1px);
}
.my-sources-view td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.my-design-panel {
    min-width: 400px;
    flex-basis: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.my-design-panel .nav {
    padding-right: 40px;
}
.my-design-panel .btn-sm .feather-icon {
    margin: -2px -4px;
    display: inline-block;
    vertical-align: top;
}
.my-design-panel > *:last-child {
    flex-grow: 1;
}
.my-light-theme .my-design-panel .ace_editor,
.my-light-theme .my-design-panel .my-sources-view,
.my-light-theme .my-design-panel .my-assets-view,
.my-light-theme .my-design-panel .my-versions-view,
.my-light-theme .my-design-panel .my-sitemap-view,
.my-light-theme .my-design-panel .my-variables-view {
    border-left: 1px solid rgb(222, 226, 230);
}
.my-dark-theme .my-design-panel .ace_editor,
.my-dark-theme .my-design-panel .my-sources-view,
.my-dark-theme .my-design-panel .my-assets-view,
.my-dark-theme .my-design-panel .my-versions-view,
.my-dark-theme .my-design-panel .my-sitemap-view,
.my-dark-theme .my-design-panel .my-variables-view {
    border-left: 1px solid rgb(68, 68, 68);
}
.my-versions-view,
.my-sources-view,
.my-sitemap-view {
    padding: 15px;
    overflow: auto;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
}
.my-variables-view {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.my-variables-view .ace_editor {
    flex-grow: 1
}
.my-variables-view .form-group {
    margin: 0;
    display: flex;
    z-index: 1;
}
.my-variables-view .form-control {
    flex-basis: 1px;
    flex-grow: 1;
    border-radius: 0;
    margin-left: -1px;
}
.my-variables-view .form-control:focus {
    z-index: 1;
}
.my-sources-view .feather-icon-external-link svg,
.my-sources-view .feather-icon-refresh-cw svg {
    width: 18px;
    color: var(--cyan);
}
.my-sources-view h5 {
    margin-top: 5px;
    margin-bottom: 20px;
}
.my-sources-view h5 > a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    color: #222;
}
.my-dark-theme .my-sources-view h5 > a {
    color: white;
}
.my-sources-view h5 > a:hover {
    text-decoration: none;
}
.my-sources-view h5 > a.active:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 2px;
    background: var(--cyan);
}
.my-sources-view h5 > a:not(.active) {
    cursor: pointer;
}
.my-sources-view table th,
.my-sources-view table td {
    font-size: 14px!important;
    line-height: 20px!important;
    padding: 1px 8px;
}
.my-sources-view .gp-stream table th,
.my-sources-view .gp-stream table td,
.my-sources-view .gp-report table th,
.my-sources-view .gp-report table td {
    font-size: 13px!important;
    line-height: 19px!important;
    padding: 1px 4px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.my-sources-view table tr:hover {
  background: rgb(233,242,250);
}
.my-dark-theme .my-sources-view table tr:hover {
  background: #283139;
}
.my-dark-theme .my-sources-view {
    background-color: #191919;
}
.my-dark-theme .nav-link.active {
     background-color: #191919!important;
}
.nav-item small {
    position: absolute;
    margin-left: 4px;
    margin-top: 1px;
    transform: scaley(0.9);
}

@media (max-width: 700px) {
    .my-design-panel {
        min-width: 100%;
    }
}
.my-design-panel .nav-link {
    padding: 0.5rem 1.75rem;
}
@media (max-width: 400px) {
    .my-design-panel .nav-link {
        padding: 0.5rem 1.5rem;
    }
}
.my-assets-view {
    flex-grow: 1;
    display: flex;
    padding: 15px;
    flex-direction: column;
}
.my-assets-view .btn svg {
    margin: -2px;
    display: inline-block;
}
.my-selected-asset-head {
    display: flex;
    align-items: center;
    margin-right: -10px;
    margin-bottom: 15px;
}
.my-selected-asset-head > * {
    margin-right: 10px;
}
.my-selected-asset-head h3 {
    margin: 0;
}
.my-selected-asset-head .btn {
    line-height: 1.2;
}
.my-assets-view .table {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
}
.my-assets-view .table td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.my-assets-view .table td:nth-child(1) {
    width: 80%;
}
.my-assets-view .table td:nth-child(2) {
    text-align: right;
}
.my-assets-view svg {
    width: 18px;
    height: 18px;
    transform: translate(0, -1px);
}
.my-assets-view .ace_editor {
    flex-grow: 1;
    margin: -15px;
    margin-top: 0;
    width: calc(100% + 30px)!important;
    border-top: 1px solid var(--light);
}
.drop-zone {
    padding: 15px;
    text-align: center;
    display: block;
    margin-top: 15px;
    background-color: var(--light);
    border: 1px dotted var(--dark);
}
.drop-zone.highlight {
    outline: 2px solid var(--cyan);
    border-color: transparent;
}
</style>
