var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-attributes" },
    [
      _vm.entries.length && _vm.show
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "pim-attributes-head" },
                [
                  _vm.validFields.length != 0
                    ? [
                        _c(
                          "a",
                          {
                            ref: "showColumnSelector",
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.showColumnSelector = true
                              }
                            }
                          },
                          [_c("feather-icon", { attrs: { name: "menu" } })],
                          1
                        ),
                        _vm._v(" "),
                        _vm.loading
                          ? _c(
                              "a",
                              { attrs: { href: "javascript:void(0)" } },
                              [
                                _c("feather-icon", { attrs: { name: "clock" } })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h2", [_vm._v("Attributes")]),
                  _vm._v(" "),
                  _vm.entries.length > 1
                    ? _c("p", [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.capitalize(_vm.entries[_vm.cursor].name)
                            ) +
                            "\n        "
                        ),
                        _vm.workflowStatus
                          ? _c(
                              "span",
                              { attrs: { "data-status": _vm.workflowStatus } },
                              [
                                _vm._v(
                                  "\n          – " +
                                    _vm._s(_vm.workflowStatus) +
                                    ",\n        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n        and " +
                            _vm._s(
                              Number(_vm.entries.length - 1).toLocaleString()
                            ) +
                            "\n        more " +
                            _vm._s(
                              _vm.pluralize(_vm.entries[_vm.cursor].type)
                            ) +
                            "\n      "
                        )
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.capitalize(_vm.entries[_vm.cursor].type)
                            ) +
                            " " +
                            _vm._s(_vm.entries[_vm.cursor].name) +
                            "\n        "
                        ),
                        _vm.workflowStatus
                          ? _c(
                              "span",
                              { attrs: { "data-status": _vm.workflowStatus } },
                              [
                                _vm._v(
                                  "\n          – " +
                                    _vm._s(_vm.workflowStatus) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                  _vm._v(" "),
                  _vm.validFields.length != 0
                    ? [
                        _vm.localesInUse.length
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.locale,
                                    expression: "locale"
                                  }
                                ],
                                ref: "locales",
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.locale = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("All locales")
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.localesInUse, function(locale) {
                                  return _c(
                                    "option",
                                    {
                                      key: locale.id,
                                      domProps: { value: locale.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(locale.name) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.storefrontsInUse.length
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.storefront,
                                    expression: "storefront"
                                  }
                                ],
                                ref: "storefronts",
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.storefront = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("All storefronts")
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.storefrontsInUse, function(
                                  storefront
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: storefront.id,
                                      domProps: { value: storefront.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(storefront.name) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "gp-check",
                          {
                            model: {
                              value: _vm.showOnlyAiFields,
                              callback: function($$v) {
                                _vm.showOnlyAiFields = $$v
                              },
                              expression: "showOnlyAiFields"
                            }
                          },
                          [
                            _vm._v("\n          Show fields with "),
                            _c("em", [_vm._v("ai")]),
                            _vm._v(" suggesions only\n        ")
                          ]
                        ),
                        _vm._v(" "),
                        _c("my-search", {
                          model: {
                            value: _vm.searchString,
                            callback: function($$v) {
                              _vm.searchString = $$v
                            },
                            expression: "searchString"
                          }
                        })
                      ]
                    : _c("p", [
                        _vm._v("\n        There are no attributes available."),
                        _c("br"),
                        _vm._v(
                          "\n        Try to switch your function group.\n      "
                        )
                      ])
                ],
                2
              ),
              _vm._v(" "),
              _vm.hasImages && _vm.entries.length > 1
                ? _c(
                    "div",
                    { staticClass: "pim-attributes-image-tiles" },
                    _vm._l(_vm.entries, function(entry) {
                      return _c(
                        "div",
                        {
                          key: entry.id,
                          staticClass: "pim-attributes-image-tile",
                          class: { focused: entry == _vm.focus },
                          attrs: {
                            status: (_vm.status =
                              _vm.workflowStatuses[entry.id])
                          }
                        },
                        [
                          _vm.status
                            ? _c(
                                "span",
                                { attrs: { "data-status": _vm.status } },
                                [_vm._v(_vm._s(_vm.status))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAIResults(entry)
                            ? _c(
                                "span",
                                {
                                  staticClass: "pim-attributes-image-tile-ai",
                                  attrs: {
                                    "data-status": _vm.hasAISuggestions(entry)
                                      ? "ai-different"
                                      : "ai-same"
                                  }
                                },
                                [_vm._v("ai")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            attrs: { src: entry.image, alt: entry.name },
                            on: {
                              click: function($event) {
                                return _vm.$emit("focus", entry)
                              }
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasImages && _vm.assetsEntry
                ? _c(
                    "div",
                    { staticClass: "pim-attributes-image-tiles" },
                    _vm._l(_vm.assetsEntry.children || [], function(entry) {
                      return _c(
                        "div",
                        {
                          key: entry.id,
                          staticClass: "pim-attributes-image-tile"
                        },
                        [
                          entry.attrs.sequence
                            ? _c(
                                "span",
                                { staticClass: "pim-tree-tile-sequence" },
                                [_vm._v(_vm._s(entry.attrs.sequence))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          entry.attrs.is_primary_image
                            ? _c(
                                "span",
                                { staticClass: "pim-tree-tile-primary" },
                                [_vm._v("primary")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            attrs: { src: entry.image, alt: entry.name }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.visibleGroups, function(fields, group) {
                return _c(
                  "div",
                  {
                    key: group,
                    staticClass: "pim-attributes-fields",
                    attrs: { "data-length": fields.length }
                  },
                  [
                    fields.length > 1
                      ? _c("h3", [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.toggleGroup(group)
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  name: _vm.isGroupOpen(group)
                                    ? "book-open"
                                    : "book"
                                }
                              }),
                              _vm._v(
                                "\n          " + _vm._s(group) + "\n        "
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    fields.length == 1 || _vm.isGroupOpen(group)
                      ? _vm._l(fields, function(ref) {
                          var field = ref.item
                          var matches = ref.matches
                          var formattedName = ref.formattedName
                          return _c(
                            "div",
                            { key: field.id, staticClass: "form-group" },
                            [
                              field.field_type == "boolean"
                                ? _c(
                                    "gp-check",
                                    {
                                      attrs: {
                                        checked: _vm.getValue(field),
                                        required: _vm.isRequired(field),
                                        disabled: _vm.isFieldReadonly(field)
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setValue(field, $event)
                                        }
                                      }
                                    },
                                    [
                                      formattedName
                                        ? _vm._l(formattedName, function(part) {
                                            return _c(
                                              "span",
                                              {
                                                class: { matched: part.matched }
                                              },
                                              [_vm._v(_vm._s(part.text))]
                                            )
                                          })
                                        : [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(field.name) +
                                                "\n            "
                                            )
                                          ],
                                      _vm._v(" "),
                                      _vm.isRequired(field)
                                        ? _c(
                                            "span",
                                            { staticClass: "required" },
                                            [
                                              _vm._v(
                                                "\n              [required]\n            "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isOverriden(field)
                                        ? _c(
                                            "span",
                                            { staticClass: "overridden" },
                                            [
                                              _vm._v(
                                                "\n              [overridden]\n              "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setValue(
                                                        field,
                                                        null
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: { name: "x" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm.isComputed(field)
                                        ? _c(
                                            "span",
                                            { staticClass: "computed" },
                                            [
                                              _vm._v(
                                                "\n              [computed]\n            "
                                              )
                                            ]
                                          )
                                        : _vm.isDerived(field)
                                        ? _c(
                                            "span",
                                            { staticClass: "derived" },
                                            [
                                              _vm._v(
                                                "\n              [derived]\n            "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isEdited(field)
                                        ? _c(
                                            "span",
                                            { staticClass: "edited" },
                                            [
                                              _vm._v(
                                                "\n              [edited]\n              "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$delete(
                                                        _vm.changes,
                                                        field.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: { name: "x" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm.isMixed(field)
                                        ? _c("span", { staticClass: "mixed" }, [
                                            _vm._v(
                                              "\n              [mixed]\n            "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : [
                                    _c(
                                      "label",
                                      [
                                        formattedName
                                          ? _vm._l(formattedName, function(
                                              part
                                            ) {
                                              return _c(
                                                "span",
                                                {
                                                  class: {
                                                    matched: part.matched
                                                  }
                                                },
                                                [_vm._v(_vm._s(part.text))]
                                              )
                                            })
                                          : [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(field.name) +
                                                  "\n              "
                                              )
                                            ],
                                        _vm._v(" "),
                                        _vm.isRequired(field)
                                          ? _c(
                                              "span",
                                              { staticClass: "required" },
                                              [
                                                _vm._v(
                                                  "\n                [required]\n              "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isOverriden(field)
                                          ? _c(
                                              "span",
                                              { staticClass: "overridden" },
                                              [
                                                _vm._v(
                                                  "\n                [overridden]\n                "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setValue(
                                                          field,
                                                          null
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: { name: "x" }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm.isComputed(field)
                                          ? _c(
                                              "span",
                                              { staticClass: "computed" },
                                              [
                                                _vm._v(
                                                  "\n                [computed]\n              "
                                                )
                                              ]
                                            )
                                          : _vm.isDerived(field)
                                          ? _c(
                                              "span",
                                              { staticClass: "derived" },
                                              [
                                                _vm._v(
                                                  "\n                [derived]\n              "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isEdited(field)
                                          ? _c(
                                              "span",
                                              { staticClass: "edited" },
                                              [
                                                _vm._v(
                                                  "\n                [edited]\n                "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.$delete(
                                                          _vm.changes,
                                                          field.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: { name: "x" }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm.isMixed(field)
                                          ? _c(
                                              "span",
                                              { staticClass: "mixed" },
                                              [
                                                _vm._v(
                                                  "\n                [mixed]\n              "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.entry &&
                                    _vm.romanceCopyField == field.api_name &&
                                    _vm.romanceCopyReport[_vm.entry.id] &&
                                    _vm.romanceCopyReport[_vm.entry.id].result
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pim-attributes-ai"
                                            },
                                            [
                                              _c("em", [_vm._v("ai")]),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                _vm._l(
                                                  _vm.romanceCopyReport[
                                                    _vm.entry.id
                                                  ].result.choices,
                                                  function(choice) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        class:
                                                          _vm.getValue(field) ==
                                                          choice.text
                                                            ? "ai-same"
                                                            : "ai-different"
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.setValue(
                                                                  field,
                                                                  choice.text
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                name: "check"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.discardRomanceCopyChoice(
                                                                  choice
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                name: "x"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              choice.text
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      [
                                        _vm.entries[_vm.cursor] &&
                                          _vm.classifyReport &&
                                          _vm.classifyReport[
                                            _vm.entries[_vm.cursor].image
                                          ] &&
                                          _vm.classifyReport[
                                            _vm.entries[_vm.cursor].image
                                          ][field.api_name]
                                      ],
                                      function(classification) {
                                        return classification
                                          ? [
                                              _vm._l(
                                                [
                                                  _vm.dropdownOptions &&
                                                    _vm.dropdownOptions[
                                                      field.id
                                                    ] &&
                                                    _vm.dropdownOptions[
                                                      field.id
                                                    ].find(function(option) {
                                                      return (
                                                        classification.name ==
                                                        option.code
                                                      )
                                                    })
                                                ],
                                                function(option) {
                                                  return option
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pim-attributes-ai",
                                                            attrs: {
                                                              "data-status":
                                                                option.code ==
                                                                _vm.getValue(
                                                                  field
                                                                )
                                                                  ? "ai-same"
                                                                  : "ai-different"
                                                            }
                                                          },
                                                          [
                                                            _c("em", [
                                                              _vm._v("ai")
                                                            ]),
                                                            _vm._v(" "),
                                                            option.code ==
                                                            _vm.getValue(field)
                                                              ? _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "check"
                                                                    }
                                                                  }
                                                                )
                                                              : _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        "javascript:void(0)"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.setValue(
                                                                          field,
                                                                          option.code
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "feather-icon",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "check"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                            _vm._v(
                                                              "\n                  " +
                                                                _vm._s(
                                                                  option.name
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("sup", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  Number(
                                                                    Math.round(
                                                                      classification.rank *
                                                                        10
                                                                    ) / 10
                                                                  ).toLocaleString()
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            option.code !=
                                                            _vm.getValue(field)
                                                              ? _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        "javascript:void(0)"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$delete(
                                                                          _vm
                                                                            .classifyReport[
                                                                            _vm
                                                                              .entries[
                                                                              _vm
                                                                                .cursor
                                                                            ]
                                                                              .image
                                                                          ],
                                                                          field.api_name
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "feather-icon",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "x"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm._e()
                                                }
                                              )
                                            ]
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._v(" "),
                                    field.field_type == "datetime"
                                      ? _c("input", {
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            type: "datetime-local",
                                            required: _vm.isRequired(field),
                                            readonly: _vm.isFieldReadonly(field)
                                          },
                                          domProps: {
                                            value: _vm.getValue(field)
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setValue(
                                                field,
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    field.field_type == "date"
                                      ? _c("input", {
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            type: "date",
                                            required: _vm.isRequired(field),
                                            readonly: _vm.isFieldReadonly(field)
                                          },
                                          domProps: {
                                            value: _vm.getValue(field)
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setValue(
                                                field,
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      : field.field_type == "textarea"
                                      ? _c("editor", {
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            init: _vm.editorInit(field),
                                            "api-key": _vm.editorApiKey,
                                            "initial-value": _vm.getValue(
                                              field
                                            ),
                                            value: _vm.getValue(field),
                                            disabled: _vm.isFieldReadonly(
                                              field
                                            ),
                                            "model-events": "change input"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.setValue(field, $event)
                                            }
                                          }
                                        })
                                      : field.field_type == "alphanumeric"
                                      ? _c("editor", {
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            init: _vm.editorInit(field),
                                            "api-key": _vm.editorApiKey,
                                            "initial-value": _vm.getValue(
                                              field
                                            ),
                                            value: _vm.getValue(field),
                                            disabled: _vm.isFieldReadonly(
                                              field
                                            ),
                                            "output-format": "text",
                                            "model-events": "change input"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.setValue(field, $event)
                                            }
                                          }
                                        })
                                      : field.field_type == "decimal" ||
                                        field.field_type == "numeric"
                                      ? _c("input", {
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            type: "number",
                                            required: _vm.isRequired(field),
                                            readonly: _vm.isFieldReadonly(field)
                                          },
                                          domProps: {
                                            value: _vm.getValue(field)
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.setValue(
                                                field,
                                                parseFloat($event.target.value)
                                              )
                                            }
                                          }
                                        })
                                      : field.field_type == "dropdown" ||
                                        field.field_type == "multidropdown"
                                      ? _c("pim-select", {
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            multiple:
                                              field.field_type ==
                                              "multidropdown",
                                            required: _vm.isRequired(field),
                                            readonly: _vm.isFieldReadonly(
                                              field
                                            ),
                                            value: _vm.getValue(field),
                                            options:
                                              _vm.dropdownOptions[field.id] ||
                                              []
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setValue(field, $event)
                                            }
                                          }
                                        })
                                      : field.field_type == "json" &&
                                        !_vm.isComputed(field)
                                      ? _c("ace-editor", {
                                          attrs: {
                                            mode: "json",
                                            autoHeight: true,
                                            value: _vm.getValue(field) || "",
                                            readOnly: _vm.isFieldReadonly(
                                              field
                                            ),
                                            darkTheme: _vm.darkTheme
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setValue(field, $event)
                                            }
                                          }
                                        })
                                      : _c("input", {
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            required: _vm.isRequired(field),
                                            readonly: _vm.isFieldReadonly(field)
                                          },
                                          domProps: {
                                            value: _vm.getValue(field)
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setValue(
                                                field,
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                  ]
                            ],
                            2
                          )
                        })
                      : _vm._e()
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "pim-hierarchies-attributes-stats" }, [
                _vm.valuesReport
                  ? _c("div", [
                      _vm._v(
                        "scalar attributes retrieved in " +
                          _vm._s(
                            _vm.valuesReport.stats.prepareTime +
                              _vm.valuesReport.stats.processTime
                          ) +
                          " ms"
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.dropdownReport
                  ? _c("div", [
                      _vm._v(
                        "dropdown attributes retrieved in " +
                          _vm._s(
                            _vm.dropdownReport.stats.prepareTime +
                              _vm.dropdownReport.stats.processTime
                          ) +
                          " ms"
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.derivedReport
                  ? _c("div", [
                      _vm._v(
                        "derived attributes computed in " +
                          _vm._s(
                            _vm.derivedReport.stats.prepareTime +
                              _vm.derivedReport.stats.processTime
                          ) +
                          " ms"
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.validFields.length != 0
                ? _c(
                    "div",
                    { staticClass: "pim-hierarchies-attributes-actions" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-primary",
                          attrs: { disabled: !_vm.attributesChanged },
                          on: { click: _vm.submitChanges }
                        },
                        [_vm._v("\n        Save changes\n      ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-secondary",
                          attrs: { disabled: !_vm.attributesChanged },
                          on: { click: _vm.discardChanges }
                        },
                        [_vm._v("\n        Discard changes\n      ")]
                      ),
                      _vm._v(" "),
                      _vm.workflowStatus && !_vm.mixedWorkflowStatuses
                        ? [
                            _vm.workflowStatus == "Rejected" ||
                            _vm.workflowStatus == "In Progress"
                              ? _c(
                                  "button",
                                  { staticClass: "btn btn-sm btn-secondary" },
                                  [
                                    _vm._v(
                                      "\n          Submit for approval\n        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.workflowStatus == "Completed"
                              ? _c(
                                  "button",
                                  { staticClass: "btn btn-sm btn-secondary" },
                                  [_vm._v("\n          Approve\n        ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.workflowStatus == "Completed"
                              ? _c(
                                  "button",
                                  { staticClass: "btn btn-sm btn-secondary" },
                                  [_vm._v("\n          Reject\n        ")]
                                )
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showColumnSelector
                ? _c(
                    "my-popup",
                    { attrs: { anchor: _vm.$refs.showColumnSelector } },
                    [
                      _c("div", { staticClass: "popover" }, [
                        _c(
                          "div",
                          { staticClass: "popover-body" },
                          [
                            _c("pim-columns", {
                              ref: "columns",
                              attrs: {
                                type: _vm.entries[0].type,
                                fields: _vm.validFields,
                                columns: _vm.columns
                              },
                              on: {
                                submit: function($event) {
                                  _vm.showColumnSelector = false
                                  _vm.columns = $event
                                },
                                cancel: function($event) {
                                  _vm.showColumnSelector = false
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.fields && _vm.entries.length && _vm.valuesConfig.stream
        ? _c("gp-data", {
            ref: "valuesReport",
            attrs: {
              stream: _vm.valuesConfig.stream,
              filter1: _vm.valuesConfig.filter1,
              filter2: _vm.valuesConfig.filter2,
              filter3: _vm.valuesConfig.filter3,
              dims: _vm.valuesConfig.dims,
              vals: _vm.valuesConfig.vals,
              cols: _vm.valuesConfig.cols,
              initialSort: _vm.valuesConfig.sort,
              expand: _vm.valuesConfig.expand,
              throttled: false
            },
            on: {
              reportId: function($event) {
                _vm.valuesReportId = $event
              }
            },
            model: {
              value: _vm.valuesReport,
              callback: function($$v) {
                _vm.valuesReport = $$v
              },
              expression: "valuesReport"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fields && _vm.entries.length && _vm.dropdownConfig.stream
        ? _c("gp-data", {
            ref: "dropdownReport",
            attrs: {
              stream: _vm.dropdownConfig.stream,
              source: _vm.dropdownConfig.source,
              filter1: _vm.dropdownConfig.filter1,
              filter2: _vm.dropdownConfig.filter2,
              filter3: _vm.dropdownConfig.filter3,
              dims: _vm.dropdownConfig.dims,
              vals: _vm.dropdownConfig.vals,
              cols: _vm.dropdownConfig.cols,
              initialSort: _vm.dropdownConfig.sort,
              expand: _vm.dropdownConfig.expand,
              throttled: false
            },
            on: {
              reportId: function($event) {
                _vm.dropdownReportId = $event
              }
            },
            model: {
              value: _vm.dropdownReport,
              callback: function($$v) {
                _vm.dropdownReport = $$v
              },
              expression: "dropdownReport"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fields
        ? _c("gp-data", {
            ref: "optionsReport",
            attrs: {
              stream: _vm.optionsConfig.stream,
              source: _vm.optionsConfig.source,
              filter1: _vm.optionsConfig.filter1,
              filter2: _vm.optionsConfig.filter2,
              filter3: _vm.optionsConfig.filter3,
              dims: _vm.optionsConfig.dims,
              vals: _vm.optionsConfig.vals,
              cols: _vm.optionsConfig.cols,
              initialSort: _vm.optionsConfig.sort,
              expand: _vm.optionsConfig.expand,
              throttled: false
            },
            on: {
              reportId: function($event) {
                _vm.optionsReportId = $event
              }
            },
            model: {
              value: _vm.optionsReport,
              callback: function($$v) {
                _vm.optionsReport = $$v
              },
              expression: "optionsReport"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fields
        ? _c("gp-data", {
            ref: "derivedReport",
            attrs: {
              stream: _vm.derivedConfig.stream,
              source: _vm.derivedConfig.source,
              filter1: _vm.derivedConfig.filter1,
              filter2: _vm.derivedConfig.filter2,
              filter3: _vm.derivedConfig.filter3,
              dims: _vm.derivedConfig.dims,
              vals: _vm.derivedConfig.vals,
              cols: _vm.derivedConfig.cols,
              initialSort: _vm.derivedConfig.sort,
              expand: _vm.derivedConfig.expand,
              throttled: false
            },
            on: {
              reportId: function($event) {
                _vm.derivedReportId = $event
              }
            },
            model: {
              value: _vm.derivedReport,
              callback: function($$v) {
                _vm.derivedReport = $$v
              },
              expression: "derivedReport"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }