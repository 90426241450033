var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-version" }, [
    _c("span", [_vm._v(_vm._s(_vm.clientVersion.slice(0, _vm.length)))]),
    _vm._v(" "),
    _vm.actualVersion && _vm.actualVersion != _vm.clientVersion
      ? _c(
          "a",
          {
            staticClass: "alert alert-info",
            attrs: { href: "#" },
            on: { click: _vm.update }
          },
          [
            _c("l10n", {
              attrs: {
                value:
                  "New version is available. Please refresh the page to apply changes."
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }