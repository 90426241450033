<template>
  <div
    :class="{
      'gp-support': true,
      'gp-support-ready': ready,
      'gp-support-expanded': expanded,
      'gp-support-online': online,
      'gp-support-shown': shown,
    }">
    <a href="javascript:void(0)" @click="handleClick">
      <feather-icon name="message-square" />
      <l10n value="Chat with support" />
    </a>
  </div>
</template>
<script>
module.exports = {
  props: {
    config: { type: Object },
  },
  data() {
    const ready = window._chatlio_ready || false;
    const expanded = ready && _chatlio.isExpanded();
    const online = ready && _chatlio.isOnline();
    const shown = ready && _chatlio.isShown();
    return {
      ready, expanded, online, shown,
    };
  },
  mounted() {
    if (!window._chatlio) {
      this.initChatlio();
    }
    document.addEventListener('chatlio.ready', this.handleReady);
    document.addEventListener('chatlio.firstMessageSent', this.handleFirstMessageSent);
    document.addEventListener('chatlio.messageSent', this.handleMessageSent);
    document.addEventListener('chatlio.messageReceived', this.handleMessageReceived);
    document.addEventListener('chatlio.preChatInfoSubmitted', this.handlePreChatInfoSubmitted);
    document.addEventListener('chatlio.visibilityChange', this.handleVisibilityChange);
    document.addEventListener('chatlio.collapsed', this.handleCollapsed);
    document.addEventListener('chatlio.expanded', this.handleExpanded);
    document.addEventListener('chatlio.trigger', this.handleTrigger);
    document.addEventListener('chatlio.offline', this.handleOffline);
    document.addEventListener('chatlio.online', this.handleOnline);
    document.addEventListener('chatlio.conversationEnded', this.handleConversationEnded);
  },
  beforeDestroy() {
    document.removeEventListener('chatlio.ready', this.handleReady);
    document.removeEventListener('chatlio.firstMessageSent', this.handleFirstMessageSent);
    document.removeEventListener('chatlio.messageSent', this.handleMessageSent);
    document.removeEventListener('chatlio.messageReceived', this.handleMessageReceived);
    document.removeEventListener('chatlio.preChatInfoSubmitted', this.handlePreChatInfoSubmitted);
    document.removeEventListener('chatlio.visibilityChange', this.handleVisibilityChange);
    document.removeEventListener('chatlio.collapsed', this.handleCollapsed);
    document.removeEventListener('chatlio.expanded', this.handleExpanded);
    document.removeEventListener('chatlio.trigger', this.handleTrigger);
    document.removeEventListener('chatlio.offline', this.handleOffline);
    document.removeEventListener('chatlio.online', this.handleOnline);
    document.removeEventListener('chatlio.conversationEnded', this.handleConversationEnded);
  },
  methods: {
    async handleClick(e) {
      if (this.ready) {
        if (!this.shown) {
          const name = app.username;
          const permalink = await gptable.createPermalink();
          const logs = `/grafana/explore?orgId=1&left=%5B%22now-12h%22,%22now%22,%22Loki%22,%7B%22expr%22:%22%7Bjob%3D%5C%22olap-rust%5C%22%7D%20%7C%3D%20%5C%22${app.session}%5C%22%22%7D%5D`;
          const remote = 'https://remotejs.com/';
          const params = {
            name, permalink, logs, remote,
          };
          _chatlio.identify(name, params);
        }
        _chatlio.showOrHide();
      }
    },
    handleReady(e) {
      window._chatlio_ready = this.ready = true;
      $('.chatlio-widget-body').draggable({
        handle: '.chatlio-chat-header',
      });
      $('.chatlio-widget-body').resizable({
        handles: 'n, e, s, w, se',
        start: (e, ui) => {
          const el = $(ui.originalElement);
          let { top, left } = el.offset();
          top -= 10;
          left -= 10;
          el.css({
            left, top, right: 'none', bottom: 'none', position: 'fixed',
          });
        },
      });
    },
    handleFirstMessageSent(e) {

    },
    handleMessageSent(e) {

    },
    handleMessageReceived(e) {
      const match = e.data.body.match(
        /^(<pre>)?([a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+)(<\/pre>)?$/,
      );
      if (match) {
        const script = document.createElement('script');
        script.src = 'https://remotejs.com/agent/agent.js';
        script.dataset.consolejsChannel = match[2];
        document.body.appendChild(script);
      }
    },
    handlePreChatInfoSubmitted(e) {

    },
    handleVisibilityChange(e) {

    },
    handleCollapsed(e) {
      this.expanded = false;
    },
    handleExpanded(e) {
      this.expanded = true;
    },
    handleTrigger(e) {

    },
    handleOffline(e) {
      this.online = false;
    },
    handleOnline(e) {
      this.online = true;
    },
    handleConversationEnded(e) {

    },
    initChatlio() {
      window._chatlio = window._chatlio || [];
      !(function () {
        const t = document.getElementById('chatlio-widget-embed');
        if (t && window.ChatlioReact && _chatlio.init) {
          return void _chatlio.init(t, ChatlioReact);
        }
        for (let e = function (t) {
            return function () {
              _chatlio.push([t].concat(arguments));
            };
          }, i = ['configure', 'identify', 'track', 'show', 'hide', 'isShown', 'isOnline', 'page', 'open', 'showOrHide'], a = 0; a < i.length; a++) {
          _chatlio[i[a]] || (_chatlio[i[a]] = e(i[a]));
        }
        const n = document.createElement('script');
        const c = document.getElementsByTagName('script')[0];
        n.id = 'chatlio-widget-embed', n.src = 'https://w.chatlio.com/w.chatlio-widget.js', n.async = !0, n.setAttribute('data-embed-version', '2.3');
        n.setAttribute('data-widget-id', 'd6f9629f-6104-48b5-6202-a46e0cfb89db');
        c.parentNode.insertBefore(n, c);
      }());
      if (this.config) {
        window._chatlio.configure(this.config);
      }
    },
  },
};
</script>
<style>
.gp-support svg {
    width: 20px;
}
.gp-support {
    padding-right: 10px;
    margin-right: 10px;
    position: relative;
    font-size: 14px;
    white-space: nowrap;
}
.gp-support:after {
    content: "";
    position: absolute;
    right: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    background-color: var(--red);
    border-radius: 50%;
}
.gp-support-online:after {
    background-color: var(--green);
}
.chatlio-widget-body {
    border: 1px solid gray;
}
.chatlio-title-bar {
    display: none!important;
}
.chatlio-file-picker-trigger {
    cursor: pointer;
}
</style>
