var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    {
      class: _vm.classes,
      style: _vm.style,
      attrs: {
        title: _vm.title,
        "data-column": _vm.column.i,
        "data-type": _vm.column.type,
        "data-name": _vm.column.name
      }
    },
    [
      _vm.column.actionable &&
      _vm.actionicon &&
      _vm.actionicon !== "noicon" &&
      !_vm.noAction
        ? _c(
            "a",
            {
              staticClass: "my-colum-action",
              attrs: { href: "javascript:void(0)" }
            },
            [_c("feather-icon", { attrs: { name: _vm.actionicon } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _vm.column.actionable && !_vm.actionicon && !_vm.noAction && !_vm.totals
        ? _c("span", [
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _vm._v("\n      " + _vm._s(_vm.formattedValue) + "\n    ")
            ])
          ])
        : _vm.actionlink
        ? _c("span", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.actionlink,
                  target: _vm.actionlink.startsWith("http") ? "_blank" : ""
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.formattedValue) + "\n    ")]
            )
          ])
        : _vm.column.type == "html"
        ? _c("span", { domProps: { innerHTML: _vm._s(_vm.formattedValue) } })
        : _vm.isLink && !_vm.noLink
        ? _c("span", [
            _c("a", { attrs: { href: _vm.value, target: "_blank" } }, [
              _vm._v("\n      " + _vm._s(_vm.formattedValue) + "\n    ")
            ])
          ])
        : _c("span", [_vm._v(_vm._s(_vm.formattedValue))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }