<template>
  <div class="pim-tree-chart">
    <pie-chart
      v-if="chartType == 'pie-chart'"
      ref="chart"
      stream="default"
      :dims="dims"
      :vals="vals"
      :provider="provider"
      :throttled="false"
      :actions="actions"
      :colors="colors"
    />
    <bar-chart
      v-else-if="chartType == 'bar-chart'"
      ref="chart"
      stream="default"
      :dims="dims"
      :vals="vals"
      :provider="provider"
      :throttled="false"
      :actions="actions"
      :colors="colors"
      x="d3.scaleBand()"
      xUnits="dc.units.ordinal"
      :elasticY="true"
      :marginTop="marginTop"
      :marginLeft="marginLeft"
      :marginRight="marginRight"
      :marginBottom="marginBottom"
      :height="height"
      :xTickAngle="xTickAngle"
    />
    <bubble-chart
      v-else-if="chartType == 'bubble-chart'"
      ref="chart"
      stream="default"
      :dims="dims"
      :vals="vals"
      :provider="provider"
      :throttled="false"
      :actions="actions"
      :colors="colors"
      :elasticX="true"
      :elasticY="true"
      :elasticRadius="true"
      :sortBubbleSize="true"
      :radiusValueAccessor="d => d.value[2] || 1"
      :colorAccessor="d => d.value[3] || 'blue'"
    />
    <scatter-chart
      v-else-if="chartType == 'scatter-chart'"
      ref="chart"
      stream="default"
      :dims="dims"
      :vals="vals"
      :provider="provider"
      :throttled="false"
      :actions="actions"
      :colors="colors"
    />
    <gp-chart
      v-else-if="chartType == 'time-series' && config"
      stream="combined"
      ref="chart"
      :metrics="config.metrics"
      :attributes="config.attributes"
      :formulas="config.formulas"
      :formats="config.formats"
      :timeframes="config.timeframes"
      :height="300"
      :elasticX="true"
      :elasticY="true"
      :marginTop="60"
      :marginLeft="60"
      :marginRight="80"
      :marginBottom="50"
      yAxisPadding="10%"
      :yTicks="5"
      :xTicks="10"
      :rightYTicks="5"
      :xTickAngle="-45"
      x="d3.scaleTime()"
      xUnits=" d3.timeDay"
      round="d3.timeDay.round"
      xTickFormat="utils.multiFormatDate"
      yTickFormat="d3.format('.2s')"
      rightYTickFormat="d3.format('$.2s')"
      :rightYAxisFormats="['value']"
      :brushOn="false"
      title="(d) => d.value[0]"
      :renderVerticalGridLines="true"
      :renderHorizontalGridLines="true"
      :clipPadding="10"
      :transitionDuration="0"
      notBefore="2021-01-01"
      notAfter="2022-12-31"
      :renderLegend="false"
      colors="d3.scaleOrdinal(d3.schemeTableau10)"
      :filter2="filter2"
    />
    <pim-attributes-ex
      ref="attributes"
      :show="false"
      :entries="entries"
      :fields="attributesFields"
      @change="attributes = $event"
    />
  </div>
</template>
<script>
module.exports = {
  mixins: [
    require('./pim-helper.js'),
  ],
  props: {
    config: { type: Object },
    entry: { type: Object },
    entries: { type: Array },
    fields: { type: Array },
  },
  data() {
    const { type } = this.entries[0];
    return {
      columns: JSON.parse(localStorage[`pim-columns-${type}`] || '[]'),
      attributes: {},
      showColumnSelector: false,
      colors: d3.scaleOrdinal(d3.schemeTableau10),
    };
  },
  mounted() {
    window.chart = this;
  },
  watch: {
    entries() {
      this.colors = d3.scaleOrdinal(d3.schemeTableau10);
    },
    columns() {
      localStorage[`pim-columns-${this.type}`] = JSON.stringify(this.columns);
    },
    rows() {
      this.$refs.chart.requestData({ forced: true });
    },
  },
  methods: {
    provider(req, table) {
      const data = {
        dataset: {
          source: {
            report: {
              size: this.rows.length,
              rows: this.rows,
              stats: {},
              totals: {},
              columns: _.concat(this.dims, this.vals),
            },
          },
        },
      };
      const meta = {
        stream: 'default',
        columns: _.concat(this.dims, this.vals),
        dims: this.dims,
        vals: this.vals,
        cols: [],
        args: {},
      };
      return { meta, data };
    },
  },
  computed: {
    skus() {
      if (this.type == 'sku') {
        return [this.entry.id];
      }
      if (this.childrenType == 'sku') {
        return this.entry.children.map((child) => child.id);
      }
      return [];
    },
    filter2() {
      if (this.skus.length == 0) {
        return undefined;
      }
      return `sku.id in ${utils.quote(this.skus)}`;
    },
    xTickAngle() {
      return -45;
    },
    marginTop() {
      return this.vals.length * 15 + 20;
    },
    marginLeft() {
      return 50;
    },
    marginRight() {
      return 10;
    },
    marginBottom() {
      return this.maxNameLength * 4 + 10;
    },
    height() {
      return 200 + this.marginTop + this.marginBottom;
    },
    maxNameLength() {
      return _(this.entries).map((entry) => entry.name.length - this.commonPrefix.length).max();
    },
    commonPrefix() {
      if (this.entries.length <= 1) {
        return '';
      }
      let commonPrefix;
      for (const entry of this.entries) {
        if (commonPrefix === undefined) {
          commonPrefix = entry.name;
        } else {
          while (!entry.name.startsWith(commonPrefix)) {
            commonPrefix = commonPrefix.slice(0, commonPrefix.length - 1);
          }
        }
        if (!commonPrefix) {
          break;
        }
      }
      return commonPrefix.length > 5 ? commonPrefix : '';
    },
    chartType() {
      return this.entry.chartType || 'pie-chart';
    },
    actions() {
      return [{
        name: 'Set as a context',
        call: (data) => {
          const entry = this.entries.find((entry) => entry.name == this.commonPrefix + data.key[0]);
          if (entry) {
            this.$emit('context', entry);
          }
        },
      }, {
        name: 'Open in new tab',
        call: (data) => {
          const entry = this.entries.find((entry) => entry.name == this.commonPrefix + data.key[0]);
          if (entry) {
            this.$emit('open-in-tab', entry);
          }
        },
      }];
    },
    attributesFields() {
      const ids = new Set(_.map(this.columns, 'id'));
      return this.fields.filter((field) => ids.has(field.id));
    },
    type() {
      return this.entries[0].type;
    },
    childrenType() {
      return this.entries[0]?.childrenType || this.classify(this.type);
    },
    dims() {
      const dims = [];
      dims.push({
        name: _.capitalize(this.type),
        calc: (entry) => entry.name.slice(this.commonPrefix.length),
      });
      return dims;
    },
    /*
                row.push(entry.name.slice(this.commonPrefix.length))
                row.push(entry.children?.length || 0)
                for (let metric of this.metrics(this.type, this.childrenType)) {
                    row.push({
                        name: metric.name,
                        calc: metric.calc,
                    })
                }
                for (let column of this.columns) {
                    let attribute = this.attributes[column.id]
                    let value = attribute ? attribute.vals[row.i] : '-'
                    row.push(value)
                }
*/
    vals() {
      const vals = [];
      vals.push({
        name: `Number of ${this.pluralize(this.childrenType)}`,
        calc: (entry) => entry.children?.length || 0,
      });
      for (const metric of this.metrics(this.type, this.childrenType)) {
        vals.push({
          name: metric.name,
          calc: metric.calc,
        });
      }
      for (const column of this.columns) {
        const field = this.fields.find((field) => field.id == column.id);
        vals.push({
          name: column.name,
          calc: (entry) => {
            const attribute = this.attributes[column.id];
            return attribute ? attribute.vals[row.i] : '-';
          },
          editable:
                        field
                        && !field.is_readonly
                        && field.editable !== false
                        && (!field.is_derived || field.overridable),
          format: (x) => (x || '-'),
        });
      }
      return vals;
    },
    hasImage() {
      return this.entries.some((entry) => entry.image);
    },
    hasDesc() {
      return this.entries.some((entry) => entry.desc);
    },
    rows() {
      return Object.freeze(this.entries.map((entry, i) => {
        const row = [];
        row.i = i;
        row.id = entry.id;
        row.__cache = {};
        for (const dim of this.dims) {
          row.push(dim.calc(entry));
        }
        for (const val of this.vals) {
          row.push(val.calc(entry));
        }
        return Object.freeze(row);
      }));
    },
  },
};
</script>
