var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pim-field" }, [
    _c("div", { staticClass: "pim-field-move" }, [
      _c(
        "a",
        { attrs: { href: "javascript:void(0)" } },
        [_c("feather-icon", { attrs: { name: "arrow-up" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        { attrs: { href: "javascript:void(0)" } },
        [_c("feather-icon", { attrs: { name: "arrow-down" } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Name")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.field.field_name,
            expression: "field.field_name"
          }
        ],
        staticClass: "form-control form-control-sm",
        domProps: { value: _vm.field.field_name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.field, "field_name", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Type")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.field.field_type,
              expression: "field.field_type"
            }
          ],
          staticClass: "form-control form-control-sm",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.field,
                "field_type",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            }
          }
        },
        [
          _c("option", { attrs: { value: "alphanumeric" } }, [
            _vm._v("alphanumeric")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "boolean" } }, [_vm._v("boolean")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "date" } }, [_vm._v("date")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "datetime" } }, [_vm._v("datetime")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "decimal" } }, [_vm._v("decimal")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "dropdown" } }, [_vm._v("dropdown")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "json" } }, [_vm._v("json")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "multidropdown" } }, [
            _vm._v("multidropdown")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "numeric" } }, [_vm._v("numeric")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "textarea" } }, [_vm._v("textarea")])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Description")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.field.description,
            expression: "field.description"
          }
        ],
        staticClass: "form-control form-control-sm",
        domProps: { value: _vm.field.description },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.field, "description", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _vm.field.has_locales
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Locales")]),
            _vm._v(" "),
            _vm._l(_vm.locales, function(locale) {
              return _c(
                "gp-check",
                {
                  key: locale.id,
                  attrs: { checked: _vm.field.locale_id == locale.id }
                },
                [_vm._v("\n      " + _vm._s(locale.name) + "\n    ")]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.field.has_storefronts
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Storefronts")]),
            _vm._v(" "),
            _vm._l(_vm.storefronts, function(storefront) {
              return _c(
                "gp-check",
                {
                  key: storefront.id,
                  attrs: { checked: _vm.field.storefront_id == storefront.id }
                },
                [_vm._v("\n      " + _vm._s(storefront.name) + "\n    ")]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.field.is_derived
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Computation Type")]),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                attrs: {
                  type: "radio",
                  name: "derive_type",
                  checked: _vm.computationType == "inherit"
                },
                on: {
                  click: function($event) {
                    return _vm.$set(_vm.field, "derive_type", "inherit")
                  }
                }
              },
              [_vm._v("\n      Inherit\n    ")]
            ),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                attrs: {
                  type: "radio",
                  name: "derive_type",
                  checked: _vm.computationType == "aggregate",
                  disabled:
                    _vm.field.field_type == "alphanumeric" ||
                    _vm.field.field_type == "textarea" ||
                    _vm.field.field_type == "json" ||
                    _vm.field.field_type == "dropdown" ||
                    _vm.field.field_type == "multidropdown"
                },
                on: {
                  click: function($event) {
                    return _vm.$set(_vm.field, "derive_type", "aggregate")
                  }
                }
              },
              [_vm._v("\n      Aggregate\n    ")]
            ),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                attrs: {
                  type: "radio",
                  name: "derive_type",
                  checked: _vm.computationType == "expression"
                },
                on: {
                  click: function($event) {
                    return _vm.$set(_vm.field, "derive_type", "expression")
                  }
                }
              },
              [_vm._v("\n      Expression\n    ")]
            ),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                attrs: {
                  type: "radio",
                  name: "derive_type",
                  checked: _vm.computationType == "template"
                },
                on: {
                  click: function($event) {
                    return _vm.$set(_vm.field, "derive_type", "template")
                  }
                }
              },
              [_vm._v("\n      Template\n    ")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.computationType == "inherit"
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Parent field")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.field.inherit_field,
                  expression: "field.inherit_field"
                }
              ],
              staticClass: "form-control form-control-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.field,
                    "inherit_field",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            _vm._l(_vm.fields, function(field) {
              return _c(
                "option",
                { attrs: { if: "field.id" }, domProps: { value: field.id } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(field.description || field.name) +
                      "\n      "
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.computationType == "aggregate"
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Children field")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.field.aggregate_field,
                  expression: "field.aggregate_field"
                }
              ],
              staticClass: "form-control form-control-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.field,
                    "aggregate_field",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            _vm._l(_vm.fields, function(field) {
              return _c(
                "option",
                { attrs: { if: "field.id" }, domProps: { value: field.id } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(field.description || field.name) +
                      "\n      "
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.computationType == "aggregate"
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Aggregation function")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.field.aggregate_function,
                  expression: "field.aggregate_function"
                }
              ],
              staticClass: "form-control form-control-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.field,
                    "aggregate_function",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _vm.field.field_type == "decimal" ||
              _vm.field.field_type == "numeric"
                ? [
                    _c("option", { attrs: { value: "sum" } }, [_vm._v("Sum")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "min" } }, [
                      _vm._v("Minimum")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "max" } }, [
                      _vm._v("Maximum")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "avg" } }, [
                      _vm._v("Average")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "med" } }, [
                      _vm._v("Median")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "mod" } }, [_vm._v("Mode")])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.field.field_type == "boolean"
                ? [
                    _c("option", { attrs: { value: "all" } }, [_vm._v("All")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "any" } }, [_vm._v("Any")])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.field.field_type == "date" ||
              _vm.field.field_type == "datetime"
                ? [
                    _c("option", { attrs: { value: "min" } }, [_vm._v("Min")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "max" } }, [_vm._v("Max")])
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.computationType == "expression"
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Expression")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.field.derived_expr,
                expression: "field.derived_expr"
              }
            ],
            staticClass: "form-control form-control-sm",
            domProps: { value: _vm.field.derived_expr },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.field, "derived_expr", $event.target.value)
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.computationType == "template"
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Template")]),
            _vm._v(" "),
            _c("ace-editor", {
              attrs: {
                autoHeight: true,
                mode: "ruby",
                value: (_vm.template && _vm.template.template_file_data) || "",
                darkTheme: _vm.darkTheme
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }