<template>
  <div>
    <div v-for="error in errors" class="alert alert-warning">
      <template v-if="error.message">
        {{error.message}}
      </template>
      <template v-else-if="error.responseJSON && error.responseJSON.errors">
        {{error.responseJSON.errors[0].message}}
      </template>
      <template v-else>
        {{JSON.stringify(error)}}
        <a href="javascript:void(0)" @click="onRetry" class="float-right">
          retry
        </a>
      </template>
    </div>
  </div>
</template>
<script>
module.exports = {
  props: {
    errors: { type: Array },
  },
  methods: {
    onRetry: function() {
      this.$emit('retry');
    },
  },
};
</script>
