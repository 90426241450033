var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-filters" },
    [
      _vm._l(_vm.visibleFilters, function(filter) {
        return _c(
          "a",
          {
            class: _vm.filterClass(filter),
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.removeFilter(filter)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.formatFilter(filter)) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _vm.showMoreLink
        ? _c("span", [
            _vm._v("\n    and\n    "),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.showAll = true
                  }
                }
              },
              [_vm._v(_vm._s(_vm.value.length - _vm.visibleFilters.length))]
            ),
            _vm._v("\n    more\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showClearLink
        ? _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.removeAllFilters }
            },
            [_vm._v("clear all")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.value.length == 0
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.wasFiltered,
                  expression: "wasFiltered"
                }
              ],
              staticClass: "text-muted badge"
            },
            [_vm._v("\n    no filters applied\n  ")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }