<template>
  <div class="my-caption" v-if="caption"><l10n :value="caption"/></div>
</template>
<script>
module.exports = {
  props: {
    caption: { type: String },
  },
};
</script>
<style>
.my-caption {
    float: left;
    font-weight: 500;
    font-size: 1.171875rem;
    margin-bottom: 4px;
}
</style>
