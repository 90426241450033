<template>
  <my-dialog
    title="Add new type"
    v-if="show"
    @close="closeForm"
  >
    <div class="inputblock">
      <label
        class="inputblock__label"
        for="typeOfProduction"
      >
        Type of production
      </label>
      <input
        v-model="typeOfProduction"
        class="inputblock__input input"
        :class="{ input_invalid: tryToSendForm && !typeOfProduction }"
        id="typeOfProduction"
      />
    </div>

    <div class="buttons-block">
      <button
        type="button"
        class="button button_primary"
        :disabled="hasEmptyField && tryToSendForm"
        :class="{ button_disabled: hasEmptyField && tryToSendForm }"
        @click="onCreateNewType"
      >
        <l10n value="Save" />
      </button>
      <button
        type="button"
        class="button button_secondary"
        @click="closeForm"
      >
        <l10n value="Cancel" />
      </button>
    </div>
  </my-dialog>
</template>

<script>
const utils = require('../my-utils');

module.exports = {
  props: {
    isAddNewTypeModalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    targetYear: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      typeOfProduction: '',
      tryToSendForm: false,
    };
  },

  computed: {
    hasEmptyField() {
      return !this.typeOfProduction;
    },
  },

  watch: {
    isAddNewTypeModalVisible(newValue) {
      this.show = newValue;
    },
  },

  methods: {
    localizeValue(value) {
      return utils.l10n(value);
    },

    cleanForm() {
      this.typeOfProduction = '';
    },

    async onCreateNewType() {
      if (this.hasEmptyField) {
        this.tryToSendForm = true;
        this.createNotification('Form has an empty fields', 'error');
        return;
      }

      const stream = 'PP_type_of_production';
      const queryBody = [{
        item: this.typeOfProduction,
        target_year: this.targetYear,
        date: '2023-01-01',
      }];
      const query = `
        mutation {
          appendRecords(
              stream: ${utils.quote(stream)},
              format: ${utils.quote('json')},
              records: ${utils.quote(JSON.stringify(queryBody))}
            )
        }`;

      await fetch('/graphql?__createNewType__', {
        method: 'POST',
        body: JSON.stringify({ query }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result?.data && result?.data?.appendRecords) {
            this.createNotification('New type has been added successfully', 'success');
            this.closeForm();
            this.$emit('on-new-type-successful-added');
          }
        });

      utils.bridge.trigger('streamModified', stream);
    },

    closeForm() {
      this.cleanForm();
      this.$emit('add-new-type-modal-close');
    },
  },
};
</script>

<style scoped>
.input {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 350px;
  height: 46px;
  margin: 0;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.input:hover {
  background-color: #E9E9E9;
  cursor: pointer;
}
.input:focus {
  border-color: #5295D5;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
  box-shadow: 0 0 0 0.2rem #5295D5;
}
.input_invalid {
  background-color: #FAE6EB;
}
.inputblock {
  margin-bottom: 16px;
}
.inputblock__label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
}
.buttons-block {
  display: flex;
  justify-content: space-around;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 46px;
  border: none;
  border-radius: 8px;
  transition: background-color .3s ease-in-out;
}
.button_primary {
  background-color: #5295D5;
  color: #ffffff;
}
.button_secondary {
  background-color: #F7F7F7;
  color: #000000;
}
.button_primary:not(.button_disabled):hover {
  background-color: #3C80C1;
  transition: background-color .3s ease-in-out;
}
.button_secondary:hover {
  background-color: #E9E9E9;
  transition: background-color .3s ease-in-out;
}
.button_disabled {
  background-color: #F7F7F7;
  color: #D4D4D4;
  cursor: default;
}
</style>
