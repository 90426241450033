<template>
  <div class="gauge-chart">
    <my-errors :errors="errors" @retry="requestData"/>

    <my-caption :caption="caption"/>

    <my-progress
      :key="reportId"
      :progress="progress"
      :processRows="processRows"
      :processTime="processTime"
      :meta="meta"
      :exportToFile="exportToFile"
      :assembly="assembly"/>

    <my-filters v-model="filters" :chart="null"/>

    <my-download :file="file"/>

    <my-tooltip
      ref="tooltip"
      :meta="meta"
      :keepOnly="keepOnly"
      :exclude="exclude"/>

    <div class="gauge-chart-segments" :style="{height}">
      <div class="gauge-chart-segment" v-for="d in segments" :style="style(d)" :title="title(d)" :key="d.key.join('\n')">
      </div>
    </div>

    <ul class="gauge-chart-legend" v-if="renderLegend">
      <li class="gauge-chart-legend-item" v-for="d in segments" :key="d.key.join('\n')" @mouseenter="hoveredSegment = d" @mouseleave="hoveredSegment = null">
        <span :style="{'background-color': color(d)}"/>
        {{d.key.join(", ")}}
      </li>
    </ul>
  </div>
</template>

<script>
let utils = require('./my-utils');
module.exports = {
  mixins: [
    require('./data.js'),
  ],
  props: {
    caption: { type: String },
    keyAccessor: { type: [Function, String], default: (d) => d.key.join('\n') },
    valueAccessor: { type: [Function, String], default: (d) => d.value[0] },
    title: { type: [Function, String], default: (d) => `${d.key.join(', ')}\n${d.value.join('\n')}` },
    ordering: { type: [Function, String], default: (d) => d.key[0] },
    colors: { type: [Function, String], default: d3.scaleOrdinal(d3.schemeCategory10) },
    height: { type: Number, default: 20 },
    renderLegend: { type: Boolean, default: false },
  },
  data() {
    return {
      hoveredSegment: null,
    };
  },
  methods: {
    keepOnly() {},
    exclude() {},
    color(d) {
      let value = this._valueAccessor(d);
      return this._colors(this._keyAccessor(d));
    },
    style(d) {
      let value = this._valueAccessor(d);
      return {
        'background-color': this.color(d),
        'flex-basis': value / this.total * 100 + '%',
        'opacity': this.hoveredSegment === null || this.hoveredSegment === d ? 1 : 0.2,
      };
    },
    convertFunction(func, def) {
      if (_.isFunction(func)) {
        return func;
      }
      if (_.isString(func)) {
        try {
          return eval(func);
        } catch (ex) {
          console.warn(ex);
        }
      }
      return def;
    },
  },
  computed: {
    _keyAccessor() {
      return this.convertFunction(this.keyAccessor, (d) => d.key.join('\n'));
    },
    _valueAccessor() {
      return this.convertFunction(this.valueAccessor, (d) => d.value[0]);
    },
    _ordering() {
      return this.convertFunction(this.ordering, (d) => d.key[0]);
    },
    _title() {
      return this.convertFunction(this.title, (d) => `${d.key.join(',')}: ${d.value.join(',')}`);
    },
    _colors() {
      return this.convertFunction(this.colors, d3.scaleOrdinal(d3.schemeCategory10));
    },
    total() {
      return _(this.segments).map(this.valueAccessor).sum();
    },
    filters() {
      return [];
    },
    segments() {
      if (this.report == null) {
        return [];
      } else {
        return _(this.report.rows)
          .map((row) => ({
            key: row.slice(0,this.dims.length),
            value: row.slice(this.dims.length) }))
          .sortBy(this._ordering)
          .value();
      }
    },
  },
};
</script>
<style>
.gauge-chart-segments {
    display: flex;
    max-width: 100%;
    margin-right: -1px;
}
.gauge-chart-segment {
    height: 20px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 1px;
    transition: flex-basis 750ms;
}
.gauge-chart .feather-icon-download {
    display: none;
}
.gauge-chart:hover .feather-icon-download {
    display: block;
}
ul.gauge-chart-legend {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 18px;
    font-size: 11px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -5px;
    margin-right: -5px;
}
ul.gauge-chart-legend > li {
    padding: 0 5px;
}
ul.gauge-chart-legend > li > *:first-child {
    display: inline-block;
    width: 12px;
    height: 2px;
    vertical-align: top;
    margin-top: 8px;
    margin-right: 2px;
}
</style>
