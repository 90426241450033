<template>
  <v-calendar
    ref="calendar"
    show-weeknumbers
    @weeknumberclick="onWeekdayclick"
    @weekdayclick="onWeekdayclick"
    :attributes="attributes"
    @dayclick="onDayClick"
  />
</template>

<script>
let utils = require('./my-utils');
let VCalendar = require('v-calendar').default;

module.exports = {
  mixins: [VCalendar],
  mounted() {
    this.$el.querySelectorAll('.vc-pane .vc-weekday:not(:first-child)').forEach((x, i) => {
      x.addEventListener('click', event => this.onWeekdayclick({
        i,
        days: this.$refs.calendar.pages[0].days.filter(
          d => d.weekdayPosition === i + 1,
        ),
        event,
      }));
    });
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      days: this.value.map(x => ({
        id: utils.formatDate(x),
        date: x,
      })),
    };
  },
  computed: {
    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  methods: {
    onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
      this.$emit('input', this.days.map(x => x.date));
    },
    onWeekdayclick(info) {
      let allDaysAreSelected = true;
      info.days.forEach(day => {
        let idx = this.days.findIndex(d => d.id === day.id);
        if (idx < 0 && day.inMonth) {
          this.days.push({
            id: day.id,
            date: day.date,
          });
          allDaysAreSelected = false;
        }
      });
      if (allDaysAreSelected) {
        info.days.forEach(day => {
          let idx = this.days.findIndex(d => d.id === day.id);
          if (idx >= 0) {
            this.days.splice(idx, 1);
          }
        });
      }
      this.$emit('input', this.days.map(x => x.date));
    },
  },
};
</script>
