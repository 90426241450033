var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-grid" },
    [
      _c(
        "a",
        {
          staticClass: "gp-grid-add",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addItem.apply(null, arguments)
            }
          }
        },
        [_c("feather-icon", { attrs: { name: "plus" } })],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "grid-layout",
        {
          attrs: {
            "col-num": _vm.colNum,
            "row-height": _vm.rowHeight,
            "is-draggable": true,
            "is-resizable": true,
            responsive: _vm.responsive,
            margin: [15, 15],
            "vertical-compact": _vm.verticalCompact,
            "use-css-transforms": _vm.useCssTransforms
          },
          on: {
            "layout-created": _vm.layoutCreatedEvent,
            "layout-before-mount": _vm.layoutBeforeMountEvent,
            "layout-mounted": _vm.layoutMountedEvent,
            "layout-ready": _vm.layoutReadyEvent,
            "layout-updated": _vm.layoutUpdatedEvent
          },
          model: {
            value: _vm.layout,
            callback: function($$v) {
              _vm.layout = $$v
            },
            expression: "layout"
          }
        },
        _vm._l(_vm.layout, function(item) {
          return _c(
            "grid-item",
            {
              key: item.i,
              attrs: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                i: item.i,
                "data-i": item.i,
                tabindex: "0",
                "drag-allow-from": ".grid-item-head",
                "drag-ignore-from": ".grid-item-body"
              },
              on: {
                resize: _vm.resizeEvent,
                move: _vm.moveEvent,
                resized: _vm.resizedEvent,
                "container-resized": _vm.containerResizedEvent,
                moved: _vm.movedEvent
              }
            },
            [
              _c("div", { staticClass: "grid-item-head" }, [
                _c("h2", [_c("l10n", { attrs: { value: item.name } })], 1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.showItemMenu = item
                      }
                    }
                  },
                  [_c("feather-icon", { attrs: { name: "more-vertical" } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-item-body" },
                [
                  _vm.flippedItems[item.i]
                    ? _c("div", [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.flipItem(item)
                              }
                            }
                          },
                          [
                            _c("table", { staticClass: "grid-item-settings" }, [
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _c(
                                      "label",
                                      [
                                        _c("l10n", { attrs: { value: "Name" } })
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("input", {
                                      staticClass: "form-control form-control",
                                      domProps: { value: item.name },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            item,
                                            "name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _c(
                                      "label",
                                      [
                                        _c("l10n", { attrs: { value: "Path" } })
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("input", {
                                      staticClass: "form-control form-control",
                                      domProps: { value: item.path },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            item,
                                            "path",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                staticStyle: { "text-align": "right" }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.flipItem(item)
                                      }
                                    }
                                  },
                                  [_c("l10n", { attrs: { value: "Done" } })],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : item.type == "page"
                    ? _c("gp-page", {
                        key: _vm.refreshKeys[item.i],
                        attrs: {
                          path: item.path,
                          darkTheme: _vm.darkTheme,
                          config: _vm.config,
                          username: _vm.username,
                          bounds: _vm.bounds,
                          storageKey: _vm.storageKey
                            ? _vm.storageKey + "." + item.i
                            : null
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.showItemMenu
        ? _c(
            "my-popup",
            {
              attrs: {
                placement: "bottom-center",
                anchor:
                  '.gp-grid .vue-grid-item[data-i="' +
                  _vm.showItemMenu.i +
                  '"] .grid-item-head a'
              },
              on: {
                escape: function($event) {
                  _vm.showItemMenu = null
                },
                clickoutside: function($event) {
                  _vm.showItemMenu = null
                }
              }
            },
            [
              _c("div", { staticClass: "popover show gp-grid-menu" }, [
                _c("div", { staticClass: "popover-body" }, [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.refreshItem(_vm.showItemMenu)
                              _vm.showItemMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "refresh-cw" } }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Refresh" } })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.showItemMenu.path.startsWith("http")
                              ? _vm.showItemMenu.path
                              : "/pages/" + _vm.showItemMenu.path,
                            target: "_blank"
                          },
                          on: {
                            click: function($event) {
                              _vm.showItemMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { name: "external-link" }
                          }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Open" } })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.deleteItem(_vm.showItemMenu)
                              _vm.showItemMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "x" } }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Remove" } })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.flipItem(_vm.showItemMenu)
                              _vm.showItemMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "settings" } }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Settings" } })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showGridMenu
        ? _c(
            "my-popup",
            {
              attrs: {
                placement: "bottom-center",
                anchor: _vm.$refs.gridMenuLink
              },
              on: {
                escape: function($event) {
                  _vm.showGridMenu = false
                },
                clickoutside: function($event) {
                  _vm.showGridMenu = false
                }
              }
            },
            [
              _c("div", { staticClass: "popover show gp-grid-menu" }, [
                _c("div", { staticClass: "popover-body" }, [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.addItem()
                              _vm.showGridMenu = false
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "plus" } }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Add new widget" } })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }