const component = require('../../input/select.vue').default;

module.exports = ({
  multiple = false,
} = {}) => {
  const valueToInput = (x) => {
    if (multiple) {
      return x ?? [];
    }
    return x ?? '';
  };
  const inputToValue = (x) => x;
  const display = (x) => {
    if (_.isArray(x)) {
      return x.join(', ');
    }
    return `${x ?? ''}`;
  };

  return {
    component,
    options: {},
    multiple,
    valueToInput,
    inputToValue,
    display,
  };
};
