var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c(
        "div",
        {
          ref: "paginator",
          staticClass: "pim-tree-paginator",
          class: { focused: _vm.entry.focused },
          style: { "margin-left": _vm.level * 20 - 4 + "px" }
        },
        [
          _c(
            "a",
            {
              class: { disabled: _vm.entry.page <= 1 },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.$set(_vm.entry.entry, "page", _vm.entry.page - 1)
                }
              }
            },
            [_c("feather-icon", { attrs: { name: "chevron-left" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v(
                "\n        " + _vm._s(_vm.l10n(_vm.entry.size)) + "\n        "
              ),
              _vm.entry.size > 1
                ? [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.pluralize(_vm.entry.entry.childrenType)) +
                        "\n        "
                    )
                  ]
                : [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.entry.entry.childrenType) +
                        "\n        "
                    )
                  ],
              _vm._v("\n        total,\n      ")
            ],
            2
          ),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n        page\n        "),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.promptPage(_vm.entry)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.l10n(_vm.entry.page)))]
            ),
            _vm._v(
              "\n        of " + _vm._s(_vm.l10n(_vm.entry.pages)) + ",\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.promptPageSize(_vm.entry)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.l10n(_vm.entry.take)))]
            ),
            _vm._v(
              "\n        " +
                _vm._s(_vm.pluralize(_vm.classify(_vm.entry.entry.type))) +
                " per page\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { disabled: _vm.entry.page >= _vm.entry.pages },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.$set(_vm.entry.entry, "page", _vm.entry.page + 1)
                }
              }
            },
            [_c("feather-icon", { attrs: { name: "chevron-right" } })],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }