var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "week-select-container" }, [
    _c(
      "label",
      { attrs: { for: "select-target-week" } },
      [_c("l10n", { attrs: { value: "Select week number" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.weekNumber,
            expression: "weekNumber"
          }
        ],
        staticClass: "form-control form-control-sm dropdown-select week-select",
        attrs: { id: "select-target-week" },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.weekNumber = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onSelectWeek
          ]
        }
      },
      [
        _c("option", { attrs: { value: "", disabled: "", selected: "" } }, [
          _vm._v("\n      " + _vm._s(_vm.placeholder10n) + "\n    ")
        ]),
        _vm._v(" "),
        _vm._l(_vm.optionItems, function(item) {
          return _c(
            "option",
            { key: item, domProps: { value: item } },
            [_c("l10n", { attrs: { value: item } })],
            1
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }