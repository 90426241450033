<template>
  <div class="my-login-form">
    <p>
      <l10n value="Authorization Required" />
    </p>

    <div
      v-if="canLoginWithPIN"
      class="form-group"
    >
      <a
        :href="`/miracl?state=${returnPath}`"
        class="btn btn-sm btn-primary"
      >
        <l10n value="Login with PIN" />
      </a>
    </div>

    <div
      v-if="canLoginWithPIN && canLoginWithPassword"
      class="form-group"
    >
      <l10n value="OR" />
    </div>

    <template v-if="canLoginWithPassword">
      <div class="form-group">
        <input
          ref="username"
          v-model="username"
          required
          autofocus
          spellcheck="false"
          autocorrect="off"
          autocapitalize="off"
          :placeholder="l10n('user name')"
          :disabled="busy"
          :class="{
            'form-control': true,
            'is-valid': passed,
          }"
          @keydown="usernameKeyDown"
        >
      </div>

      <div class="form-group">
        <input
          ref="password"
          v-model="password"
          required
          :placeholder="l10n('password')"
          :disabled="busy"
          :class="{
            'form-control': true,
            'is-valid': passed,
          }"
          type="password"
          @keydown="passwordKeyDown"
        >
      </div>

      <button
        v-if="!passed"
        class="btn btn-sm btn-primary"
        type="button"
        :disabled="!canLogin"
        @click="login"
      >
        <l10n v-if="!busy" value="Submit" />
        <l10n v-else value="Submitting..." />
      </button>

      <div
        v-if="failed"
        class="invalid-feedback"
        style="display:block"
      >
        <l10n value="Wrong user name or password" />
      </div>
    </template>
  </div>
</template>

<script>
const utils = require('./my-utils');

module.exports = {
  data() {
    return {
      l10n: utils.l10n,
      username: '',
      password: '',
      busy: false,
      passed: false,
      failed: false,
      isCanLoginWithPassword: false,
      isCanLoginWithPIN: false,
    };
  },

  watch: {
    username() {
      this.failed = false;
    },
    password() {
      this.failed = false;
    },
  },

  computed: {
    canLogin() {
      return !this.busy && !this.passed && this.username !== '' && this.password !== '';
    },

    canLoginWithPassword() {
      return true;
    },

    canLoginWithPIN() {
      return false;
    },

    returnPath() {
      return window.location.pathname;
    },
  },

  methods: {
    usernameKeyDown(e) {
      if (e.key === 'Enter') {
        if (this.password !== '') {
          this.login();
        } else {
          this.$refs.password.focus();
        }
      }
    },

    passwordKeyDown(e) {
      if (e.key === 'Enter') {
        this.login();
      }
    },

    login() {
      if (!this.canLogin) {
        return;
      }

      this.busy = true;
      this.failed = false;

      Promise
        .resolve($.ajax({
          url: '/login',
          method: 'POST',
          data: JSON.stringify({
            username: this.username,
            password: this.password,
          }),
          contentType: 'application/json',
        }))
        .then(() => {
          this.busy = false;
          this.passed = true;
          this.$emit('loggedin');
          utils.bridge.trigger('checkSession');
        })
        .catch((err) => {
          this.busy = false;
          this.failed = true;
          // eslint-disable-next-line no-console
          console.warn(err);
        });
    },
  },
};
</script>

<style>
.my-login-form .form-group {
  max-width: 200px;
}
</style>
