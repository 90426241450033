var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-loaded-path-info" },
    [
      !_vm.lines
        ? _c("l10n", { attrs: { value: "Loading..." } })
        : [
            _c(
              "gp-check",
              {
                model: {
                  value: _vm.onlyImportant,
                  callback: function($$v) {
                    _vm.onlyImportant = $$v
                  },
                  expression: "onlyImportant"
                }
              },
              [
                _c("l10n", { attrs: { value: "Show only important messages" } })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gp-loaded-path-info-lines" },
              _vm._l(_vm.lines, function(line) {
                return !_vm.onlyImportant || line.startsWith("!")
                  ? _c("div", { class: { important: line.startsWith("!") } }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(line.replace(/^! /, "")) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              }),
              0
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }