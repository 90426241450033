var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "foamtree" },
    [
      _c("my-errors", {
        attrs: { errors: _vm.errors },
        on: { retry: _vm.requestData }
      }),
      _vm._v(" "),
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _c("my-progress", {
        key: _vm.reportId,
        attrs: {
          progress: _vm.progress,
          processRows: _vm.processRows,
          processTime: _vm.processTime,
          meta: _vm.meta,
          exportToFile: _vm.exportToFile,
          assembly: _vm.assembly
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "my-filters" },
        [
          _vm._l(_vm.filters, function(filter) {
            return _c(
              "a",
              {
                class: _vm.filterClass(filter),
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.removeFilter(filter)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(filter.name) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _vm.filters.length == 0
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.wasFiltered,
                      expression: "wasFiltered"
                    }
                  ],
                  staticClass: "text-muted badge"
                },
                [_vm._v("\n      no filters applied\n    ")]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("my-download", { attrs: { file: _vm.file } }),
      _vm._v(" "),
      _c("my-tooltip", {
        ref: "tooltip",
        attrs: { meta: _vm.meta, keepOnly: _vm.keepOnly, exclude: _vm.exclude }
      }),
      _vm._v(" "),
      _c("div", { ref: "base", staticClass: "my-foamtree", style: _vm.style }, [
        _c("div", { ref: "chart" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }