<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="quarter-select-container">
    <label for="select-target-quarter">
      <l10n value="Select target quarter" />
    </label>
    <select
      id="select-target-quarter"
      v-model="targetQuarters"
      multiple
      class="form-control form-control-sm dropdown-select target-quater-select"
      @change="onSelectQuarter"
    >
      <option
        v-for="option in optionItems"
        v-bind:key="`target-quarter-${option}`"
        :value="option"
        :selected="targetQuarters.indexOf(option) !== -1"
      >
        <l10n :value="getValueForOption(option)" />
      </option>
    </select>
  </div>
</template>

<script>
const graphQl = require('../api/graphQl');
const utils = require('../my-utils');
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  components: {
    'my-multiselect': MyMultiSelect,
  },
  props: {
    username: {
      type: String,
      required: true,
      default: '',
    },
    stream: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      targetQuarters: [],
      optionItems: [1, 2, 3, 4],
      isLoading: false,
      placeholder10n: utils.l10n('Select quarters'),
    };
  },

  mounted() {
    this.getSelectedQuarters();
  },

  methods: {
    getValueForOption(option) {
      if (this.targetQuarters.indexOf(option) !== -1) {
        return `${option} quarter (selected)`;
      }
      return `${option} quarter (not selected)`;
    },
    getSelectedQuarters() {
      graphQl.makeGQRequest(`
        query {
          dataset {
            source(name: "${this.stream}") {
              report(
                cores: 64,
                cache: true,
                dims: "target_quarter",
                sort: [])
              {
                rows
              }
            }
          }
        }`, '/graphql?__getSelectedTargetQuarters__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;
        this.targetQuarters = rows.map((row) => row[0]) || [];
        this.$emit('target-quarters-changed', this.targetQuarters);
      }).catch(() => {
        this.createNotification('Error while fetching selected target quarters', 'error');
      });
    },

    async onSelectQuarter() {
      try {
        const { stream } = this;
        const createUser = this.username;
        const createTime = Date.now();
        const records = [];

        if (this.targetQuarters.length > 0) {
          this.targetQuarters.forEach((quarter) => {
            const record = [];
            record.push(createUser);
            record.push(createTime);
            record.push(quarter);

            records.push(record);
          });
        }

        await graphQl.makeGQRequest(`
        mutation {
          restartStream(name: "${stream}")
        }`, '/graphql?__restartStreamWithTargetQuarters__');

        graphQl.makeGQRequest(`
        mutation {
          appendRecords(
            stream: "${stream}",
            format: "json",
            records: ${utils.quote(JSON.stringify(records))}
          )
        }`, '/graphql?__changeTargetQuarters__').then((result) => {
          const { data: { dataset: { source: { report: { rows } } } } } = result;
          this.targetQuarters = rows || [];
        });
      } catch (error) {
        this.createNotification('Error while fetching selected target quarters', 'error');
      } finally {
        this.$emit('target-quarters-changed', this.targetQuarters);
      }
    },
  },
};
</script>

<style scoped>
.quarter-select-container {
  margin-bottom: 10px;
}
.quarter-select-container label {
  font-weight: bold;
}
</style>
