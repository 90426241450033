var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-filter" },
    [
      _c("feather-icon", { attrs: { name: "filter" } }),
      _vm._v(" "),
      _vm._l(_vm.filter, function(conditions, i) {
        return _c(
          "span",
          { staticClass: "pim-filter-conditions" },
          [
            _vm._l(conditions, function(values, id) {
              return [
                _vm.activeControl == i + id
                  ? [
                      _c(
                        "my-popup",
                        {
                          key: _vm.activeControl,
                          attrs: { placement: "bottom-start", portal: "popup" },
                          on: {
                            clickoutside: function($event) {
                              _vm.cleanupConditions()
                              _vm.activeControl = null
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "anchor",
                                fn: function() {
                                  return [
                                    _c("span", {
                                      staticClass: "ui-front",
                                      staticStyle: { display: "inline-block" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pim-filter-popup" },
                            [
                              _c("gp-select", {
                                ref: "field",
                                refInFor: true,
                                attrs: {
                                  options: _vm.visibleFields,
                                  autofocus: !_vm.field,
                                  placeholder: "Field",
                                  recentOptionsKey: "recentFields"
                                },
                                model: {
                                  value: _vm.field,
                                  callback: function($$v) {
                                    _vm.field = $$v
                                  },
                                  expression: "field"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.operator,
                                      expression: "operator"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.operator = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                _vm._l(_vm.operators, function(
                                  operator,
                                  value
                                ) {
                                  return _c(
                                    "option",
                                    { domProps: { value: value } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(operator) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "pim-filter-values" },
                                [
                                  _vm.loading
                                    ? _c("feather-icon", {
                                        attrs: { name: "clock" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.field &&
                                  ["decimal", "numeric"].includes(
                                    _vm.field.field_type
                                  )
                                    ? _c("input", {
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { type: "number" }
                                      })
                                    : _vm.field &&
                                      _vm.field.field_type == "date"
                                    ? _c("input", {
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { type: "date" }
                                      })
                                    : _vm.field &&
                                      _vm.field.field_type == "datetime"
                                    ? _c("input", {
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { type: "datetime-local" }
                                      })
                                    : _c(
                                        "textarea",
                                        {
                                          ref: "values",
                                          refInFor: true,
                                          staticClass:
                                            "form-control form-control-sm",
                                          on: {
                                            focus: function($event) {
                                              return _vm.autocomplete.autocomplete(
                                                "search"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(values.join("\n")) +
                                              "\n                              "
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _vm.operator == "between"
                                    ? [
                                        _vm.field &&
                                        _vm.field.field_type == "date"
                                          ? _c("input", {
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: { type: "date" }
                                            })
                                          : _vm.field &&
                                            _vm.field.field_type == "datetime"
                                          ? _c("input", {
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: { type: "datetime-local" }
                                            })
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", {
                                ref: "autocomplete",
                                refInFor: true
                              }),
                              _vm._v(" "),
                              _vm.field &&
                              [
                                "alphanumeric",
                                "textarea",
                                "dropdown",
                                "multidropdown"
                              ].includes(_vm.field.field_type)
                                ? _c(
                                    "div",
                                    { staticClass: "pim-filter-popup-notes" },
                                    [
                                      _c("l10n", {
                                        attrs: {
                                          value:
                                            "Use multiple lines to filter by multiple values"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "pim-filter-popup-actions" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-xs",
                                      attrs: { disabled: _vm.field == null },
                                      on: {
                                        click: function($event) {
                                          _vm.changeCondition(i, id)
                                          _vm.cleanupConditions()
                                          _vm.activeControl = null
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [_c("l10n", { attrs: { value: "OK" } })],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary btn-xs",
                                      on: {
                                        click: function($event) {
                                          _vm.cleanupConditions()
                                          _vm.activeControl = null
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", { attrs: { value: "Cancel" } })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger btn-xs",
                                      on: {
                                        click: function($event) {
                                          _vm.deleteCondition(i, id)
                                          _vm.cleanupConditions()
                                          _vm.activeControl = null
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", { attrs: { value: "Delete" } })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.field = _vm.getFieldByCalc(id)
                        _vm.activeControl = i + id
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.getFieldByCalc(id).name) +
                        ":\n        " +
                        _vm._s(
                          values.length < 10
                            ? values.join(", ")
                            : values.slice(0, 8).join(", ") +
                                " " +
                                _vm
                                  .l10n("and {more} more...")
                                  .replace(
                                    "{more}",
                                    new Number(
                                      values.length - 8
                                    ).toLocaleString()
                                  )
                        )
                    )
                  ]
                ),
                _vm._v(" "),
                _c("l10n", {
                  staticClass: "operator",
                  attrs: { value: "AND" }
                }),
                _vm._v(" "),
                _c("span")
              ]
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "new",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.$set(conditions, "", [])
                    _vm.field = null
                    _vm.activeControl = i
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "plus" } })],
              1
            ),
            _vm._v(" "),
            _vm.filter.length > 0
              ? _c("l10n", { staticClass: "operator", attrs: { value: "OR" } })
              : _vm._e(),
            _vm._v(" "),
            _c("span")
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("span", { staticClass: "pim-filter-conditions" }, [
        _c(
          "a",
          {
            staticClass: "new",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.activeControl = _vm.filter.length
                _vm.filter.push({ "": [] })
              }
            }
          },
          [_c("feather-icon", { attrs: { name: "plus" } })],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }