var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gp-stored", {
        attrs: { family: "reports" },
        model: {
          value: _vm.config,
          callback: function($$v) {
            _vm.config = $$v
          },
          expression: "config"
        }
      }),
      _vm._v(" "),
      _c(
        "gp-check",
        {
          model: {
            value: _vm.checked,
            callback: function($$v) {
              _vm.checked = $$v
            },
            expression: "checked"
          }
        },
        [_vm._v("test")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }