<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="my-chosen" ref="root">
    <my-caption :caption="caption" />
    <select
      ref="select"
      :multiple="multiple"
      :placeholder="placeholder10n"
      :data-placeholder="placeholder10n"
      :disabled="disabled"
    >
      <option value="" :selected="selection.length == 0" disabled="disabled" />
      <option
        v-for="option in options"
        :key="option[0]"
        :value="option[0]"
        :selected="computedSelection.find(selected => selected[0].name == option[0])"
      >
        {{ formatValue(option) }}
      </option>
    </select>
  </div>
</template>

<script>
const utils = require('./my-utils');
require('./chosen.jquery');

module.exports = {
  data() {
    let selection = this.initialSelection;

    selection = _.map(selection, option => _.isArray(option) ? option : [option]);
    return {
      selection,
    };
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    indexed: { type: Boolean, default: false },
    caption: { type: String },
    rtl: { type: Boolean },
    width: { type: String, default: 'auto' },
    maxSelectedOptions: { type: Number },
    disableSearchThreshold: { type: Number },
    allowSingleDeselect: { type: Boolean, default: true },
    noResultsText: { type: String },
    multiple: { type: Boolean },
    placeholder: { type: String, default: utils.l10n('Select some options') },
    hierarchical: { type: Boolean, default: false },
    initialSelection: { type: Array, default: () => [] },
    filtersFunction: { type: [String, Function] },
    localStorageKey: { type: String },
    format: { type: [Function, String] },
    desiredSort: { type: Array, default: () => [1] },
    autofilter: { type: Boolean, default: true },
    incomingOptions: { type: Array, required: false },
    disabled: { type: Boolean, required: false },
  },

  computed: {
    placeholder10n() {
      return utils.l10n(this.placeholder);
    },
    // sort() {
    //   if (this.dims.length > 0)
    //     return this.desiredSort || this.initialSort || [1];
    //   else
    //     return null;
    // },
    options() {
      return this.incomingOptions.map((option) => [option.text, option.name]);
    },
    computedSelection() {
      return this.selection;
    },
  },
  updated() {
    this.updateChosenWidth();
    $(this.$refs.select).trigger('chosen:updated');
  },
  mounted() {
  //   window.component = this
    this.createChosen();
  //   $(this.$refs.select).trigger('chosen:updated')
  //   if (this.autofilter)
  //     utils.bridge.trigger('filtersChanged', this._uid, this.filters)
  },
  watch: {
    disabled() {
      this.$nextTick(() => this.createChosen());
    },
    // initialSelection() {
    //   let selection = this.initialSelection;
    //   this.selection = _.map(selection, (option) => _.isArray(option) ? option : [option]);
    // },
  //   rtl: function() { Vue.nextTick(() => this.createChosen()) },
  //   width: function() { Vue.nextTick(() => this.createChosen()) },
  //   maxSelectedOptions: function() { Vue.nextTick(() => this.createChosen()) },
  //   disableSearchThreshold: function() { Vue.nextTick(() => this.createChosen()) },
  //   allowSingleDeselect: function() { Vue.nextTick(() => this.createChosen()) },
  //   noResultsText: function() { Vue.nextTick(() => this.createChosen()) },
  //   multiple: function() { Vue.nextTick(() => this.createChosen()) },
  //   selection() {
  //     if (this.localStorageKey && _.isArray(this.selection))
  //       localStorage[this.localStorageKey] = JSON.stringify(this.selection)
  //     this.$emit('change', this.selection)
  //   }
  },
  beforeDestroy() {
    $(this.$refs.select).chosen('destroy');
  },
  methods: {
    formatValue(option) {
      if (_.isFunction(this.format)) {
        return this.format(option[0], option);
      }
      if (_.isString(this.format)) {
        try {
          return eval(this.format)(option[0], option);
        } catch (ex) {
          console.warn('failed to format value', ex);
        }
      }
      return option[0];
    },
    createChosen() {
      $(this.$refs.select).chosen('destroy');
      $(this.$refs.select).chosen({
        rtl: this.rtl,
        width: this.width,
        max_selected_options: this.maxSelectedOptions,
        no_results_text: this.noResultsText,
        allow_single_deselect: this.allowSingleDeselect,
        disable_search_threshold: this.disableSearchThreshold,
      }).change(() => {
        this.selection = _(this.$refs.select.options)
          .filter(({ selected, disabled }) => selected && !disabled)
          .map(({ value }) => this.options.find(option => option[0] == value))
          .filter()
          .value();
        let selectedOption = null;
        if (this.selection.length) {
          [[selectedOption]] = this.selection;
        }
        this.$emit('select-change', selectedOption);
      });
      $(this.$el).find('.chosen-container').on('touchstart', function(e) {
        e.stopPropagation();
        e.preventDefault();
        // Trigger the mousedown event.
        $(this).trigger('mousedown');
      });
      $(this.$el).find('.chosen-search-input').attr('placeholder', this.placeholder10n);
    },
    updateChosenWidth() {
      // let width = $(this.$refs.select).outerWidth()
      // if (this.multiple)
      //     width += 28
      // if (!this.multiple && this.allowSingleDeselect)
      //     width += 20
      // $(this.$refs.root)
      //     .find(".chosen-container")
      //     .css("min-width", `${width}px`)
    },
  },
};
</script>
<style scoped>
.my-content .my-caption {
  float: none;
}
.my-content .chosen-container-single .chosen-single abbr {
  right: 22px;
}
.my-content .chosen-container-multi .chosen-choices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
}
.my-content .chosen-container-multi .chosen-choices li.search-choice,
.my-content .chosen-container-multi .chosen-choices li.search-field {
  float: none;
  margin: 2px;
}
.my-content .chosen-container-multi .chosen-choices li.search-field input[type=text] {
  margin-top: -2px;
  margin-bottom: -2px;
}
.my-content .chosen-container .chosen-drop {
  width: auto;
  border-top: 1px solid rgb(88,151,251);
  margin-top: -1px;
}
.my-content .chosen-container .chosen-results li {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
