<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="plan-select-container">
    <label for="select-priority-plan">
      <l10n value="Select priority plan" />
    </label>
    <select
      id="select-priority-plan"
      v-model="priorityPlan"
      class="form-control form-control-sm dropdown-select plan-select"
      @change="onSelectPlan"
    >
      <option
        value=""
        disabled
        selected
      >
        {{ placeholder10n }}
      </option>
      <option
        v-for="item in optionItems"
        v-bind:key="item.plan"
        :value=item.plan
      >
        <l10n :value=item.plan /></option>
    </select>
  </div>
</template>

<script>
const graphQl = require('../api/graphQl');
const utils = require('../my-utils');

module.exports = {
  props: {
    username: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      priorityPlan: '',
      optionItems: [],
      placeholder10n: utils.l10n('Select priority plan'),
    };
  },

  mounted() {
    this.getTargetPlansList();
    this.getSelectedPlan();
  },

  methods: {
    getTargetPlansList() {
      graphQl.makeGQRequest(`
          query {
            dataset {
              source(name: "five_step_algorithm_types_priority_plans") {
                report(
                  cores: 1,
                  cache: true,
                  dims: "priority, plan",
                  sort: [])
                {
                  rows
                }
              }
            }
          }`, '/graphql?__getTargetPlansList__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;
        const data = rows.map((row) => ({
          priority: row[0],
          plan: row[1],
        }));
        this.optionItems = data;
      }).catch(() => {
        this.createNotification('Error while fetching priority plans', 'error');
      });
    },

    getSelectedPlan() {
      graphQl.makeGQRequest(`
          query {
            dataset {
              source(name: "five_step_algorithm_priority_plan_override_last") {
                report(
                  cores: 1,
                  cache: true,
                  dims: "priority_plan",
                  sort: [])
                {
                  rows
                }
              }
            }
          }`, '/graphql?__getSelectedTargetPlan__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;

        if (rows.length !== 0) {
          const data = rows[0][0];
          this.priorityPlan = data;
        } else {
          this.priorityPlan = this.optionItems.find((item) => item.priority === 1).plan;
          this.saveDefaultPriorityPlan();
        }

        this.$emit('priority-plan-changed');
      }).catch(() => {
        this.createNotification('Error while fetching selected priority plan', 'error');
      });
    },

    onSelectPlan(e) {
      const priorityPlan = e.target.value;
      const createUser = this.username;
      const createTime = Date.now();
      const record = [];

      record.push(createUser);
      record.push(createTime);
      record.push(priorityPlan);

      let records = [];
      records.push(record);
      records = JSON.stringify(records);

      graphQl.makeGQRequest(`
        mutation {
          appendRecords(
            stream: "five_step_algorithm_priority_plan_override",
            format: "json",
            records: ${utils.quote(records)})
        },`, '/graphql?__changeTargetPlan__').then((result) => {
        if (result.data) {
          this.createNotification('Priority plan has been successfully changed', 'success');
          this.$emit('priority-plan-changed');
        } else if (result.errors) {
          result.errors.forEach((error) => {
            this.createNotification(error.message, 'error');
          });
        }
      }).catch(() => {
        this.createNotification('Error while setting priority plan', 'error');
      });
    },

    saveDefaultPriorityPlan() {
      const { priorityPlan } = this;
      const createUser = this.username;
      const createTime = Date.now();
      const record = [];

      record.push(createUser);
      record.push(createTime);
      record.push(priorityPlan);

      let records = [];
      records.push(record);
      records = JSON.stringify(records);

      graphQl.makeGQRequest(`
        mutation {
          appendRecords(
            stream: "five_step_algorithm_priority_plan_override",
            format: "json",
            records: ${utils.quote(records)})
        },`, '/graphql?__setDefaultTargetPlan__').then((result) => {
        if (result.errors) {
          result.errors.forEach((error) => {
            this.createNotification(error.message, 'error');
          });
        }
      }).catch(() => {
        this.createNotification('Error while setting default priority plan', 'error');
      });
    },
  },
};
</script>

  <style scoped>
  .plan-select-container {
    margin-bottom: 10px;
  }
  .plan-select-container label {
    font-weight: bold;
  }
  </style>
