<template>
  <my-dialog
    title="Add new season collection"
    v-if="show"
    @close="closeForm"
  >
    <div class="inputblock">
      <label class="inputblock__label" for="seasons">Seasons</label>
      <my-multiselect
        id="seasons"
        v-model="season"
        :options="seasons"
        :maxHeight="190"
        :loading="isLoading"
        @open="getSeasons"
        placeholder="Выберите сезон"
      />
    </div>

    <div class="inputblock inputblock_select">
      <label class="inputblock__label" for="collections">Collections</label>
      <my-multiselect
        id="collections"
        v-model="collection"
        :options="collections"
        :maxHeight="190"
        :loading="isLoading"
        :searchable="true"
        @open="getCollections"
        placeholder="Выберите коллекцию"
      />
    </div>

    <div class="buttons-block">
      <button
        type="button"
        class="button button_primary"
        :disabled="hasEmptyField && tryToSendForm"
        :class="{ button_disabled: hasEmptyField && tryToSendForm }"
        @click="onCreateNewSeasonCollection"
      >
        <l10n value="Save" />
      </button>
      <button
        type="button"
        class="button button_secondary"
        @click="closeForm"
      >
        <l10n value="Cancel" />
      </button>
    </div>
  </my-dialog>
</template>

<script>
const graphQl = require('../api/graphQl');
const utils = require('../my-utils');
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  components: {
    'my-multiselect': MyMultiSelect,
  },
  props: {
    isAddNewSeasonCollectionModalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    targetYear: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      season: '',
      collection: '',
      seasons: [],
      collections: [],
      tryToSendForm: false,
      isLoading: false,
    };
  },

  computed: {
    hasEmptyField() {
      return !this.season || !this.collection;
    },
  },

  watch: {
    isAddNewSeasonCollectionModalVisible(newValue) {
      this.show = newValue;
    },
  },

  methods: {
    getSeasons() {
      this.isLoading = true;
      const targetYearLastDigits = Number(this.targetYear.slice(2));
      this.seasons = ['Base', `SS'${targetYearLastDigits}`, `FW'${targetYearLastDigits}`, `SS'${targetYearLastDigits + 1}`];
      this.isLoading = false;
    },
    async getCollections() {
      this.isLoading = true;

      graphQl.makeGQRequest(`
      query {
        dataset {
        source(name: "pp_collections") {
                        
        report(
          name: "gp-table",
          cores: 64,
          cache: true,        
          dims: "item",
          sort: []
          )
          {
            rows
          }
            }
            }
      }`, '/graphql?__getCollections__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;
        const collections = [];

        rows.forEach((row) => collections.push(row[0]));

        this.collections = collections;
        this.isLoading = false;
      }).catch(() => {
        this.createNotification('Error while fetching collections', 'error');
        this.collections = [];
        this.isLoading = false;
      });
    },
    localizeValue(value) {
      return utils.l10n(value);
    },
    cleanForm() {
      this.season = '';
      this.collection = '';
    },

    async onCreateNewSeasonCollection() {
      if (this.hasEmptyField) {
        this.tryToSendForm = true;
        this.createNotification('Form has an empty fields', 'error');
        return;
      }

      const stream = 'PP_seasons_collections';
      const queryBody = [{
        item: this.season,
        collection: this.collection,
        target_year: this.targetYear,
        date: '2023-01-01',
      }];
      const query = `
          mutation {
            appendRecords(
                stream: ${utils.quote(stream)},
                format: ${utils.quote('json')},
                records: ${utils.quote(JSON.stringify(queryBody))}
              )
          }`;

      graphQl.makeGQRequest(query, '/graphql?__addNewSeasonCollection__')
        .then((result) => {
          if (result?.data && result?.data?.appendRecords) {
            this.createNotification('New season collection has been added successfully', 'success');
            this.closeForm();
            this.$emit('on-new-season-collection-successful-added');
          } else {
            this.createNotification('An error ocured while adding new season collection', 'error');
          }
        });

      utils.bridge.trigger('streamModified', stream);
    },
    closeForm() {
      this.cleanForm();
      this.$emit('add-new-season-collection-modal-close');
    },
  },
};
</script>

<style scoped>
  .input {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 350px;
    height: 46px;
    margin: 0;
    padding: 0px 8px 0px 12px;
    border: none;
    border-radius: 8px;
    background: #f7f7f7;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }
  .input:hover {
    background-color: #E9E9E9;
    cursor: pointer;
  }
  .input:focus {
    border-color: #5295D5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
    box-shadow: 0 0 0 0.2rem #5295D5;
  }
  .input_invalid {
    background-color: #FAE6EB;
  }
  .inputblock {
    margin-bottom: 16px;
  }
  .inputblock__label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #9B9B9B;
  }
  .inputblock_select {
  height: 220px;
  }
  .buttons-block {
    display: flex;
    justify-content: space-around;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 46px;
    border: none;
    border-radius: 8px;
    transition: background-color .3s ease-in-out;
  }
  .button_primary {
    background-color: #5295D5;
    color: #ffffff;
  }
  .button_secondary {
    background-color: #F7F7F7;
    color: #000000;
  }
  .button_primary:not(.button_disabled):hover {
    background-color: #3C80C1;
    transition: background-color .3s ease-in-out;
  }
  .button_secondary:hover {
    background-color: #E9E9E9;
    transition: background-color .3s ease-in-out;
  }
  .button_disabled {
    background-color: #F7F7F7;
    color: #D4D4D4;
    cursor: default;
  }
  </style>
