<template>
  <div :class="{ kpis: true, loading: reportId }">
    <template v-if="editing">
      <gp-portal to="modal">
        <my-popup
          @escape="stopEditing"
          placement="bottom"
          :anchor="getReference">
          <div class="kpi-form">
            <table>
              <tbody>
                <tr>
                  <th><l10n value="KPI name" /></th>
                  <td>
                    <input
                      autofocus
                      class="form-control"
                      v-model="editingEntryName" />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Primary metric" /></th>
                  <td>
                    <gp-select
                      :options="metricOptions"
                      v-model="editingMetric1"
                      recentOptionsKey="recentMetrics"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Primary timeframe" /></th>
                  <td>
                    <gp-select
                      :options="timeframeOptions"
                      v-model="editingTimeframe1"
                      recentOptionsKey="recentTimeframes"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Secondary metric" /></th>
                  <td>
                    <gp-select
                      :options="metricOptions"
                      v-model="editingMetric2"
                      recentOptionsKey="recentMetrics"
                    />
                  </td>
                </tr>
                <tr>
                  <th><l10n value="Secondary timeframe" /></th>
                  <td>
                    <gp-select
                      :options="timeframeOptions"
                      v-model="editingTimeframe2"
                      recentOptionsKey="recentTimeframes"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="kpi-form-actions">
              <button
                v-if="editingEntry"
                class="btn btn-danger"
                @click="
                  removeEntry()
                  stopEditing()
                ">
                <l10n value="Delete" />
              </button>
              <span />
              <button
                class="btn btn-primary"
                @click="submitEditing"
                :disabled="!editingMetric1 || !editingTimeframe1 || !editingEntryName">
                <l10n v-if="editingEntry" value="Submit" />
                <l10n v-else value="Create" />
              </button>
              <button
                class="btn btn-secondary"
                @click="stopEditing">
                <l10n value="Cancel" />
              </button>
            </div>
          </div>
        </my-popup>
      </gp-portal>
    </template>
    <div class="kpi" v-for="entry, i in entries">
      <div>
        <template v-for="metric, j in entry.metrics" v-if="values.has(metricKey(metric))">
          <span
            :set1="val0 = values.get(metricKey(entry.metrics[0])).value"
            :set2="valn = values.get(metricKey(metric)).value"
            :set3="column = values.get(metricKey(metric)).column"
            :set4="format = values.get(metricKey(metric)).column.format"
            :title="j != 0 ? format(valn, [], column) : null"
          >
            <template v-if="j == 0">
              {{format(valn, [], column)}}
            </template>
            <template v-else>
              <span
                :class="{
                  positive: j != 0 && val0 - valn > 0.001,
                  negative: j != 0 && valn - val0 > 0.001,
                }">
                <template v-if="valn != 0">
                  {{formatPercent(val0 / valn - 1)}}
                </template>
                <template v-else>
                  {{val0 > valn ? '+' : ''}}{{format(val0 - valn, [], column)}}
                </template>
                <feather-icon v-if="val0 - valn > 0.001" name="trending-up" />
                <feather-icon v-if="valn - val0 > 0.001" name="trending-down" />
              </span>
            </template>
          </span>
        </template>
      </div>
      <label>{{entry.name}}</label>
      <a
        href="javascript:void(0)"
        class="kpi-edit"
        @click="startEditing(entry)">
        <feather-icon name="edit-3" />
      </a>
    </div>
    <div class="kpi-add">
      <a href="javascript:void(0)" @click="startEditing()">
        <feather-icon name="plus" />
      </a>
    </div>
    <gp-data
      v-if="referenceDate"
      id="gp-kpis"
      ref="data"
      :stream="stream"
      :source="source"
      :groups="groups"
      :vars="vars"
      :vals="vals"
      :instant="false"
      v-model="report"
      @reportId="reportId = $event"
    />
  </div>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  mixins: [
    utils.extraFilters,
    utils.configHelpers,
    utils.referenceDateHelper,
  ],
  model: {
    prop: 'entries',
    event: 'change',
  },
  props: {
    stream: { type: String, default: 'default' },
    vars: { type: Object, default: () => ({}) },
    groups: { type: Array },
    entries: { type: Array, default: () => [] },
  },
  data() {
    const entries = [];

    return {
      report: null,
      reportId: null,
      editing: false,
      editingEntry: null,
      editingMetric1: null,
      editingMetric2: null,
      editingTimeframe1: null,
      editingTimeframe2: null,
      editingEntryName: null,
      primaryDims: [],
    };
  },
  mounted() {
    window.kpis = this;
    if (window.gptable) {
      this.primaryDims = this.cleanupDims(gptable.dims);
    }
    utils.bridge.bind('primaryDimsChanged', this.primaryDimsChanged);
  },
  beforeDestroy() {
    utils.bridge.unbind('primaryDimsChanged', this.primaryDimsChanged);
  },
  watch: {
    entries: {
      deep: true,
      handler() {
        localStorage.kpiEntries = JSON.stringify(this.entries);
      },
    },
  },
  methods: {
    metricKey(metric) {
      return `${metric.formula}-${metric.timeframe}`;
    },
    cleanupDims(dims) {
      return _.map(dims, (dim) => _.pick(dim, ['calc']));
    },
    primaryDimsChanged(primaryDims) {
      primaryDims = this.cleanupDims(primaryDims);
      if (!_.isEqual(this.primaryDims, primaryDims)) {
        this.primaryDims = primaryDims;
      }
    },
    getReference() {
      if (this.editingEntry) {
        return $(this.$el).find('.kpi')[this.entries.indexOf(this.editingEntry)];
      }
      return $(this.$el).find('.kpi-add')[0];
    },
    parseDate(text) {
      const [y, m, d] = text.split('-').map((x) => parseInt(x));
      return new Date(y, m - 1, d);
    },
    formatDate(date) {
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      return `${y}-${m < 10 ? '0' : ''}${m}-${d < 10 ? '0' : ''}${d}`;
    },
    formatPercent(x) {
      let text = new Number(x).toLocaleString(this.locale, {
        style: 'percent',
        maximumFractionDigits: 1,
      });
      if (x > 0) {
        text = `+${text}`;
      }
      return text;
    },
    startEditing(entry) {
      this.stopEditing();
      this.editing = true;
      if (entry) {
        this.editingEntry = entry;
        this.editingEntryName = entry.name;
        if (entry.metrics[0]) {
          const metric = entry.metrics[0];
          this.editingMetric1 = this.metricsByFormula[metric.formula];
          this.editingTimeframe1 = _.assign({ id: metric.timeframe }, this.timeframes[metric.timeframe]);
        }
        if (entry.metrics[1]) {
          const metric = entry.metrics[1];
          this.editingMetric2 = this.metricsByFormula[metric.formula];
          this.editingTimeframe2 = _.assign({ id: metric.timeframe }, this.timeframes[metric.timeframe]);
        }
      }
    },
    stopEditing() {
      this.editing = false;
      this.editingEntry = null;
      this.editingEntryName = '';
      this.editingMetric1 = null;
      this.editingMetric2 = null;
      this.editingTimeframe1 = null;
      this.editingTimeframe2 = null;
    },
    submitEditing() {
      const name = this.editingEntryName;
      const metrics = [];
      if (this.editingMetric1 && this.editingTimeframe1) {
        metrics.push({
          formula: this.editingMetric1.formula.split(/[\s,]+/g)[0],
          format: this.editingMetric1.format,
          timeframe: this.editingTimeframe1.id,
        });
      }
      if (this.editingMetric2 && this.editingTimeframe2) {
        metrics.push({
          formula: this.editingMetric2.formula.split(/[\s,]+/g)[0],
          format: this.editingMetric2.format,
          timeframe: this.editingTimeframe2.id,
        });
      }
      const entry = { name, metrics };
      if (this.editingEntry) {
        const entries = _.clone(this.entries);
        entries.splice(entries.indexOf(this.editingEntry), 1, entry);
        this.$emit('change', entries);
      } else {
        const entries = _.clone(this.entries);
        entries.push(entry);
        this.$emit('change', entries);
      }
      this.stopEditing();
    },
    removeEntry() {
      const entries = _.clone(this.entries);
      entries.splice(entries.indexOf(this.editingEntry), 1);
      this.$emit('change', entries);
    },
    makeVals(vals, metric) {
      const referenceDate = this.parseDate(this.referenceDate);
      let { timeframe } = metric;
      if (!this.timeframes[timeframe]) {
        timeframe = 'reference_date';
      }

      const [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate);

      const resolveSubstitutes = (calc, depth = 0) => {
        if (depth == 10) {
          return calc;
        }
        return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
          const formula = this.formulas[symbol];
          if (formula !== undefined && !this.isAggregationFormula(formula)) {
            return `(${resolveSubstitutes(formula, depth + 1)})`;
          }
          return symbol;
        });
      };

      const registerFormula = (symbol) => {
        const formula = this.formulas[symbol];
        if (formula !== undefined) {
          if (this.isAggregationFormula(formula)) {
            vals[`${symbol}_${timeframe}`] = this.resolveDateConditions(
              resolveSubstitutes(formula),
              startDate,
              endDate,
              referenceDate,
            );
          } else {
            for (const [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
              registerFormula(symbol);
            }
          }
        }
      };

      const symbols = metric.formula.split(/[\s,]+/g);
      for (const symbol of symbols) {
        registerFormula(symbol);
      }
    },
    makeCols(cols, metric) {
      let calc;
      const symbols = metric.formula.split(/[\s,]+/g);
      const symbol = symbols[0];

      const formula = this.formulas[symbol];
      let { timeframe } = metric;
      if (!this.timeframes[timeframe]) {
        timeframe = 'reference_date';
      }
      if (formula !== undefined) {
        if (this.isAggregationFormula(formula)) {
          calc = `${symbol}_${timeframe}`;
        } else {
          const resolveSubstitutes = (calc, depth = 0) => {
            if (depth == 10) {
              return calc;
            }
            return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
              const formula = this.formulas[symbol];
              if (formula !== undefined) {
                if (this.isAggregationFormula(formula)) {
                  return `${symbol}_${timeframe}`;
                }
                return `(${resolveSubstitutes(formula, depth + 1)})`;
              }
              return symbol;
            });
          };
          calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => resolveSubstitutes(symbol));
        }
      }

      const { name } = metric;

      let format = this.formats[metric.format] || metric.format;
      if (_.isString(format)) {
        try {
          format = eval(format);
        } catch (ex) {
          console.warn(format, ex);
        }
      }
      if (!_.isFunction(format)) {
        format = (x) => x;
      }

      if (calc !== undefined) {
        cols.push(_.assign(
          {
            name,
            calc,
            format,
            metric,
          },
          _.omit(metric, ['name', 'type', 'format']),
        ));
      }
    },
  },
  computed: {
    source() {
      const vals = {};
      const cols = [];
      const dims = []; // this.primaryDims

      for (const entry of this.entries) {
        for (const metric of entry.metrics) {
          this.makeVals(vals, metric);
          this.makeCols(cols, metric);
        }
      }

      return {
        dims,
        cols,
        vals: _(vals)
          .toPairs()
          .map(([name, calc]) => ({
            name,
            calc: `${calc} as ${name}`,
            show: false,
          }))
          .sortBy('calc')
          .value(),
        filter0: this.filter0,
      };
    },
    vals() {
      return _.map(
        this.source.cols,
        ({ metric, format, calc }, i) => {
          let aggregation = 'sum';
          if (metric.format == 'percent') {
            aggregation = 'avg';
          }

          if (metric.formula.startsWith('avg')) {
            aggregation = 'avg';
          }

          if (_.startsWith(calc, 'avg')) {
            aggregation = 'avg';
          }
          if (_.startsWith(calc, 'min')) {
            aggregation = 'min';
          }
          if (_.startsWith(calc, 'max')) {
            aggregation = 'max';
          }

          let precision;
          if (aggregation == 'sum') {
            precision = 0;
          }

          const col = `col${i + this.source.dims.length + 1}`;

          return {
            metric,
            format,
            precision,
            calc: `${aggregation}(${col} if ${col} != 0)`,
          };
        },
      );
    },
    filter0() {
      const dates = new Set();
      const referenceDate = this.parseDate(this.referenceDate);
      for (const date of [
        referenceDate,
        utils.nextDate(referenceDate),
        utils.prevDate(referenceDate)]) {
        dates.add(this.formatDate(date));
      }
      for (const entry of this.entries) {
        for (const metric of entry.metrics) {
          let { timeframe } = metric;
          if (!this.timeframes[timeframe]) {
            timeframe = 'reference_date';
          }
          let [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate);
          if (endDate >= startDate) {
            if (metric.formula) {
              const formula = this.resolveSubstitutes(metric.formula);
              if (_.includes(formula, 'date_before_start')) {
                startDate = utils.prevDate(startDate);
              }
              if (_.includes(formula, 'date_after_end')) {
                endDate = utils.nextDate(endDate);
              }
            }
            let date = new Date(startDate);
            while (date.getTime() <= endDate.getTime()) {
              dates.add(this.formatDate(date));
              date = utils.nextDate(date);
            }
          }
        }
      }
      const datesArray = Array.from(dates).sort((a, b) => new Date(a) - new Date(b));
      const datesLength = datesArray.length;
      const [firstDate] = datesArray;

      if (datesLength > 1) {
        const lastDate = datesArray[datesLength - 1];

        return `date >= \`${firstDate}\` && date <= \`${lastDate}\``;
      }

      return `date == \`${firstDate}\``;
    },
    values() {
      return this.report && this.report.rows && this.report.rows.length == 1
        ? new Map(this.report.meta.columns.map((column, i) => [this.metricKey(column.metric), { column, i, value: this.report.rows[0][i] }])) : new Map();
    },
    metricOptions() {
      return _(this.metrics).filter((metric) => metric.name && !metric.deleted).sortBy((metric) => metric.name).value();
    },
    timeframeOptions() {
      return _(this.computedTimeframes)
        .toPairs()
        .map(([id, timeframe]) => _.assign({ id }, timeframe))
        .value();
    },
    computedTimeframes() {
      const referenceDate = this.parseDate(this.referenceDate);
      return _(this.timeframes)
        .toPairs()
        .filter(([id, { deleted }]) => !deleted)
        .map(([id, { calc, name }]) => {
          try {
            const [startDate, endDate] = eval(calc)(referenceDate);
            return [id, {
              calc,
              name,
              startDate,
              endDate,
              referenceDate,
            }];
          } catch (ex) {
            console.warn(id, name, calc, ex);
          }
        })
        .filter()
        .sortBy(([id, { startDate }]) => (_.isDate(startDate) ? startDate.getTime() : 0))
        .fromPairs()
        .value();
    },
  },
};
</script>
<style type="text/css">
.kpis {
    float: right;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
}
.kpi {
    text-align: center;
    padding-right: 20px;
    margin-bottom: 10px;
}
.kpi > div {
    display: flex;
    flex-direction: column;
}
.kpi > label {
    margin: auto;
    font-size: 0.8em;
    opacity: 0.9;
    margin-top: -2px;
    display: block;
}
.kpi .my-progress {
    display: none;
}
.kpi .feather-icon-trending-up svg,
.kpi .feather-icon-trending-down svg {
    width: 18px;
    margin-top: -2px;
    margin-right: 8px;
}
.kpi > div > :nth-child(1) {
    font-size: 22px;
}
.kpi > div > :nth-child(2) {
    font-size: 13px;
    margin: auto;
    margin-top: -6px;
}
.kpi > div > :nth-child(2) .my-chart {
    display: flex;
    /*flex-direction: row-reverse;*/
}
#elast .x .tick {
    display: none!important;
}
@media only screen and (max-width: 600px) {
    .gp-side-bar {
        position: absolute;
        z-index: 4;
        width: 100%;
        min-width: none;
        max-width: none;
        background-color: white;
        top: 36px;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .gp-side-bar-hide.close {
        z-index: 5;
    }
    input {
        font-size: 18px;
        border-radius: 0;
    }
    .kpis {
        float: none;
        max-width: inherit;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.kpi {
    position: relative;
}
.kpi-edit {
    position: absolute;
    top: 0;
    right: 0;
}
.kpi-edit svg {
    visibility: hidden;
    width: 16px;
    height: 16px;
}
.kpi:hover .kpi-edit svg {
    visibility: initial;
}
.kpi-add {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px dotted var(--gray);*/
    /*border-radius: 8px;*/
    margin-bottom: 10px;
}
.kpi-add > * {
    display: block;
    margin: auto;
    vertical-align: middle;
}
.kpi-form {
    background-color: white;
    padding: 15px;
    border: 1px solid var(--dark);
    box-shadow: 0 0 10px 0px var(--dark);
}
.kpi-form table {
    margin-bottom: 20px;
    width: 100%;
}
.kpi-form table td {
    width: 300px;
}
.kpi-form table th {
    font-weight: normal;
}
.kpi-form table th,
.kpi-form table td {
    padding: 4px 8px;
}
.kpi-form-actions {
    text-align: right;
}
.kpi-form-actions .btn {
    min-width: 100px;
}
.kpi-form-actions .btn + .btn {
    margin-left: 10px;
}
.kpis {
    margin-top: -4px;
    margin-right: 10px;
}
.kpis.loading .kpi {
    opacity: 0.5;
}
.kpi > div > :nth-child(1) {
    font-size: 22px;
}
.kpi > div > :nth-child(2) {
    font-size: 15px;
    margin-top: 0;
}
.kpi > label {
    margin-top: -1px;
    font-size: 15px;
}
.kpi-add {
    margin-bottom: 0;
}
.kpi-add svg {
    color: var(--gray);
    width: 40px;
    height: 40px;
    padding: 7px;
    border: 1px dotted var(--gray);
    border-radius: 4px;
}
.kpi .positive {
    color: var(--green);
}
.kpi .negative {
    color: var(--red);
}
.kpi-form-actions {
    display: flex;
}
.kpi-form-actions span {
    flex-grow: 1;
    flex-basis: 1px;
}
.kpi-form td > input {
    border: none;
    border-bottom: 1px solid var(--dark);
    border-radius: 0;
    padding: 0 8px;
    height: 28px;
    color: inherit;
    background-color: transparent;
}
.my-dark-theme .kpi-form {
    background-color: rgb(39,53,72);
    border: 1px solid black;
}
.my-dark-theme .kpi-form td > input {
    border-color: var(--light);
}
</style>
