<template>
  <div class="gp-reports">
    <table
      class="table table-sm table-striped table-bordered table-hover table-responsive">
      <thead>
        <tr>
          <th>name</th>
          <th>status</th>
          <th>cores</th>
          <th>user</th>
          <th>progress</th>
          <th>sorting</th>
          <th>id</th>
          <th>source</th>
          <th>filter0</th>
          <th>filter1</th>
          <th>filter2</th>
          <th>filter3</th>
          <th>dims</th>
          <th>vals</th>
          <th>cols</th>
          <th>sort</th>
          <th>duration</th>
          <th>action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="report in visibleReports"
          @click="selectedReport = selectedReport === report ? null : report"
          :class="{
            'table-info': report.status === 'cache',
            'table-success': report.status === 'done',
            'table-danger': report.cancelled || report.status === 'failed',
            selected: selectedReport === report,
          }"
        >
          <td>{{report.name}}</td>
          <td>{{report.cancelled ? 'cancelled' : report.status }}</td>
          <td>{{report.cores}}</td>
          <td>{{report.username}}</td>
          <td>{{report.progress !== undefined ? new Number(report.progress).toLocaleString(undefined, { style: "percent" }) : ""}}</td>
          <td>{{report.sorting}}</td>
          <td>{{report.reportId}}</td>
          <td>{{report.source}}</td>
          <td :title="report.filter0"><span>{{report.filter0 || "-"}}</span></td>
          <td :title="report.filter1"><span>{{report.filter1 || "-"}}</span></td>
          <td :title="report.filter2"><span>{{report.filter2 || "-"}}</span></td>
          <td :title="report.filter3"><span>{{report.filter3 || "-"}}</span></td>
          <td :title="report.dims"><span>{{report.dims || "-"}}</span></td>
          <td :title="report.vals"><span>{{report.vals || "-"}}</span></td>
          <td :title="report.cols"><span>{{report.cols || "-"}}</span></td>
          <td :title="report.sort"><span>{{report.sort && report.sort.length > 0 ? report.sort.join(", ") : "-"}}</span></td>
          <td :title="report.duration"><span>{{report.duration !== undefined ? Number(report.duration).toLocaleString() : null}}</span></td>
          <td>
            <a
              v-if="report.reportId && !report.cancelled"
              href="#"
              @click.prevent.stop="cancelReport(report.reportId)">
              <l10n value="cancel" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
module.exports = {
  props: {
    limit: { type: Number, default: 100 },
  },
  data() {
    return {
      reports: {},
      selectedReport: null,
    };
  },
  mounted() {
    utils.bridge.bind('ws-message', this.handleWsMessage);
  },
  beforeDestroy() {
    utils.bridge.unbind('ws-message', this.handleWsMessage);
  },
  methods: {
    cancelReport(reportId) {
      utils.bridge.trigger('cancelReport', reportId);
    },
    handleWsMessage(message) {
      const { reportId } = message;
      let report = this.reports[reportId];
      if (!report) {
        report = {};
        this.$set(this.reports, reportId, report);
      }
      for (const key of Object.keys(message)) {
        this.$set(report, key, message[key]);
      }
    },
  },
  computed: {
    visibleReports() {
      return _(this.reports).values().reverse().take(this.limit)
        .value();
    },
  },
};
</script>
<style>
.gp-reports {
    font-size: 0.7em;
    margin-top: 8px;
}
.gp-reports tr td {
    white-space: nowrap;
}
.gp-reports tr td span {
    display: block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gp-reports tr.table-info:hover,
.gp-reports tr.table-success:hover,
.gp-reports tr.table-danger:hover {
    color: white;
}
.gp-reports tr.selected td {
    color: #222;
    background-color: var(--light)!important;
}
.my-dark-theme .gp-reports tr.selected {
    color: white;
    background-color: var(--blue)!important;
}
.gp-reports tr.selected td {
    white-space: normal;
}
.gp-reports tr.selected td span {
    display: -webkit-box;
    -webkit-line-clamp: 20;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gp-reports tr.selected:hover td {
    background-color: var(--blue);
}
</style>
