var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-main-foot", class: { opened: _vm.show } },
    [
      _vm.mode == "simulation" && _vm.show
        ? _c(
            "div",
            {
              staticClass: "pim-main-foot-calendar",
              on: {
                click: function($event) {
                  return $event.stopPropagation()
                },
                keydown: function($event) {
                  $event.key == "ArrowLeft" &&
                  _vm.calendar.days.indexOf(_vm.simulationDay) > 0
                    ? (_vm.simulationDay =
                        _vm.calendar.days[
                          _vm.calendar.days.indexOf(_vm.simulationDay) - 1
                        ])
                    : $event.key == "ArrowRight" &&
                      _vm.calendar.days.indexOf(_vm.simulationDay) <
                        _vm.calendar.days.length - 1
                    ? (_vm.simulationDay =
                        _vm.calendar.days[
                          _vm.calendar.days.indexOf(_vm.simulationDay) + 1
                        ])
                    : null
                }
              }
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedMonth,
                      expression: "selectedMonth"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedMonth = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.calendar.months, function(month) {
                  return _c("option", { domProps: { value: month } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          month
                            .split("-")
                            .slice(0, 2)
                            .join("-")
                        ) +
                        "\n      "
                    )
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void()" },
                  on: {
                    click: function($event) {
                      _vm.selectedMonth =
                        _vm.calendar.months[
                          _vm.calendar.months.indexOf(_vm.selectedMonth) - 1
                        ]
                    }
                  }
                },
                [_c("feather-icon", { attrs: { name: "chevrons-left" } })],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.calendar.days, function(day, n) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "day",
                      class: {
                        active: day == _vm.simulationDay,
                        holiday: _vm.isHolilday(day)
                      },
                      attrs: {
                        tabindex: "0",
                        "data-day": new Date(day).getDay()
                      },
                      on: {
                        focus: function($event) {
                          _vm.simulationDay = day
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(day.split("-")[2]) + "\n      "
                      )
                    ]
                  )
                ]
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void()" },
                  on: {
                    click: function($event) {
                      _vm.selectedMonth =
                        _vm.calendar.months[
                          _vm.calendar.months.indexOf(_vm.selectedMonth) + 1
                        ]
                    }
                  }
                },
                [_c("feather-icon", { attrs: { name: "chevrons-right" } })],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show
        ? _c(
            "div",
            { staticClass: "pim-main-foot-body" },
            [
              _vm.entry && _vm.entry.type == "category"
                ? [
                    _c("plain-table", {
                      key: _vm.mode,
                      ref: "table",
                      style: { "--tile-size": _vm.tileSize + "px" },
                      attrs: {
                        stream: _vm.tableConfig.stream,
                        source: _vm.tableConfig.source,
                        mode: _vm.tab,
                        dims: _vm.tableConfig.dims,
                        vals: _vm.tableConfig.vals,
                        cols: _vm.tableConfig.cols,
                        filter1: _vm.tableConfig.filter1,
                        filter2: _vm.tableConfig.filter2,
                        initialSort: [1],
                        tableActions: _vm.tableActions
                      },
                      on: { "cell-edited": _vm.handleCellEdit }
                    }),
                    _vm._v(" "),
                    _c("gp-section-columns", {
                      ref: "columns",
                      attrs: {
                        autofocus: false,
                        persitent: true,
                        attributes: _vm.config.attributes,
                        metrics: _vm.config.metrics,
                        columns: _vm.columns
                      },
                      on: {
                        submit: function($event) {
                          _vm.$refs.columns.changes = {}
                          _vm.columns = $event.columns
                        },
                        cancel: function($event) {
                          _vm.$refs.columns.changes = {}
                        }
                      }
                    })
                  ]
                : _c("p", [
                    _vm._v(
                      "\n      Please select a category from the hierarchy above.\n    "
                    )
                  ])
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }