<template>
  <div class="class-attribute-select">
    <h6 class="class-attribute-select-header">
      <l10n value="Привязка атрибута" />
    </h6>
    <gp-treeview
      :key="key"
      :data="treeData"
      @item-click="onItemClick"
    />
  </div>
</template>

<script>
const utils = require('../my-utils');

module.exports = {
  props: {
    selectedClass: {
      type: String,
      default: '',
    },

    classes: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      key: 0,
    };
  },

  computed: {
    treeData() {
      const result = this.classes.map((classEl) => {
        const { column, name } = classEl;
        const icon = name === this.selectedClass ? 'check' : 'none';
        const el = {
          name, column, icon,
        };
        el.text = `<span>${utils.l10n(name)}</span>`;

        return el;
      });

      this.key += 1;

      return result.sort((a, b) => (b?.children?.length || 0) - (a?.children?.length || 0));
    },
  },

  methods: {
    onItemClick({ name, column }) {
      const classObj = { name, column };
      this.$emit('select-class', classObj);
    },
  },
};
</script>

<style scoped>
.class-attribute-select {
  padding: 10px 6px;
  overflow-y: scroll;
  max-height: 400px;
}

.class-attribute-select-header {
  font-weight: bold;
}

.my-light-theme .class-attribute-select {
  background: #fff;
  border: 1px solid var(--dark);
}

.my-dark-theme .class-attribute-select {
  background: #222;
  border: 1px solid var(--light);
}

.class-attribute-select >>> .gp-treeview-item {
  display: flex;
  justify-content: space-between;
}

.class-attribute-select >>> .gp-treeview-item > span:nth-child(1) {
  margin-right: 20px;
}
</style>
